import React from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import {notifications_push_sync,notifications_push_read} from '../services/BE'
//import {idFromToken,displaynameFromToken} from '../@react-fefmwk/auth/services/token'

import UserAvatar from '../@react-fefmwk/auth/modules/UserAvatar'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Paper from '@material-ui/core/Paper'
//import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
//import IconButton from '@material-ui/core/IconButton'

const styles = (theme) => {
    //console.log(theme)
    return {
        read: {
            cursor: 'pointer',
            border: '2px solid '+theme.palette.grey[300],
            backgroundColor: theme.palette.grey[300],
            '&:hover': {
                borderColor: theme.palette.grey[400],
            },
        },
        unread: {
            cursor: 'pointer',
            //backgroundColor: theme.palette.secondary[100],
            border: '2px solid white',
            boxShadow: theme.shadows[2],
            '&:hover': {
                border: '2px solid '+theme.palette.primary.main,
            },
        }
    }
}

class Notifications extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notifications: [],
            goto_cta: '',
            refresh: false,
            loading: false
        }
    }
    async componentDidMount() {
        //const id_user = idFromToken()
        //const dispaly_name = displaynameFromToken()
        const notifications = await this.syncNotifications()
        this.setState({
            notifications: notifications,
            loading: false
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        //const id_user = idFromToken()
        //const dispaly_name = displaynameFromToken()
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh
        if( refresh && prev_refresh !== refresh ) {
            const notifications = await this.syncNotifications()
            this.setState({
                notifications: notifications,
                loading: false
            })
        }
    }
    async syncNotifications() {
        this.setState({
            loading: true
        })
        const unread = false
        const notifications = await notifications_push_sync(unread)
        return notifications
    }
    async openNotification(e,notification) {
        e.preventDefault()

        const cta = notification.hasOwnProperty('cta') ? notification.cta : ''
        const id = notification.hasOwnProperty('id_notification_push') ? notification.id_notification_push : ''
        const is_read = notification.hasOwnProperty('is_read') ? notification.is_read : 1
        
        const set_as_read = !parseInt(is_read)
            ? await notifications_push_read( id,{loader: false} )
            : true
        if( set_as_read ) {
            if( !cta )//refresh only if no cta is set
                this.setState({
                    refresh: true
                })
            this.setState({
                goto_cta: cta ? cta : this.state.cta,
                refresh: false,
            })
        }
    }
    renderListItem( notification ) {
        const is_read = notification.is_read
        const from = notification.from_
        const subject = notification.subject
        const body = notification.body
        let cta = notification.cta
        cta = cta ? ('/app/'+cta) : '';
        const created_at = notification.nice_created_at

        const { classes } = this.props
        const className = parseInt(is_read) === 1 ? classes.read : classes.unread

        return <React.Fragment><Box mb={1}>
            <Paper elevation={0} className={className}>
                <ListItem alignItems="flex-start" onClick={(e) => this.openNotification(e,notification)}>
                    <ListItemAvatar>
                        <UserAvatar
                            username={from}
                            showUsername={false}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={from}
                        secondary={<React.Fragment>
                            <b>{subject}</b> {body}
                        </React.Fragment>}
                    />
                    <ListItemSecondaryAction>
                        <ListItemText
                            secondary={created_at}
                        />
                        {/*<Box style={{textAlign:"right"}}>
                            {cta
                                ? <IconButton size="small"><MoreHorizIcon /></IconButton>
                                : null}
                        </Box>*/}
                    </ListItemSecondaryAction>
                </ListItem>
            </Paper>
        </Box></React.Fragment>
    }
    render() {
        const refresh = this.state.refresh
        const notifications = this.state.notifications
        const goto_cta = this.state.goto_cta
        if( goto_cta )
            return <Redirect push to={goto_cta} />

        return <React.Fragment>
            <List>
                {notifications.length && !refresh
                    ? notifications.map((notification,n) => {
                        return <React.Fragment key={n}>
                            {this.renderListItem(notification)}
                        </React.Fragment>
                    })
                    : <Chip label="Non ci sono notifiche per te!" />}
            </List>
        </React.Fragment>
    }
}

export default withStyles(styles)(Notifications)