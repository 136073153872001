import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import WidgetPodsCarousel from './widgets/WidgetPodsCarousel'

import { idFromToken } from '../@react-fefmwk/auth/services/token'
import {users_get_mysponsoredBy} from '../services/BE'
import { Box,Typography,Chip,Button,Tooltip,Grid } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = (theme) => ({})

class SponsoredPodsCarousel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pods: []
        }
    }
    async componentDidMount() {
        const id_user = idFromToken()
        const pods = await users_get_mysponsoredBy(id_user)
        this.setState({
            pods: pods
        })
    }
    render() {
        const pods = this.state.pods

        //const { classes } = this.props
        let height = this.props.hasOwnProperty('height') ? this.props.height : 300
        if( !pods.length ) height = 'auto'
        const outfit = this.props.hasOwnProperty('outfit') ? this.props.outfit : "standard"
        const withToolbar = this.props.hasOwnProperty('withToolbar') ? this.props.withToolbar : true

        return <Box style={{height:height}}>
            <Box mb={1}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Typography component="span" variant="h5"
                            style={{color: outfit === "white" ? "white" : "initial"}}
                        >
                            Consigliati da un amico
                        </Typography>
                    </Grid>
                    {withToolbar
                        ? <Grid item xs="auto">
                            <Tooltip title="Tutti i consigliati dagli amici" placement="top">
                                <Button color="secondary" variant="outlined" size="small"
                                    aria-label="Tutti i consigliati dagli amici"
                                    component={AdapterLink} to="/app/mysponsoredby"
                                    startIcon={<ChevronRightIcon />}>
                                    Tutti
                                </Button>
                            </Tooltip>
                        </Grid>
                        : null}
                </Grid>
            </Box>
            {!pods.length
                ? <Chip label="Nessuno show consigliato per ora!" />
                : <WidgetPodsCarousel pods={pods} />}
        </Box>
    }
}

export default withStyles(styles)(SponsoredPodsCarousel)