import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import { reviews_loop } from '../../services/BE'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
//import Grid from '@material-ui/core/Grid'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import { Tooltip } from '@material-ui/core'

const style = theme => {
    return {
        carouselGrid: {
            height:'100%',
            display: 'flex',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
        },
        carouselGridItem: {
            height:'100%',
            display:"flex",
            //alignItems: 'center',
            flexDirection:"column"
        },
        carouselItem: {
            height:"80%",
            minWidth: 180,
            backgroundColor:"transparent",
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            transition: 'width 1s, height 0.5s,box-shadow 0.5s',
            '&:hover': {
                height: '83%',
                width: '102%',
                boxShadow: '4px 4px 2px lightgrey'
            }
        }
    }
}

class WidgetPodsCarousel extends React.Component {
    render() {
        const pods = this.props.hasOwnProperty('pods') ? this.props.pods : []
        if( !pods.length )
            return <LoaderSingleComponent width="100%" />

        const {classes} = this.props

        const podsBoxes = pods.map((pod) => {
            const app = pod.podcast_app
            const code = pod.podcast_code
            const title = pod.podcast_title
            const thumb = pod.podcast_thumb
            return <React.Fragment key={code} >
                <AdapterLink to={"/app/shows/"+app+"/"+code}>
                <Box mr={2} className={classes.carouselGridItem}>
                    <Tooltip title={title} placement="bottom">
                        <Paper elevation={0} className={classes.carouselItem} style={{backgroundImage:"url('"+thumb+"')"}}></Paper>
                    </Tooltip>
                </Box>
                </AdapterLink>
            </React.Fragment>
        })

        return <React.Fragment>
            <Box className={classes.carouselGrid}>
                {podsBoxes}
            </Box>
        </React.Fragment>
    }
}

export default withStyles(style)(WidgetPodsCarousel)