import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import { idFromToken } from '../../@react-fefmwk/auth/services/token'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'

class PostActions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_post: this.props.id_post,
            id_author: this.props.id_author,
            currentId: null,
            anchorEl: null,
            open: false,
            onMenuClose: this.props.hasOwnProperty('onMenuClose')
                ? this.props.onMenuClose
                : () => true
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }
    handleClose() {
        this.setState({currentId:null,anchorEl:null,open:false})
        this.state.onMenuClose()
    }
    handleClick(e,id) {
        this.setState({currentId:id,anchorEl:e.currentTarget,open:true})
    }

    render() {
        const id_post = this.state.id_post
        const id_author = this.state.id_author
        const open = this.state.open
        const anchorEl = this.state.anchorEl

        const current_user_id = idFromToken()
        if( parseInt(id_author) !== parseInt(current_user_id) ) return null

        return <React.Fragment>
            <IconButton id={"postActions-"+id_post}
                onClick={(e) => this.handleClick(e,id_post)}
                aria-controls={"postActions-"+id_post}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu open={open}
                id={"postActions-"+id_post}
                anchorEl={anchorEl}
                onClose={() => this.handleClose()}
            >
                <MenuItem key={"delPost-"+id_post} component={AdapterLink} to={"/app/post/delete/"+id_post}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Elimina</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    }
}

export default PostActions