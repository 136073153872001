import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import { authors_get_obj } from '../services/BE'

import WidgetAuthorPreview from './widgets/WidgetAuthorPreview'
import WidgetReviewsList from './widgets/WidgetReviewsList'
import WidgetPostsList from './widgets/WidgetPostsList'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
//import Grid from '@material-ui/core/Grid'
//import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
//import Chip from '@material-ui/core/Chip'
//import List from '@material-ui/core/List'
//import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
//import ListItemText from '@material-ui/core/ListItemText'
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

//import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const style = theme => {
    return {
        linkNoDecoration: {textDecoration:'none'},
    }
}

class Author extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_author: this.props.id_author,
            author: {},
        }
    }
    async componentDidMount() {
        const id_author = this.state.id_author
        this.setState({
            author: await authors_get_obj( id_author )
        })
    }

    render() {
        const id_author = this.state.id_author
        const author = this.state.author
        if( !Object.keys(author).length )
            return <LoaderSingleComponent width="100%" />

        //const {classes} = this.props

        return <React.Fragment>
            <Paper elevation={0}>
                <Box p={2}>
                    <WidgetAuthorPreview
                        radius={60}
                        fontSize="180%"
                        author={author}
                        titleVariant="h6"
                        withActions={true}
                    />
                </Box>
            </Paper>
            <Box mt={2} mb={4}>
                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                    Podcasts in ascolto
                </Typography>
                <WidgetPostsList id_author={id_author} />
            </Box>
            <Box mt={2} mb={4}>
                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                    Recensioni
                </Typography>
                <WidgetReviewsList id_author={id_author} />
            </Box>
        </React.Fragment>
    }
}

export default withStyles(style)(Author)