import React from 'react'

import SpotifyPodPreview from '../spotify/SpotifyPodPreview'
import SpreakerPodPreview from '../spreaker/SpreakerPodPreview'

import Divider from '@material-ui/core/Divider'
//import Typography from '@material-ui/core/Typography'
//import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Chip from '@material-ui/core/Chip'

class WidgetPodsList extends React.Component {
    render() {
        const pods = this.props.pods

        const boxProps = this.props.hasOwnProperty('boxProps') ? this.props.boxProps : {}
        const listProps = this.props.hasOwnProperty('listProps') ? this.props.listProps : {}
        const listItemProps = this.props.hasOwnProperty('listItemProps') ? this.props.listItemProps : {}
        const onEmptyMsg = this.props.hasOwnProperty('onEmptyMsg') ? this.props.onEmptyMsg : ''
        const hookOnCloseRemoveFromMyPods = this.props.hasOwnProperty('hookOnCloseRemoveFromMyPods')
            ? this.props.hookOnCloseRemoveFromMyPods
            : () => true

        return <React.Fragment>
            <Box {...boxProps}>
                {pods.length
                    ? <List {...listProps}>
                        {pods.map((podcast,p) => {
                            const app = podcast.hasOwnProperty('app')
                                ? podcast.app
                                : (podcast.hasOwnProperty('podcast_app') ? podcast.podcast_app : '')
                            const code = podcast.hasOwnProperty('code')
                                ? podcast.code
                                : (podcast.hasOwnProperty('podcast_code') ? podcast.podcast_code : '')

                            return <React.Fragment key={"podcast-"+p}>
                                {app === 'spotify'
                                    ? <SpotifyPodPreview code={code}
                                        truncate={true}
                                        hookOnCloseRemoveFromMyPods={hookOnCloseRemoveFromMyPods}
                                        {...listItemProps}
                                    />
                                    : null}
                                {app === 'spreaker'
                                    ? <SpreakerPodPreview code={code}
                                        truncate={true}
                                        hookOnCloseRemoveFromMyPods={hookOnCloseRemoveFromMyPods}
                                        {...listItemProps}
                                    />
                                    : null}
                                {p < (pods.length-1)
                                    ? <Divider />
                                    : null}
                            </React.Fragment>
                        })}
                    </List>
                    : (onEmptyMsg ? <Chip label={onEmptyMsg} /> : null)}
            </Box>
        </React.Fragment>
    }
}

export default WidgetPodsList