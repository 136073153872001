import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { reviews_loop } from '../../services/BE'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import ReviewRating from '../pieces/ReviewRating'
import StatsChips from '../pieces/StatsChips'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        reviewActionBtn: {textTransform:'capitalize',fontWeight:'bold'},
        reviewThumb: {height:300,overflow:'hidden'},
        hasReviewed: {color: theme.palette.grey[500]}
    }
}

class WidgetReviewsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.hasOwnProperty('code') ? this.props.code : '',
            id_author: this.props.hasOwnProperty('id_author') ? this.props.id_author : 0,
            reviews: [],
        }
    }
    async componentDidMount() {
        const code = this.state.code
        const id_author = this.state.id_author

        this.setState({
            reviews: await reviews_loop( code,id_author )
        })
    }

    render() {
        const code = this.state.code
        const id_author = this.state.id_author
        const reviews = this.state.reviews
        if( !reviews.length )
            return <Chip label="Nessuna recensione al podcast" />

        const {classes,withTitle} = this.props
        const reviews_n = reviews.length

        return <React.Fragment>
            {withTitle
                ? <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6}>
                        <Typography component={Box} p={2} variant="subtitle1" color="primary">
                            Recensioni
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        <Typography component={Box} p={2} variant="body2" color="primary">
                            {reviews_n} recensioni
                        </Typography>
                    </Grid>
                </Grid>
                : null}
            <Paper elevation={0}>
                <List>
                    {reviews.map((review,i) => {
                        const id_review = review.id_review
                        const title = review.review_title
                        const author = review.review_author_nice
                        const podcast_title = review.podcast_title
                        const podcast_thumb = review.podcast_thumb
                        const rating = review.review_rating
                        const comments_n = review.stats.comments_n
                        const likes_n = review.stats.likes_n
                        return <React.Fragment key={id_review}>
                            <ListItem component={AdapterLink} to={"/app/review/outlook/"+id_review}>
                                {podcast_thumb
                                    ? <ListItemAvatar>
                                        <Avatar src={podcast_thumb} />
                                    </ListItemAvatar>
                                    : null}
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography color="primary" variant="h6" className={classes.linkNoDecoration}>
                                            {title}
                                        </Typography>
                                        {code
                                            ? null
                                            : <Typography color="secondary" variant="body2" className={classes.linkNoDecoration}>
                                                {podcast_title}
                                            </Typography>}
                                    </React.Fragment>}
                                    secondary={id_author ? null : author}
                                />
                                <ListItemSecondaryAction>
                                    <ReviewRating rating={rating} />
                                    <Box className={classes.alignTextRight}>
                                        <StatsChips color="primary"
                                            comments_n={comments_n}
                                            likes_n={likes_n}
                                        />
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {i < (reviews_n-1) ? <Divider /> : null}
                        </React.Fragment>
                    })}
                </List>
            </Paper>
        </React.Fragment>
    }
}

export default withStyles(style)(WidgetReviewsList)