Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var greyMeetpods = {
  "50": "#f2e0eb",
  "100": "#e1b2cf",
  "200": "#d081af",
  "300": "#c15191",
  "400": "#b82b7a",
  "500": "#b10063",
  "600": "#a1005e",
  "700": "#8d0057",
  "800": "#78004f",
  "900": "#530441"
};

var _default = greyMeetpods;
exports.default = _default;