import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { posts_loop } from '../../services/BE'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import StatsChips from '../pieces/StatsChips'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        postActionBtn: {textTransform:'capitalize',fontWeight:'bold'},
        postThumb: {height:300,overflow:'hidden'},
        hasPosted: {color: theme.palette.grey[500]}
    }
}

class WidgetPostsList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.hasOwnProperty('code') ? this.props.code : '',
            id_author: this.props.hasOwnProperty('id_author') ? this.props.id_author : 0,
            posts: [],
        }
    }
    async componentDidMount() {
        const code = this.state.code
        const id_author = this.state.id_author

        this.setState({
            posts: await posts_loop( code,id_author )
        })
    }

    render() {
        const id_author = this.state.id_author
        const posts = this.state.posts
        if( !posts.length )
            return <Chip label="Nessun podcast ascoltato" />

        const {classes,withTitle} = this.props
        const posts_n = posts.length

        return <React.Fragment>
            {withTitle
                ? <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6}>
                        <Typography component={Box} p={2} variant="subtitle1" color="primary">
                            Podcast ascoltati
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        <Typography component={Box} p={2} variant="body2" color="primary">
                            {posts_n} ascoltati
                        </Typography>
                    </Grid>
                </Grid>
                : null}
            <Paper elevation={0}>
                <List>
                    {posts.map((post,i) => {
                        const id_post = post.id_post
                        const author = post.post_author_nice
                        const podcast_title = post.podcast_title
                        const podcast_thumb = post.podcast_thumb
                        const podcast_app = post.podcast_app
                        const podcast_code = post.podcast_code
                        //const podcast_url = post.podcast_url
                        const comments_n = post.stats.comments_n
                        const likes_n = post.stats.likes_n
                        return <React.Fragment key={id_post}>
                            <ListItem component={AdapterLink} to={"/app/shows/"+podcast_app+"/"+podcast_code}>
                                {podcast_thumb
                                    ? <ListItemAvatar>
                                        <Avatar src={podcast_thumb} />
                                    </ListItemAvatar>
                                    : null}
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography color="primary" variant="h6" className={classes.linkNoDecoration}>
                                            {podcast_title}
                                        </Typography>
                                    </React.Fragment>}
                                    secondary={id_author ? null : author}
                                />
                                <ListItemSecondaryAction>
                                    <Box className={classes.alignTextRight}>
                                        <StatsChips color="primary"
                                            comments_n={comments_n}
                                            likes_n={likes_n}
                                        />
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {i < (posts_n-1) ? <Divider /> : null}
                        </React.Fragment>
                    })}
                </List>
            </Paper>
        </React.Fragment>
    }
}

export default withStyles(style)(WidgetPostsList)