import {
    headerTokenKey,
    getToken,
    idFromToken,
    groupsFromToken
} from '../@react-fefmwk/auth/services/token'
import {
    currentDomain,
    doCall,
    getEP
} from '../@react-fefmwk/requests/api'


const current_domain = currentDomain();
export const serviceUrl = current_domain === "meetpods.stage.it"
    ? "http://api.meetpods.stage.it"
    : "https://api.meetpods.teuteca.it"

//export const spreakerAppUrl = "https://www.spreaker.com"
//const spreakerApiUrl = "https://api.spreaker.com/v2"

export const spotifyAppUrl = "https://open.spotify.com"
//const spotifyApiUrl = "https://api.spotify.com/v1"
export const spotifyHeaderTokenKey = "SPOTIFYTOKEN"
export const spotifyLocalTokenKey = "AUTHSPOT"

export function isPodcaster() {
    const groups = groupsFromToken()
    return groups.includes(String(3)) ? true : false
}

export const EP = {
    //##AUTHENTICATION
    "login": "/login",
    "logout": "/logout",
    "forgot_password": "/password-forgot",
    "forgot_password_verify": "/password-forgot/verify",
    "reset_password": "/password-reset",
    "verify_email": "/verify-email",
    "verify_email_verify": "/verify-email/verify",
    "sign_up": "/sign-up",

    //NOTIFICATIONS
    "notifications_push_sync": "/notifications/push/sync",
    "notifications_push_read": "/notifications/push/read",

    //SPOTIFY
    "spotify_login": "/login/spotify",
    "login_spotify_authlink": "/login/spotify/authlink",
    "spotify_me": "/spotify/me",
    "spotify_search": "/spotify/search",
    "spotify_artist": "/spotify/artist",
    "spotify_artist_get_shows": "/spotify/artist/get/shows",
    "spotify_shows": "/spotify/shows",
    "spotify_shows_get": "/spotify/shows",
    "spotify_get_episodes": "/spotify/shows",
    "spotify_categories": "/spotify/browse/categories",

    //SPREAKER
    "spreaker_search": "/spreaker/search",
    "spreaker_shows_get": "/spreaker/shows",
    "spreaker_get_episodes": "/spreaker/shows",

    //SHOWS
    "is_player_reachable": "/shows/is-player-reachable",
    "is_in_my_pods": "/shows/is-in-my-pods",
    "shows_get_stats": "/shows",
    "sponsor_show": "/shows",

    //##ENTITIES
    "row_get": "/tables/get",
    "row_update": "/tables/update",
    "row_delete": "/tables/delete",

    //##MEETPODS
    "users_list": "/users/list",
    "users_get": "/users/get",
    "users_get_mypods": "/users/get",
    "users_get_myplaylists": "/users/get",
    "users_get_mysponsoredBy": "/users/get",
    "users_get_follows": "/users/get",
    "users_get_followers": "/users/get",
    "users_get_options": "/users/get",
    "users_get_survey": "/users/get",
    "users_get_survey_has_answered": "/users/get",
    "users_create": "/users/create",
    "users_create_options": "/users/create/options",
    "users_update": "/users/update",
    "users_delete": "/users/delete",
    "users_fields": "/users/fields",

    "surveys_answers_list": "/surveys_answers/list",
    "surveys_answers_get": "/surveys_answers/get",
    "surveys_answers_create": "/surveys_answers/create",
    "surveys_answers_update": "/surveys_answers/update",
    "surveys_answers_delete": "/surveys_answers/delete",
    "surveys_answers_fields": "/surveys_answers/fields",

    "users_mypods_list": "/users_mypods/list",
    "users_mypods_get": "/users_mypods/get",
    "users_mypods_create": "/users_mypods/create",
    "users_mypods_update": "/users_mypods/update",
    "users_mypods_delete": "/users_mypods/delete",
    "users_mypods_fields": "/users_mypods/fields",

    "is_followed": "/follows/is-followed",
    "follows_list": "/follows/list",
    "follows_get": "/follows/get",
    "follows_create": "/follows/create",
    "follows_update": "/follows/update",
    "follows_delete": "/follows/delete",
    "follows_fields": "/follows/fields",

    "authors_loop": "/authors/loop",
    "authors_get_obj": "/authors/get",

    "podswall_loop": "/podswall/loop",

    "posts_q": "/posts/Q",
    "posts_list": "/posts/list",
    "posts_loop": "/posts/loop",
    "posts_get_obj": "/posts/get",
    "posts_get": "/posts/get",
    "posts_create": "/posts/create",
    "posts_update": "/posts/update",
    "posts_delete": "/posts/delete",
    "posts_fields": "/posts/fields",

    "posts_comments_list": "/posts_comments/list",
    "posts_comments_get": "/posts_comments/get",
    "posts_comments_create": "/posts_comments/create",
    "posts_comments_update": "/posts_comments/update",
    "posts_comments_delete": "/posts_comments/delete",
    "posts_comments_fields": "/posts_comments/fields",

    "posts_likes_list": "/posts_likes/list",
    "posts_likes_get": "/posts_likes/get",
    "posts_likes_create": "/posts_likes/create",
    "posts_likes_update": "/posts_likes/update",
    "posts_likes_delete": "/posts_likes/delete",
    "posts_likes_fields": "/posts_likes/fields",

    "reviews_q": "/reviews/Q",
    "reviews_list": "/reviews/list",
    "reviews_list_mostreviewed": "/reviews/list/mostreviewed",
    "reviews_loop": "/reviews/loop",
    "reviews_get_obj": "/reviews/get",
    "reviews_get": "/reviews/get",
    "reviews_create": "/reviews/create",
    "reviews_update": "/reviews/update",
    "reviews_delete": "/reviews/delete",
    "reviews_fields": "/reviews/fields",

    "reviews_comments_list": "/reviews_comments/list",
    "reviews_comments_get": "/reviews_comments/get",
    "reviews_comments_create": "/reviews_comments/create",
    "reviews_comments_update": "/reviews_comments/update",
    "reviews_comments_delete": "/reviews_comments/delete",
    "reviews_comments_fields": "/reviews_comments/fields",

    "reviews_likes_list": "/reviews_likes/list",
    "reviews_likes_get": "/reviews_likes/get",
    "reviews_likes_create": "/reviews_likes/create",
    "reviews_likes_update": "/reviews_likes/update",
    "reviews_likes_delete": "/reviews_likes/delete",
    "reviews_likes_fields": "/reviews_likes/fields",
    
    "podcasts_categories_list": "/podcasts_categories/list",
    "podcasts_categories_loop": "/podcasts_categories/loop",
    "podcasts_categories_get": "/podcasts_categories/get",
    "podcasts_categories_create": "/podcasts_categories/create",
    "podcasts_categories_update": "/podcasts_categories/update",
    "podcasts_categories_delete": "/podcasts_categories/delete",
    "podcasts_categories_fields": "/podcasts_categories/fields",
    
    "playlists_q": "/playlists/Q",
    "playlists_list": "/playlists/list",
    "playlists_get": "/playlists/get",
    "playlists_create": "/playlists/create",
    "playlists_update": "/playlists/update",
    "playlists_delete": "/playlists/delete",
    "playlists_fields": "/playlists/fields",
    
    "playlists_items_q": "/playlists_items/Q",
    "playlists_items_list": "/playlists_items/list",
    "playlists_items_get": "/playlists_items/get",
    "playlists_items_create": "/playlists_items/create",
    "playlists_items_update": "/playlists_items/update",
    "playlists_items_delete": "/playlists_items/delete",
    "playlists_items_delete_byPodInfo": "/playlists_items/delete/by-pod-info",
    "playlists_items_fields": "/playlists_items/fields",

}
export async function login_spotify_authlink() {
    let callUrl = getEP( 'login_spotify_authlink',serviceUrl )

    return await doCall( callUrl,{
        method: 'GET',
    },'text',{loader:false})
}
export async function spotify_login(code) {
    let callUrl = getEP( 'spotify_login',serviceUrl )
    callUrl += "?code="+code

    return await doCall( callUrl,{
        method: 'GET',
    },'text')
}
export async function spotify_me() {
    const callUrl = getEP( 'spotify_me',serviceUrl )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',{loader:false})
}
export async function spotify_search( q ) {
    const callUrl = getEP( 'spotify_search',serviceUrl )

    const body = {
        type: "show",
        q: q,
        limit: 20
    }

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify({
            q: body
        })
    },'json.object')
}
export async function spotify_artist( name = '',id = '' ) {
    let callUrl = getEP( 'spotify_artist',serviceUrl )
    callUrl += '?name='+name+'&id='+id

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function spotify_artist_get_shows( name = '',id = '' ) {
    let callUrl = getEP( 'spotify_artist_get_shows',serviceUrl )
    callUrl += '?name='+name+'&id='+id

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function spotify_shows( ids = '' ) {
    let callUrl = getEP( 'spotify_shows',serviceUrl )
    callUrl += ids ? ('?ids='+ids) : ''

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function spotify_shows_get( code = '' ) {
    let callUrl = getEP( 'spotify_shows_get',serviceUrl )
    callUrl += '/'+code

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function spotify_get_episodes( code = '' ) {
    let callUrl = getEP( 'spotify_get_episodes',serviceUrl )
    callUrl += '/'+code+'/episodes'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',{loader:false})
}
export async function spotify_categories( limit = 10,offset = 10 ) {
    let callUrl = getEP( 'spotify_categories',serviceUrl )
    callUrl += '?1'
    callUrl += limit ? ('&limit='+limit) : ''
    callUrl += offset ? ('&offset='+offset) : ''

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function spreaker_search( q ) {
    const callUrl = getEP( 'spreaker_search',serviceUrl )

    const body = {
        type: "shows",
        q: q,
        limit: 20
    }

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify({
            q: body
        })
    },'json.object')
}

export async function spreaker_shows_get( code = '' ) {
    let callUrl = getEP( 'spreaker_shows_get',serviceUrl )
    callUrl += '/'+code

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function spreaker_get_episodes( code = '' ) {
    let callUrl = getEP( 'spreaker_get_episodes',serviceUrl )
    callUrl += '/'+code+'/episodes'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',{loader:false})
}
export async function is_player_reachable( url ) {
    if( !url ) return false

    let callUrl = getEP( 'is_player_reachable',serviceUrl )
    callUrl += '?url='+url

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric',{loader:false})
}
export async function is_in_my_pods( app,code ) {
    let callUrl = getEP( 'is_in_my_pods',serviceUrl )
    callUrl += '?app='+app+'&code='+code

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric',{loader:false})
}
export async function shows_get_stats( app,code ) {
    let callUrl = getEP( 'shows_get_stats',serviceUrl )
    callUrl += '/'+app+'/'+code+'/stats'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',{loader:false})
}
export async function sponsor_show( app,code,data ) {
    let callUrl = getEP( 'sponsor_show',serviceUrl )
    callUrl += '/'+app+'/'+code+'/sponsor'

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify({
            data:data
        })
    },'json.object')
}
export async function podswall_loop( code = '',id_user = 0,offset = 0,n = 5 ) {
    let callUrl = getEP( 'podswall_loop',serviceUrl )
    callUrl += '?q'
    callUrl += code ? ('&code='+code) : ''
    callUrl += id_user ? ('&id_user='+id_user) : ''
    callUrl += '&offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function posts_loop( code = '',id_user = 0,offset = 0,n = 5 ) {
    let callUrl = getEP( 'posts_loop',serviceUrl )
    callUrl += '?q'
    callUrl += code ? ('&code='+code) : ''
    callUrl += id_user ? ('&id_user='+id_user) : ''
    callUrl += '&offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function posts_get_obj( id_review ) {
    let callUrl = getEP( 'posts_get_obj',serviceUrl )
    callUrl += '/'+id_review+'/obj'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function reviews_list_mostreviewed( start_dt = '',end_dt = '',limit = 20 ) {
    let callUrl = getEP( 'reviews_list_mostreviewed',serviceUrl )
    callUrl += '?1'
    callUrl += start_dt ? ('&start_dt='+start_dt) : ''
    callUrl += end_dt ? ('&end_dt='+end_dt) : ''
    callUrl += limit ? ('&limit='+limit) : ''

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function reviews_loop( code = '',id_user = 0,offset = 0,n = 5 ) {
    let callUrl = getEP( 'reviews_loop',serviceUrl )
    callUrl += '?q'
    callUrl += code ? ('&code='+code) : ''
    callUrl += id_user ? ('&id_user='+id_user) : ''
    callUrl += '&offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function reviews_get_obj( id_review ) {
    let callUrl = getEP( 'reviews_get_obj',serviceUrl )
    callUrl += '/'+id_review+'/obj'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function authors_loop() {
    const callUrl = getEP( 'authors_loop',serviceUrl )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function authors_get_obj( id_author ) {
    let callUrl = getEP( 'authors_get_obj',serviceUrl )
    callUrl += '/'+id_author+'/obj'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',{loader:false})
}
export async function users_get_mypods( id_user,offset = 0,n = 10 ) {
    let callUrl = getEP( 'users_get_mypods',serviceUrl )
    callUrl += '/'+id_user+'/mypods?offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_myplaylists( id_user,offset = 0,n = 12 ) {
    let callUrl = getEP( 'users_get_myplaylists',serviceUrl )
    callUrl += '/'+id_user+'/myplaylists?offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function users_get_mysponsoredBy( id_user,offset = 0,n = 12 ) {
    let callUrl = getEP( 'users_get_mysponsoredBy',serviceUrl )
    callUrl += '/'+id_user+'/sponsored-pods?offset='+offset+'&n='+n

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function users_get_follows( id_user,friends_only = false ) {
    let callUrl = getEP( 'users_get_follows',serviceUrl )
    callUrl += '/'+id_user+'/follows?friends_only='+friends_only

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function users_get_followers( id_user,friends_only = false ) {
    let callUrl = getEP( 'users_get_followers',serviceUrl )
    callUrl += '/'+id_user+'/followers?friends_only='+friends_only

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function users_get_options( id_user ) {
    let callUrl = getEP( 'users_get_options',serviceUrl )
    callUrl += '/'+id_user+'/options'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function users_create_options( id_user,data ) {
    const callUrl = getEP( 'users_create_options',serviceUrl )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify({
            id:id_user,
            data:data,
            fromKV:true
        })
    },'json.object')
}
export async function users_get_friends( id_user ) {
    return await users_get_followers( id_user,true )
}
export async function playlists_items_delete_byPodInfo( id_playlist,app,code ) {
    let callUrl = getEP( 'playlists_items_delete_byPodInfo',serviceUrl )
    callUrl += '?id_playlist='+id_playlist+'&app='+app+'&code='+code

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function users_get_survey( id_user,options = {} ) {
    let callUrl = getEP( "users_get_survey" )
    callUrl += '/'+id_user+'/survey'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}
export async function users_get_survey_has_answered( id_user ) {
    let callUrl = getEP( "users_get_survey_has_answered" )
    callUrl += '/'+id_user+'/survey/has-answered'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'text',{loader:false})
}
export async function is_followed( id_author,id_follower ) {
    let callUrl = getEP( 'is_followed',serviceUrl )
    callUrl += '?id_user='+id_author+'&id_follower='+id_follower

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric',{loader:false})
}
export async function podcasts_categories_loop() {
    const callUrl = getEP( 'podcasts_categories_loop',serviceUrl )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function notifications_push_sync( unread = true,to_id = 0 ) {
    let callUrl = getEP( "notifications_push_sync" )
    callUrl += '?unread='+unread+'&to_id='+to_id

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',{loader:false})
}
export async function notifications_push_read( id,options = {} ) {
    let callUrl = getEP( "notifications_push_read" )
    callUrl += '/'+id

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric',options)
}