import React from 'react'
import { Redirect } from 'react-router-dom'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
import Page401 from '../@react-fefmwk/theme-structure/modules/401'
//import EntityToolbar from '../@react-fefmwk/entity/modules/EntityToolbar'
import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {entity_q} from '../@react-fefmwk/entity/services/api'

import Playlist from '../modules/Playlist'

//import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'

class SponsoredByPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            playlist: {},
        }
    }
    async getPlaylist() {
        const current_user_id = idFromToken()

        //get playlist
        const playlist = await await entity_q(
            'playlists',
            {filters:{
                id_user:current_user_id,
                playlist_title:"Consigliati da un amico"
            }}
        )
        this.setState({
            playlist: playlist.hasOwnProperty("0") ? playlist[0] : {}
        })
    }
    async componentDidMount() {
        await this.getPlaylist()
    }
    render() {
        const playlist = this.state.playlist
        const id_playlist = playlist.hasOwnProperty('id_playlist')
            ? playlist.id_playlist
            : 0

        const title = this.props.route.title

        const navigation = <React.Fragment>
            <GoBackLink type="button"
                variant="text"
                color="secondary"
                Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
            />
        </React.Fragment>

        return <Content title={title} navigation={navigation} maxWidth="md">
            {!Object.keys(playlist).length || !id_playlist
                ? <Chip label="Non hai ancora show consigliati" size="small" icon={<SentimentVeryDissatisfiedIcon />} />
                : <Playlist id_playlist={id_playlist} withTitle={false} withToolbar={false} podPreviewScope="playlist.sponsoredBy" />}
        </Content>
    }
}

export default SponsoredByPage