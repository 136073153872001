import React from 'react'

import {spotifyAppUrl} from '../../services/BE'

import PodCategoryBox from '../pieces/PodCategoryBox'

import {
    Grid
} from '@material-ui/core'

class WidgetPodsCategories extends React.Component {
    render() {
        const categories = this.props.categories

        return <Grid container spacing={2}>
            {categories.length
                ? categories.map((category) => {
                    const category_id = category.id_category
                    const category_name = category.category
                    const category_nice_name = category_name.charAt(0).toUpperCase() + category_name.slice(1)
                    const category_url = spotifyAppUrl+"/genre/"+category.category_code
                    return <Grid key={category_id} item xs={4} md={2}>
                        <PodCategoryBox
                            title={category_nice_name}
                            url={category_url}
                            externalUrl={true}
                        />
                    </Grid>})
                : null}
        </Grid>
    }
}

export default WidgetPodsCategories