import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {hasOwnPath} from '../../@react-fefmwk/arrays/api'
import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {spotify_artist} from '../../services/BE'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import { IconButton,ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const style = theme => {}

class SpotifyArtist extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            id: "",
            artist: {}
        }
    }
    async componentDidMount() {
        const id = this.props.id
        let artist = this.props.artist
        if(!Object.keys(artist).length && id) {
            this.setState({
                loading: true,
            })
            artist = await spotify_artist('',id)
        }

        this.setState({
            artist: artist,
            loading: false,
        })
    }

    render() {
        const loading = this.state.loading
        const artist = this.state.artist
        if( !Object.keys(artist).length && loading )
            return <LoaderSingleComponent width="100%" />

        const name = artist.hasOwnProperty('name') ? artist.name : "Nessun profilo Spotify trovato"
        const spotify_followers_n = hasOwnPath(artist,'followers.total') ? artist.followers.total : 0
        const spotify_url = hasOwnPath(artist,'external_urls.spotify') ? artist.external_urls.spotify : ""

        return <React.Fragment>
            <Box mt={1}>
                <Paper elevation={0}>
                <List dense>
                    <ListItem>
                        <ListItemText>{name}</ListItemText>
                        <ListItemText># followers: {spotify_followers_n}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton size="small" component={ExternalLink} to={spotify_url} target="_blank">
                                <OpenInNewIcon fontSize="small" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                </Paper>
            </Box>
        </React.Fragment>
    }
}

export default withStyles(style)(SpotifyArtist)