import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {spotify_shows_get} from '../../services/BE'
import {podPreviewActionsSchema} from '../../services/PodPreviewActionsSchema'

import SpotifyPodPreview from './SpotifyPodPreview'
import SpotifyPodEpisodes from './SpotifyPodEpisodes'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

const style = theme => {}

class SpotifyShow extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.code,
            show: {}
        }
    }
    async componentDidMount() {
        const code = this.state.code

        this.setState({
            show: await spotify_shows_get(code)
        })
    }

    render() {
        const code = this.props.code
        if( !code )
            return <React.Fragment>
                <p>Unkown show id</p>
            </React.Fragment>

        const show = this.state.show
        if( !Object.keys(show).length )
            return <LoaderSingleComponent width="100%" />
        
        const episodes = show.hasOwnProperty('episodes') ? show.episodes : {}

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('show')

        return <React.Fragment>
            <Box mt={1}>
                <List>
                    <SpotifyPodPreview
                        podcast={show}
                        titleVariant="h6"
                        //avatarRadius={50}
                        {...podPreviewProps}
                    />
                </List>
            </Box>
            {Object.keys(episodes).length
                ? <React.Fragment>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={6}>
                            <Typography component={Box} p={2} variant="subtitle1" color="primary">
                                Episodi
                            </Typography>
                        </Grid>
                        {episodes.hasOwnProperty('items') ?
                            <Grid item xs={6} style={{textAlign:'right'}}>
                                <Typography component={Box} p={2} variant="body2" color="primary">
                                    {episodes.items.length} episodi
                                </Typography>
                            </Grid>
                            : null}
                    </Grid>
                    <SpotifyPodEpisodes episodes={episodes} />
                </React.Fragment>
                : null}
        </React.Fragment>
    }
}

export default withStyles(style)(SpotifyShow)