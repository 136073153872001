import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {ShareSocial} from 'react-share-social'

import Modal from '../@react-fefmwk/modals/modules/Modal'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
//import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'
import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'

import ReplyIcon from '@material-ui/icons/Reply'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import CommentIcon from '@material-ui/icons/Comment'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import ShareIcon from '@material-ui/icons/Share'

import {posts_get_obj} from '../services/BE'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'
import {entity_create} from '../@react-fefmwk/entity/services/api'
import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {nowString,formatDate} from '../@react-fefmwk/dates/services/api'

import WidgetAuthor from './widgets/WidgetAuthor'
import WidgetPodPreview from './widgets/WidgetPodPreview'
import PostActions from './pieces/PostActions'

const style = theme => {
    return {
        postPaper: {padding: 20},
        commentTextBox: {
            backgroundColor: theme.palette.grey[200],
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 20,
        },
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        postActionBtn: {textTransform:'capitalize',fontWeight:'bold'},
        postThumb: {height:300,overflow:'hidden'},
        hasPosted: {color: theme.palette.grey[500]}
    }
}

class Post extends React.Component {
    constructor(props) {
        super(props)

        const post = this.props.hasOwnProperty('post')
            ? this.props.post
            : {}
        const id_post = this.props.hasOwnProperty('id_post')
            ? this.props.id_post
            : (Object.keys(post).length ? post.id_post : 0)
        this.state = {
            id_post: id_post,
            post: post,
            id_commentToReply: 0,
            hasCommented: false,
            openCommentForm: false
        }
    }

    async componentDidMount() {
        const post = this.state.post
        const id_post = this.state.id_post

        if( !Object.keys(post).length && id_post ) {
            this.setState({
                post: await posts_get_obj(id_post)
            })
        }
    }
    async componentDidUpdate(prevProps,prevState) {
        const hasCommented = this.state.hasCommented
        const id_post = this.state.id_post
        const id_commentToReply = this.state.id_commentToReply

        if( hasCommented ) {
            this.setState({
                post: await posts_get_obj(id_post),
                hasCommented: false
            })
        }

        if( id_commentToReply && hasCommented )
            this.setState({
                post: await posts_get_obj(id_post),
                id_commentToReply: 0,
                hasCommented: false
            })
    }

    replyTo(id_post_comment) {
        this.setState({
            id_commentToReply: id_post_comment
        })
    }

    async like(id_post) {
        const id_user = idFromToken()
        const data = {id_post:id_post,id_user:id_user,active:1,created_at:nowString()}

        const like = await entity_create('posts_likes',data)
        if( like )
            this.setState({
                post: await posts_get_obj(id_post),
            })
    }
    async dislike(id_post) {
        const id_user = idFromToken()
        const data = {id_post:id_post,id_user:id_user,active:0,created_at:nowString()}

        const dislike = await entity_create('posts_likes',data)
        if( dislike )
            this.setState({
                post: await posts_get_obj(id_post),
            })
    }

    render() {
        const post = this.state.post
        const id_post = this.state.id_post
        if( !id_post || !Object.keys(post).length ) return <LoaderSingleComponent width="100%" />

        const {classes} = this.props

        const truncatePost = this.props.hasOwnProperty('truncate') ? this.props.truncate : false
        const commentsLimit = this.props.hasOwnProperty('commentsLimit') ? this.props.commentsLimit : 0
        const showHeader = this.props.hasOwnProperty('header') ? this.props.header : true
        let showComments = this.props.hasOwnProperty('comments') ? this.props.comments : true
        showComments = commentsLimit > 0 ? true : showComments

        const post_id_author = post.id_user
        const post_author_nice = post.post_author_nice
        const post_updated_at = post.updated_at
        const post_content = post.post_content
        const is_podcaster = post.is_podcaster
        const podcast_code = post.podcast_code
        const podcast_title = post.podcast_title
        const podcast_app = post.podcast_app
        const podcast_url = post.podcast_url
        const post_attachments = post.attachments
        const current_user_likes = post.current_user_likes
        const current_user_has_commented = post.current_user_has_commented

        //truncate post_content
        const post_content_trunc = (truncatePost && post_content.length > 255) ? post_content.substring(0,255) : post_content

        //get preview image
        const thumb = post_attachments.length ? post_attachments[0]['full_url'] : ''

        const id_commentToReply = this.state.id_commentToReply
        const openCommentForm = this.state.openCommentForm

        //build post page url
        const postPagUrl = "/app/post/outlook/"+id_post

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('post')

        //CommentForm
        const CommentForm = (id_post_comment_parent = 0) => {
            return <EntityForm 
                entity="posts_comments"
                precompiledData={{
                    id_post: id_post,
                    id_post_comment_parent: id_post_comment_parent,
                }}
                customFieldsInfo={{
                    post_comment: {nice_name:null,placeholder: 'Aggiungi un commento'}
                }}
                entityInputProps={{}}
                gridItemProps={{
                    post_comment: {xs:12},
                    id_post: {style: {display: 'none'}},
                    post_comment_date: {style: {display: 'none'}},
                    id_user: {style: {display: 'none'}},
                    id_post_comment_parent: {style: {display: 'none'}},
                }}
                onSubmitOk={() => this.setState({hasCommented:true,openCommentForm:false})}
                textBtnSubmit="Pubblica"
                startIconBtnSubmit={null}
                refreshFormOnSubmit={true}
            />}

        //CommentsList
        const CommentsList = ( comments,N ) => {
            return comments.map((comment,i) => {
                if( i > N - 1 ) return null

                const id_post_comment = comment.id_post_comment
                const id_post_comment_parent = comment.id_post_comment_parent
                const id_comment_author = comment.id_comment_author
                const author = comment.comment_author_nice
                const updated_at = comment.post_comment_date
                const commentText = comment.post_comment
                const hasReplies = comment.hasOwnProperty('has_replies') ? comment.has_replies : 0
                const replies = comment.hasOwnProperty('replies') ? comment.replies : []

                return <Box key={i} mt={2} mb={4}>
                    <WidgetAuthor
                        author={{
                            id_author:id_comment_author,
                            author_nice:author
                        }}
                        subtitle={updated_at}
                    />
                    <Box mt={1} ml={6}>
                        <Paper elevation={0} className={classes.commentTextBox}>
                            <Grid container alignItems="center">
                                <Grid item xs={10}>
                                    <Typography component="div" variant="body2">
                                        {commentText}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.alignTextRight}>
                                    {parseInt(id_post_comment_parent) > 0
                                        ? null
                                        : <IconButton size="small" onClick={() => this.replyTo(id_post_comment)}>
                                            <ReplyIcon color="secondary" />
                                        </IconButton>}
                                </Grid>
                            </Grid>
                        </Paper>
                        {id_commentToReply && id_commentToReply === id_post_comment
                            ? CommentForm(id_post_comment)
                            : null}
                        {hasReplies
                            ? CommentsList( replies )
                            : null}
                    </Box>
                </Box>
            })}

        return <React.Fragment>
            <Paper elevation={0} className={classes.postPaper}>
                {showHeader
                    ? <React.Fragment>
                        <Box mb={2}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <WidgetAuthor radius={60} fontSize="180%"
                                        author={{
                                            id_author:post_id_author,
                                            author_nice:post_author_nice,
                                            is_podcaster:is_podcaster,
                                        }}
                                        subtitle={formatDate(post_updated_at)}
                                        withStats={false}
                                        withActions={true}
                                    />
                                </Grid>
                                <Grid item xs="auto" className={classes.alignTextRight}>
                                    <PostActions id_post={id_post} id_author={post_id_author} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                    </React.Fragment>
                    : null}
                {thumb
                    ? <Box mt={2} className={classes.postThumb} style={{background:'url(\''+thumb+'\') center no-repeat'}}></Box>
                    : null}
                <Box mt={2}>
                    <Box mb={1}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs="auto"><Chip label="post" size="small" color="secondary" variant="outlined"/></Grid>
                            <Grid item xs>
                            <Typography component="span" variant="overline" className={classes.hasReviewed}>
                                sta ascoltando
                            </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <List style={{padding:0}}>
                        <WidgetPodPreview
                            app={podcast_app}
                            code={podcast_code}
                            title={podcast_title}
                            openInAppUrl={podcast_url}
                            titleVariant="h6"
                            outerP={0}
                            {...podPreviewProps}
                        />
                    </List>
                </Box>
                <Box mt={2}>
                    <Typography component="div" variant="body1">
                        {post_content_trunc.length > 0
                            ? post_content_trunc
                            : <Typography component={AdapterLink} to={"/app/post/outlook/"+id_post} variant="body2" color="primary" className={classes.linkNoDecoration}>
                                Vai al post
                            </Typography>}
                        {post_content_trunc.length === post_content.length
                            ? null
                            : <Typography component={AdapterLink} to={"/app/post/outlook/"+id_post} variant="body2" color="primary" className={classes.linkNoDecoration}>
                                &nbsp;...leggi di più
                            </Typography>}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Tooltip title={current_user_likes > 0 ? "Non mi piace più" : "Mi piace"}>
                                <Button className={classes.postActionBtn}
                                    startIcon={current_user_likes > 0
                                        ? <FavoriteIcon color="primary" />
                                        : <FavoriteBorderIcon color="primary" />}
                                    onClick={() => current_user_likes > 0 ? this.dislike(id_post) : this.like(id_post)}
                                >
                                    {post.stats.likes_n}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={current_user_has_commented > 0  ? "Commenta ancora" : "Aggiungi un commento"}>
                                <Button className={classes.postActionBtn}
                                    startIcon={current_user_has_commented > 0 
                                        ? <CommentIcon color="primary" />
                                        : <ChatBubbleOutlineIcon color="primary" />}
                                    onClick={() => {this.setState({openCommentForm:true})}}
                                >
                                    Commenta
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Modal
                                printCloseBtn
                                title="Condividi"
                                content={<ShareSocial
                                    url={postPagUrl}
                                    socialTypes={['facebook','twitter','linkedin']}
                                    style={{
                                        root: {padding: 0,textAlign:'center'},
                                        copyContainer: {display:'none'}
                                    }}
                                />}
                                Trigger={<Button className={classes.postActionBtn}
                                    startIcon={<ShareIcon color="primary" />}
                                >
                                    Condividi
                                </Button>}
                                tooltipText="Condividi"
                                //tooltipPlacement="right"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    {openCommentForm ? CommentForm() : null}
                </Box>
                {post.comments.length && showComments
                    ? <React.Fragment>
                        <Box mt={4}>
                            <Typography component="div" variant="body1" color="primary">
                                {post.stats.comments_n === 0 ? "Nessun" : post.stats.comments_n} comment{post.stats.comments_n <= 1 ? "o" : "i"}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            {CommentsList( post.comments,commentsLimit )}
                        </Box>
                    </React.Fragment>
                    : null}
            </Paper>
        </React.Fragment>
    }
}

export default withStyles(style)(Post)