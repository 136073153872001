import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import RedirectBack from '../@react-fefmwk/links/modules/RedirectBack'
//import EntityToolbar from '../@react-fefmwk/entity/modules/EntityToolbar'
import Modal from '../@react-fefmwk/modals/modules/Modal'
import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {users_get_myplaylists} from '../services/BE'

import PodCategoryBox from '../modules/pieces/PodCategoryBox'

//import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import AddIcon from '@material-ui/icons/Add'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = (theme) => ({
    LinkToAllPlaylists: {
        textDecoration: 'none',
        color: 'initial'
    }
})

class MyPlaylists extends React.Component {
    constructor(props) {
        super(props)

        const openAddNew = this.props.hasOwnProperty('openAddNew') ? this.props.openAddNew : false
        this.state = {
            myplaylists: [],
            refresh: false,
            openAdd: openAddNew,
            redirectBack: false,
        }
    }
    async getMyPlaylists() {
        const offset = this.props.hasOwnProperty('offset') ? this.props.offset : 0
        const n = this.props.hasOwnProperty('n') ? this.props.n : 100
        const id_user = this.props.hasOwnProperty('id_user')
            ? this.props.id_user
            : idFromToken()

        this.setState({
            myplaylists: await users_get_myplaylists( id_user,offset,n ),
            refresh: false
        })
    }
    async componentDidMount() {
        await this.getMyPlaylists()
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh

        if( refresh && !prev_refresh ) {
            await this.getMyPlaylists()
        }
    }

    render() {
        const redirectBack = this.state.redirectBack
        const myplaylists = this.state.myplaylists

        const withToolbar = this.props.hasOwnProperty('withToolbar') ? this.props.withToolbar : true
        const withToAllPlaylists = this.props.hasOwnProperty('withToAllPlaylists') ? this.props.withToAllPlaylists : false
        const LinkToAllPlaylistsComp = withToAllPlaylists ? AdapterLink : Box
        const withTitle = this.props.hasOwnProperty('withTitle') ? this.props.withTitle : true
        const withStats = this.props.hasOwnProperty('withStats') ? this.props.withStats : false
        const title = this.props.hasOwnProperty('title') ? this.props.title : 'Playlist'

        const classes = this.props.classes

        const openAddNew = this.props.hasOwnProperty('openAddNew') ? this.props.openAddNew : false
        const openAdd = this.state.openAdd
        const toolbar = withToolbar
            ? <Box ml={2}>
                {withTitle
                    ? <React.Fragment>
                        <Tooltip title="Tutte le playlist" placement="top">
                            <Button color="secondary" variant="outlined" size="small"
                                aria-label="Tutte le playlist"
                                component={AdapterLink} to="/app/myplaylists"
                                startIcon={<ChevronRightIcon />}>
                                Tutte
                            </Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                    </React.Fragment>
                    : null}
                <Modal fullWidth maxWidth="sm" printCloseBtn
                    title={"Nuova playlist"}
                    open={openAdd}
                    onOpen={() => this.setState({openAdd:true})}
                    content={<React.Fragment>
                        <EntityForm 
                            entity="playlists"
                            onSubmitOk={() => {
                                this.setState({refresh:!openAddNew,openAdd:false,redirectBack:openAddNew})
                            }}
                            textBtnSubmit="Crea"
                            txtOnCreationOk="Playlist creata"
                            gridProps={{spacing:0}}
                            customFieldsInfo={{
                                id_user: {input_type: "hidden_autouserid"},
                                created_by: {input_type: "hidden_autouserid"},
                                created_at: {input_type: "hidden_timestamp"},
                            }}
                            gridItemProps={{
                                playlist_title: {xs:12},
                            }}
                        />
                    </React.Fragment>}
                    Trigger={<Button color="primary" variant="outlined" size="small"
                        aria-label="Add new playlist"
                        startIcon={<AddIcon />}>
                        Aggiungi
                    </Button>}
                    tooltipText="Crea nuova playlist"
                    tooltipPlacement="bottom"
                />
            </Box>
            : null

        return <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                {withTitle
                    ? <Grid item xs="auto">
                        <LinkToAllPlaylistsComp to="/app/myplaylists" className={classes.LinkToAllPlaylists}>
                            <Tooltip title="Tutte le playlist" placement="bottom">
                                <Typography component="h5" variant="h5">{title}</Typography>
                            </Tooltip>
                        </LinkToAllPlaylistsComp>
                    </Grid>
                    : null}
                {withStats
                    ? <Grid item xs="auto">
                        <Chip label={"# totale playlist: "+myplaylists.length} color="secondary" />
                    </Grid>
                    : null}
                {withToolbar
                    ? <Grid item xs>
                        {toolbar}
                    </Grid>
                    : null}
            </Grid>
            <Box mt={2}>
                {myplaylists.length
                    ? <Grid container spacing={2}>
                        {myplaylists.map((playlist,p) => {
                            const playlist_id = playlist.id_playlist
                            const playlist_title = playlist.playlist_title
                            const playlist_url = "/app/playlists/"+playlist_id
                            return <Grid key={playlist_id} item xs={4} md={2}>
                                <PodCategoryBox
                                    title={playlist_title}
                                    url={playlist_url}
                                    externalUrl={false}
                                />
                            </Grid>
                        })}
                    </Grid>
                    : <Chip label="Nessuna playlist" />}
            </Box>
            {redirectBack ? <RedirectBack /> : null}
        </React.Fragment>
    }
}

export default withStyles(styles)(MyPlaylists)