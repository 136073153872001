import React from 'react'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import WidgetPodsCategories from './widgets/WidgetPodsCategories'

import {podcasts_categories_loop} from '../services/BE'
import { Box,Typography } from '@material-ui/core'

class PodsCategories extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: {},
        }

    }
    async componentDidMount() {
        const categories = await podcasts_categories_loop()

        this.setState({
            categories: categories,
        })
    }
    render() {
        const categories = this.state.categories
        const categoriesGroups = Object.keys(categories)

        const outfit = this.props.hasOwnProperty('outfit')
            ? this.props.outfit
            : "standard"

        return <React.Fragment>
            {!categoriesGroups.length
                ? <LoaderSingleComponent width="100%" />
                : categoriesGroups.map((categoryGroup) => {
                    const categories_list = categories.hasOwnProperty(categoryGroup) ? categories[categoryGroup] : []
                    const categoryGroup_nice_name = categoryGroup.charAt(0).toUpperCase() + categoryGroup.slice(1)
                    return <React.Fragment key={categoryGroup}>
                        <Box mb={2} mt={4}>
                            <Typography component="h5" variant="h5"
                                style={{color: outfit === "white" ? "white" : "initial"}}
                            >
                                {categoryGroup_nice_name}
                            </Typography>
                        </Box>
                        <WidgetPodsCategories categories={categories_list} />
                    </React.Fragment>
                })}
        </React.Fragment>
    }
}

export default PodsCategories