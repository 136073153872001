import React from 'react'
import TripOriginIcon from '@material-ui/icons/TripOrigin'
import LensIcon from '@material-ui/icons/Lens'

class ReviewRating extends React.Component {
    render() {
        const rating = this.props.rating
        const rating_max = this.props.hasOwnProperty('rating_max') ? this.props.rating_max : 5

        let bullets = []
        for (let i = 1; i <= rating_max; i++) {
            if( i <= rating )
                bullets.push(<LensIcon key={i} size="small" color="secondary" />)
            else
                bullets.push(<TripOriginIcon key={i} size="small" color="secondary" />)
        }

        return <React.Fragment>
            {bullets}
        </React.Fragment>
    }
}

export default ReviewRating