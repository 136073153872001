import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ResetPasswordForm from '../@react-fefmwk/auth/modules/ResetPasswordForm'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

import { ChevronLeft } from '@material-ui/icons'

const loginStyles = {
    cardContent: {
        textAlign:'center'
    },
    loginLogoContainer: {
        width: '100%',
        marginBottom: 5,
        textAlign: 'center',
    },
    resetBtn: {
        width: '100%',
        marginBottom: 5
    },
    linkClean: {
        textDecoration: 'none',
        color: 'gray',
    },
}

class ResetPassword extends React.Component {
    render() {
        const theme = this.props.theme
        const defaultHomeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : "/"
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : defaultHomeUrl
        
        const { classes } = this.props
        const inputProps = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            }
        }

        return <React.Fragment>
            <Box component="div" className={classes.loginLogoContainer}>
                <img src="/images/logo-login.png" alt="Login logo" style={{maxWidth:'50%'}} />
            </Box>
            <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                    <ResetPasswordForm redirectTo={redirectTo}
                        passwordProps={inputProps}
                        msgWelcome={(user) => <React.Fragment>
                            Ciao {user.user},<br />
                            inserisci la nuova password due volte.
                        </React.Fragment>}
                        msgAfterForgot="Password aggiornata. Torna al login per accedere"
                        buttons={(formikprops) => <React.Fragment>
                            <Button color="primary" variant="contained" className={classes.resetBtn}
                                type="submit"
                            >Reset</Button>
                            <Button variant="text" className={classes.resetBtn}
                                component={AdapterLink}
                                to="/"
                                startIcon={<ChevronLeft fontSize="small" />}
                            >Torna al login</Button>
                        </React.Fragment>}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    }
}

export default withStyles(loginStyles)(withTheme(ResetPassword))