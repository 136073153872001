import React from 'react'

import SpotifyShow from './spotify/SpotifyShow'
import SpreakerShow from './spreaker/SpreakerShow'
import WidgetReviewsList from './widgets/WidgetReviewsList'

import Box from '@material-ui/core/Box'
//import Typography from '@material-ui/core/Typography'

class Show extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            app: this.props.app,
            code: this.props.code,
        }
    }

    render() {
        const app = this.props.app
        const code = this.props.code

        let appShow = null
        switch( app ) {
            case 'spotify':
                appShow = <React.Fragment>
                    <SpotifyShow code={code} />
                </React.Fragment>
            break
            case 'spreaker':
                appShow = <React.Fragment>
                    <SpreakerShow code={code} />
                </React.Fragment>
            break
            default:
                appShow = <React.Fragment>
                    <p>Invalid app or show id</p>
                </React.Fragment>
            break
        }

        return <React.Fragment>
            {appShow}
            <Box mt={2} mb={4}>
                <WidgetReviewsList code={code} withTitle={true} />
            </Box>
        </React.Fragment>
    }
}

export default Show