import React from 'react'
import LockIcon from '@material-ui/icons/Lock'

export function tableSchema( entity ) {
    switch (entity) {
        case 'users':
            return {
                id_user: {nice_name:'#'},
                user_login: {nice_name:'Nome utente'},
                display_name: {nice_name:'Nome'},
                user_pass: {
                    Header: "Password",
                    nice_name: "Password",
                    Cell: (props) => {
                        return <LockIcon color="secondary" fontSize="small" />
                    },
                    filterable: false,
                },
                created_at: {nice_name:'Data di creazione'},
                updated_at: {nice_name:'Ultimo aggiornamento'},
            }
        default:
            return {}
    }
}