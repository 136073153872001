import React from 'react'
import { Redirect } from "react-router-dom"

import {encodeBase64} from '../@react-fefmwk/links/service/api'
import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import PodPreview from './PodPreview'

import {spotify_shows_get,spreaker_shows_get} from '../services/BE'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

class WriteReview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            podcast: {},
            redirect: false
        }
    }

    async componentDidMount() {
        const code = this.props.code
        const app = this.props.app

        let shows = {}
        let podcast = {}
        switch( app ) {
            case 'spreaker':
                shows = await spreaker_shows_get( code )
                if( shows.hasOwnProperty('show') )
                    podcast = shows.show
            break
            case 'spotify':
                shows = await spotify_shows_get( code )
                if( Object.keys(shows).length )
                    podcast = shows
                /*if( shows.hasOwnProperty('shows') && shows.shows.length )
                    podcast = shows.shows[0]*/
            break
        }

        this.setState({
            podcast: podcast
        })
    }

    render() {
        const podcast = this.state.podcast
        const hasChosen = Object.keys(podcast).length
        if( !hasChosen ) return <LoaderSingleComponent width="100%" />

        const app = this.props.app
        const code = this.props.code

        const redirect = this.state.redirect

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('write_review')

        return <React.Fragment>
            <Box mb={4}>
                <Paper elevation={0}>
                    <PodPreview podcast={podcast} withList={true} app={app} {...podPreviewProps} />
                </Paper>
            </Box>
            {hasChosen
                ? <Box>
                    <EntityForm 
                        entity="reviews"
                        precompiledData={{
                            podcast_code: code,
                            podcast_app: app,
                            podcast_info: encodeBase64(JSON.stringify(podcast))
                        }}
                        customFieldsInfo={{
                            podcast_code: {input_type: "hidden"},
                            podcast_app: {input_type: "hidden"},
                            podcast_info: {input_type: "hidden"},
                        }}
                        entityInputProps={{}}
                        gridItemProps={{
                            review_title: {xs:12},
                            review_content: {xs:12},
                            created_at: {style: {display: 'none'}},
                            id_user: {style: {display: 'none'}},
                        }}
                        fieldsOrder={["review_title","id_rating","review_content","id_user","created_at","podcast_code","podcast_app","podcast_info"]}
                        onSubmitOk={() => this.setState({redirect:true})}
                        textBtnSubmit="Pubblica"
                        txtOnCreationOk="Recensione pubblicata"
                        startIconBtnSubmit={null}
                    />
                </Box>
                : null
            }
            {redirect
                ? <Redirect to='/app/pods-wall' />
                : null
            }
        </React.Fragment>
    }
}

export default WriteReview