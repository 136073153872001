import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Formik,Form } from 'formik'

import {withMediaQuery} from '../../@react-fefmwk/theme-structure/services/api'
import FieldText from '../../@react-fefmwk/form/modules/FieldText'
import Modal from '../../@react-fefmwk/modals/modules/Modal'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {spreaker_search} from '../../services/BE'
import {podPreviewActionsSchema} from '../../services/PodPreviewActionsSchema'

import SpreakerPodPreview from './SpreakerPodPreview'

import Box from '@material-ui/core/Box'
//import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'

const style = theme => {
    return {
        inputWhite: {
            color: 'white',
        },
        rootWhite: {
            '&:before': {
                borderBottomColor: 'rgba(255, 255, 255, 0.42)',
            },
            '&:after': {
                borderBottomColor: 'white',
            },
        },
    }
}

const SearchInput = (props) => {
    const outfit = props.hasOwnProperty('outfit') ? props.outfit : "standard"
    const variant = props.variant
    const InputProps = props.InputProps
    const formikProps = props.formikProps
    const values = formikProps.values
    const placeholder = props.placeholder
    return <FieldText
        placeholder={placeholder}
        name="search"
        type="string"
        value={values.search}
        variant={variant}
        onChange={formikProps.handleChange}
        InputProps={outfit === "white" ? InputProps : {}}
    />
}

class SpreakerSearch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            results: [],
            results_in_response: 0,
            searchOpen: false,
        }
    }
    componentDidUpdate(prevProps,prevState) {
        const results = this.state.results
        const search = this.state.search
        const prev_searchOpen = prevState.searchOpen

        if( !search.length && results.length ) {
            this.setState({
                results: [],
                results_in_response: 0,
            })
        }
        if( search.length > 3 && !prev_searchOpen ) {
            this.setState({
                searchOpen: true
            })
        }
    }

    validate(values) {
        const errors = {}

        if( !values['search'] )
            errors['search'] = "Indica un criterio di ricerca"

        return errors
    }
    async submit(values,actions = {}) {
        this.setState({
            results: [],
            results_in_response: 0,
        })

        const q = values.search
        const response = await spreaker_search( q )

        const items = response.hasOwnProperty('items') ? response.items : []
        const results_in_response = items.length

        this.setState({
            results: items,
            results_in_response: results_in_response,
        })
    }

    render() {
        const searchOpen = this.state.searchOpen
        const search = this.state.search
        const results = this.state.results
        const results_in_response = this.state.results_in_response

        const classes = this.props.classes
        const isMobile = this.props.isSm

        const modalScreenProps = isMobile
            ? {fullScreen:true}
            : {fullWidth:true,maxWidth:"lg"}

        const id_playlist = this.props.hasOwnProperty('id_playlist') ? this.props.id_playlist : 0
        const placeholder = this.props.hasOwnProperty('placeholder') ? this.props.placeholder : "Cerca podcast..."
        const variant = this.props.hasOwnProperty('variant') ? this.props.variant : "outlined"
        const outfit = this.props.hasOwnProperty('outfit') ? this.props.outfit : "standard"//standard|white
        const InputProps = outfit === 'white'
            ? {classes: {
                input: classes.inputWhite,
                root: classes.rootWhite,
            }}
            : {}
        const podPreviewScope = this.props.hasOwnProperty('podPreviewScope') ? this.props.podPreviewScope : 'search'

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema(podPreviewScope)

        const Trigger = this.props.Trigger
        const TriggerComponent = !Trigger
            ? <Box className={outfit === 'white' ? classes.searchBoxLikeWhite : classes.searchBoxLike} onClick={() => this.setState({searchOpen:true})}>
                {placeholder}
            </Box>
            : (React.isValidElement(Trigger)
                ? React.cloneElement(
                    Trigger,
                    {
                        style: {cursor:'pointer'},
                        onClick: () => this.setState({searchOpen:true})
                    }
                )
                : <Trigger color="secondary" style={{cursor:'pointer'}} onClick={() => this.setState({searchOpen:true})} />)

        return <React.Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    search: search,
                }}
                validate={(values) => {
                    const errors = this.validate(values)
                    const search = values.hasOwnProperty('search') && values.search ? values.search : ''
                    this.setState({
                        search: search
                    })
                    if( !Object.keys(errors).length && search.length > 3 )
                        this.submit(values)
                }}
                /*onSubmit={(values,actions) => {
                    const errors = this.validate(values)
                    if( Object.keys(errors).length || !errors ) {
                        actions.setErrors(errors)
                    }
                    else {
                        this.submit(values,actions)
                    }
                }}*/
                render={formikProps => {
                    const valSearch = formikProps.values.search
                    return <Form id={"search-spreaker"}>
                        {/*<SearchInput formikProps={formikProps} outfit="white" variant={variant} InputProps={InputProps} placeholder={placeholder} />*/}
                        {TriggerComponent}
                        <React.Fragment>
                            <Modal
                                {...modalScreenProps}
                                open={searchOpen}
                                //open={results.length || valSearch.length > 3 ? true : false}
                                printCloseBtn
                                onClose={() => this.setState({
                                    search: '',
                                    results: [],
                                    results_n_total: 0,
                                    results_in_response: 0,
                                    searchOpen: false,
                                })}
                                title={<SearchInput formikProps={formikProps} outfit="standard" variant={variant} InputProps={InputProps} placeholder={placeholder} />}
                                content={!results.length
                                    ? (valSearch.length ? <LoaderSingleComponent width="100%" /> : null)
                                    : <React.Fragment>
                                        <Box mt={2} mb={2}>
                                            <Chip label={"Risultati: "+results_in_response} />
                                        </Box>
                                        <Divider />
                                        <Box>
                                            <List>
                                                {results.map((podcast,p) => {
                                                    return <React.Fragment key={"podcast-"+p}>
                                                        <SpreakerPodPreview podcast={podcast} truncate={true} id_playlist={id_playlist} {...podPreviewProps} />
                                                        {p < (results.length-1)
                                                            ? <Divider />
                                                            : null}
                                                    </React.Fragment>
                                                })}
                                            </List>
                                        </Box>
                                    </React.Fragment>}
                                //Trigger={TriggerComponent}
                                //tooltipText={linkText}
                                //tooltipPlacement="right"
                                //BackdropProps={BackdropProps}
                                //{...rest}
                            />
                        </React.Fragment>
                    </Form>
                }}
            />
        </React.Fragment>
    }
}

export default withStyles(style)(withMediaQuery(SpreakerSearch))