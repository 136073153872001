import React from 'react'

import SpotifyPodPreview from './spotify/SpotifyPodPreview'
import SpreakerPodPreview from './spreaker/SpreakerPodPreview'

class PodPreview extends React.Component {
    render() {
        const {app,...rest} = this.props

        switch( app ) {
            case 'spotify':
                return <React.Fragment>
                    <SpotifyPodPreview {...rest} />
                </React.Fragment>
                
            case 'spreaker':
                return <React.Fragment>
                    <SpreakerPodPreview {...rest} />
                </React.Fragment>
            default:
                return <React.Fragment>
                    <p>Invalid app or show id</p>
                </React.Fragment>
        }
    }
}

export default PodPreview