import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Hidden from '@material-ui/core/Hidden'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import GraphicEqIcon from '@material-ui/icons/GraphicEq'

const styles = (theme) => ({
    spotify: {
        backgroundColor: theme.palette.spotify.main,
        color: "black",
        '&:hover': {
            backgroundColor: theme.palette.spotify[700],
        },
    },
    spreaker: {
        backgroundColor: theme.palette.spreaker.main,
        color: "black",
        '&:hover': {
            backgroundColor: theme.palette.spreaker[700],
        },
    },
    spotifyMob: {
        color: theme.palette.spotify.main,
        '&:hover': {
            color: theme.palette.spotify[700],
        },
    },
    spreakerMob: {
        color: theme.palette.spreaker.main,
        '&:hover': {
            color: theme.palette.spreaker[700],
        },
    },
})

class PodcastAppChip extends React.Component {
    render() {
        const {app,classes,theme,...rest} = this.props
        const Comp = this.props.hasOwnProperty('comp') ? this.props.comp : Chip
        const className = classes.hasOwnProperty(app) ? classes[app] : ''

        const {startIcon,...fabProps} = {...rest}

        return <React.Fragment>
            <Hidden smUp>
                <Tooltip title={app}>
                    <IconButton className={classes.hasOwnProperty(app+'Mob') ? classes[app+'Mob'] : ''} {...fabProps} size="small">
                        <GraphicEqIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Hidden>
            <Hidden smDown>
                {Comp === Chip
                    ? <Comp label={app} className={classes.hasOwnProperty(app) ? classes[app] : ''} {...rest} />
                    : <Comp className={classes.hasOwnProperty(app) ? classes[app] : ''} label={app} {...rest} variant="contained" size="small">
                        {app}
                    </Comp>}
            </Hidden>
        </React.Fragment>
    }
}

export default withStyles(styles)(withTheme(PodcastAppChip))