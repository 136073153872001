import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import SignUpForm from '../@react-fefmwk/auth/modules/SignUpForm'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { ChevronLeft } from '@material-ui/icons'

const loginStyles = {
    cardContent: {
        textAlign:'center'
    },
    loginLogoContainer: {
        width: '100%',
        marginBottom: 5,
        textAlign: 'center',
    },
    loginBtn: {
        width: '100%',
        marginBottom: 5
    },
    linkClean: {
        textDecoration: 'none',
        color: 'gray',
    },
}

class SignUp extends React.Component {
    render() {
        const theme = this.props.theme
        const defaultHomeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : "/"
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : defaultHomeUrl
        
        const { classes } = this.props
        const inputProps = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            }
        }
        const inputPropsDisplayName = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            },
            helperText: <Typography component="span" variant="caption" color="inherit" style={{textAlign:'center',display:'block'}}>
                Sei un podcaster? Indica il tuo esatto nome in Spotify!
            </Typography>
        }
        console.log(inputPropsDisplayName)
        const profile = this.props.route.hasOwnProperty('profile')
            ? this.props.route.profile
            : "author"
        const id_user_group = profile === "podcaster" ? 3 : 2

        return <React.Fragment>
            <Box component="div" className={classes.loginLogoContainer}>
                <img src="/images/logo-login.png" alt="Login logo" style={{maxWidth:'50%'}} />
            </Box>
            <Card elevation={0}>
                <CardContent className={classes.cardContent}>
                    <SignUpForm redirectTo={redirectTo}
                        useremailProps={inputProps}
                        displaynameProps={inputPropsDisplayName}
                        usernameProps={inputProps}
                        passwordProps={inputProps}
                        msgWelcome={(user) => <React.Fragment>
                            L'indirizzo {user.email} è verificato!<br />
                            Compila il form sotto per creare il tuo account!
                        </React.Fragment>}
                        msgAfterSignUp="Account creato! Vai al login per accedere"
                        id_user_group={id_user_group}
                        buttons={(formikProps,serv_terms) => {
                            return <React.Fragment>
                                <Button color="primary" variant="contained" className={classes.loginBtn}
                                    type="submit"
                                    disabled={!formikProps.isValid || !serv_terms}
                                >Registrati</Button>
                                <Button variant="text" className={classes.loginBtn}
                                    component={AdapterLink}
                                    to="/"
                                    startIcon={<ChevronLeft fontSize="small" />}
                                >Torna al login</Button>
                            </React.Fragment>}}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    }
}

export default withStyles(loginStyles)(withTheme(SignUp))