import React from 'react'
import { Formik,Form,ErrorMessage } from 'formik'

import { forgotPwd } from '../services/api'

import TextMsg from '../../messages/modules/TextMsg'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

class ForgotPasswordForm extends React.Component {
    constructor() {
        super()

        this.state = {
            request_sent: false,
        }
        
        this.forgotPwd = this.forgotPwd.bind(this)
    }

    validate( values ) {
        const username = values.hasOwnProperty('username') ? values.username : ''

        let errors = {}

        if( !username )
            errors['username'] = "Username/email cannot be empty"

        //exec hook onValidate
        const onValidate_f = this.props.hasOwnProperty('onValidate')
            ? this.props.onValidate
            : (values,errors) => {return errors}
        errors = onValidate_f(values,errors)

        return errors
    }
    async forgotPwd(data) {
        const result = await forgotPwd( data )
        this.setState({
            request_sent: parseInt(result) ? true : false
        })
    }

    render() {
        const request_sent = this.state.request_sent
        const msgWelcome = this.props.hasOwnProperty('msgWelcome')
            ? this.props.msgWelcome
            : <React.Fragment>
                Fill with your username or email.<br />
                You will receive an email with instructions on how to reset your password.
            </React.Fragment>
        const msgAfterForgot = this.props.hasOwnProperty('msgAfterForgot')
            ? this.props.msgAfterForgot
            : 'Check your email and follow instructions'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps) => <Button color="primary" variant="contained"
                disabled={!formikProps.isValid}
                type="submit"
            >Reset</Button>
        const usernameProps = this.props.hasOwnProperty('usernameProps') ? this.props.usernameProps : {}

        return <React.Fragment>
            <Typography component={Box} variant="body2">
                {msgWelcome}
            </Typography>
            <Formik
                initialValues={{username: ''}}
                validate={(values) => {
                    return this.validate( values )
                }}
                onSubmit={(values,actions) => {
                    this.forgotPwd(values)
                }}
                render={formikProps => (
                    <Form id="forgotPwdForm" onSubmit={formikProps.handleSubmit}>
                        <Box mb={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="username"
                                    type="text"
                                    name="username"
                                    label="Username or email"
                                    error={formikProps.errors.username && formikProps.touched.username ? true : false}
                                    value={formikProps.values.name}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    margin="normal"
                                    {...usernameProps}
                                />
                                <ErrorMessage name="username">
                                    {(msg) => (<TextMsg severity="error" message={msg} />)}
                                </ErrorMessage>
                            </FormControl>
                        </Box>
                        {buttons(formikProps)}
                    </Form>
                )}
            />
            <SnackbarMessage
                open={request_sent}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterForgot}
            />
        </React.Fragment>
    }
}

export default ForgotPasswordForm