Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var greenMeetpods = {
  "50": "#e0f4ef",
  "100": "#b4e3d5",
  "200": "#84d1bb",
  "300": "#52bea0",
  "400": "#2caf8d",
  "500": "#08a07b",
  "600": "#05926e",
  "700": "#00825f",
  "800": "#007252",
  "900": "#005636"
};

var _default = greenMeetpods;
exports.default = _default;