import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'

import { idFromToken } from '../../@react-fefmwk/auth/services/token'
import { users_get_survey_has_answered } from '../../services/BE'

import Modal from '../../@react-fefmwk/modals/modules/Modal'
import IFrame from '../../@react-fefmwk/app/modules/IFrame'

class UserSurveyBanner extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            has_answered: true
        }
    }
    async componentDidMount() {
        this.hasAnswered()
    }
    /*async componentDidUpdate(prevProps,prevState) {
        const has_answered = this.state.has_answered
        const prev_has_answered = prevState.has_answered
        if(has_answered !== "N" && prev_has_answered === "N") {
            this.hasAnswered()
        }
    }*/
    async hasAnswered() {
        const id_user = idFromToken()
        if( id_user > 0 ) {
            const has_answered = await users_get_survey_has_answered(id_user)
            this.setState({
                has_answered: has_answered
            })
        }
    }

    render() {
        const survey_path = "/embed/app/survey"
        const has_answered = this.state.has_answered
        const current_path = this.props.location.pathname

        return current_path === survey_path
            ? null
            : <Modal hideCloseBtn
                open={has_answered === "N" ? true : false}
                fullWidth
                fullScreen
                //maxWidth="xs"
                disableBackdropClick
                contentStyle={{height:"80vh"}}
                title="Form di profilazione"
                content={<IFrame src={survey_path} scrolling="yes" />}
            />
    }
}

export default withRouter(UserSurveyBanner)