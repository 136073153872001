import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
//import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'
//import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'
//import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {spotify_me} from '../services/BE'

import Author from '../modules/Author'

//import Box from '@material-ui/core/Box'
//import Paper from '@material-ui/core/Paper'
//import List from '@material-ui/core/List'
//import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
//import ListItemText from '@material-ui/core/ListItemText'
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
//import IconButton from '@material-ui/core/IconButton'
//import Avatar from '@material-ui/core/Avatar'

//import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const styles = (theme) => ({})

class Profile extends React.Component {
    /*constructor(props) {
        super(props)

        this.state = {
            profile_spotify: {}
        }
    }*/

    /*async componentDidMount() {
        const profile_spotify = await spotify_me()

        this.setState({
            profile_spotify: profile_spotify
        })
    }*/

    render() {
        //const profile_spotify = this.state.profile_spotify
        const id_user = idFromToken()

        //const { classes } = this.props

        return <React.Fragment>
            {/*Object.keys(profile_spotify).length
                ? <Box mb={2}>
                    <Paper elevation={0}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={profile_spotify.images.length ? profile_spotify.images[0].url : ''} />
                                </ListItemAvatar>
                                <ListItemText primary={<span>{profile_spotify.display_name}</span>} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="show" component={ExternalLink} to={profile_spotify.external_urls.spotify}>
                                        <OpenInNewIcon color="secondary" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Paper>
                </Box>
                : <LoaderSingleComponent width="100%" />*/}
            {/*<br />
            <ViewEntity
                id_entity={id_user}
                entity="users"
                noCard noTitle
            />*/}
            <Author id_author={id_user} />
        </React.Fragment>
    }
}

export default withStyles(styles)(Profile)