import React from 'react'

import {spotify_shows_get} from '../../services/BE'

//import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import WidgetPodPreview from '../widgets/WidgetPodPreview'

class SpotifyPodPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.hasOwnProperty('code') ? this.props.code : '',
            podcast: this.props.hasOwnProperty('podcast') ? this.props.podcast : {}
        }
    }
    async componentDidMount() {
        const podcast = this.state.podcast
        if( !Object.keys(podcast).length ) {
            const code = this.state.code
            this.setState({
                podcast: await spotify_shows_get(code)
            })
        }
    }
    render() {
        const podcast = this.state.podcast
        if( podcast === undefined || !podcast || !Object.keys(podcast).length )
            return null
            //return <LoaderSingleComponent width="100%" />

        const thumbUrl = podcast.hasOwnProperty('images') ? podcast.images[0].url : ''
        const title = podcast.hasOwnProperty('name') ? podcast.name : ''
        const publisher = podcast.hasOwnProperty('publisher') ? podcast.publisher : ''
        const code = podcast.hasOwnProperty('id') ? podcast.id : ''
        const openInAppUrl = podcast.hasOwnProperty('external_urls') ? podcast.external_urls.spotify : ''
        const description = podcast.hasOwnProperty('description') ? podcast.description : ''
        //const episodes = podcast.hasOwnProperty('episodes') ? podcast.episodes : {}
        //const total_episodes = podcast.hasOwnProperty('total_episodes') ? podcast.total_episodes : 0

        return <WidgetPodPreview
            app="spotify"
            thumbUrl={thumbUrl}
            title={title}
            publisher={publisher}
            code={code}
            openInAppUrl={openInAppUrl}
            description={description}
            //episodes={episodes}
            //total_episodes={total_episodes}
            {...this.props}
        />
    }
}

export default SpotifyPodPreview