import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'

import Search from '../modules/Search'
import MostReviewdPodsCarousel from '../modules/MostReviewdPodsCarousel'
import PodsCategories from '../modules/PodsCategories'

import Box from '@material-ui/core/Box'
//import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class SearchPage extends React.Component {
    render() {
        const title = this.props.route.title

        const navigation = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
        />

        return <Content title={title} navigation={navigation} maxWidth="md">
            <Box>
                <Search app="spotify" placeholder="Cerca show..." />
            </Box>
            <Box mt={4}>
                <MostReviewdPodsCarousel outfit="standard" />
            </Box>
            <Box mt={4}>
                <PodsCategories outfit="standard" />
            </Box>
        </Content>
    }
}

export default SearchPage