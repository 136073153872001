import React from 'react'
import { Formik,Form,ErrorMessage } from 'formik'
//import { Redirect } from "react-router-dom"

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {encodeBase64} from '../@react-fefmwk/links/service/api'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import FieldAutocomplete from '../@react-fefmwk/form/modules/FieldAutocomplete'
import FieldHidden from '../@react-fefmwk/form/modules/FieldHidden'
import TextMsg from '../@react-fefmwk/messages/modules/TextMsg'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'

import PodPreview from './PodPreview'

import {
    spotify_shows_get,
    spreaker_shows_get,
    users_get_friends,
    sponsor_show
} from '../services/BE'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

class SponsorPod extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            podcast: {},
            friends: [],
            submitted: false
        }
    }

    async componentDidMount() {
        const code = this.props.code
        const app = this.props.app

        //get show
        let shows = {}
        let podcast = {}
        switch( app ) {
            case 'spreaker':
                shows = await spreaker_shows_get( code )
                if( shows.hasOwnProperty('show') )
                    podcast = shows.show
            break
            case 'spotify':
                shows = await spotify_shows_get( code )
                if( Object.keys(shows).length )
                    podcast = shows
                /*if( shows.hasOwnProperty('shows') && shows.shows.length )
                    podcast = shows.shows[0]*/
            break
        }

        //get user's friends
        const current_user_id = idFromToken()
        const friends = await users_get_friends( current_user_id )

        this.setState({
            podcast: podcast,
            friends: friends
        })
    }

    render() {
        const podcast = this.state.podcast
        const hasChosen = Object.keys(podcast).length
        if( !hasChosen ) return <LoaderSingleComponent width="100%" />

        const submitted = this.state.submitted
        const id_user = this.props.hasOwnProperty('id_user') ? this.props.id_user : 0
        const app = this.props.app
        //const code = this.props.code
        const info = encodeBase64(JSON.stringify(podcast))
        //const id_friend = this.props.id_friend
        const friends = this.state.friends
        const created_by = idFromToken()

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('sponsor_show')

        return <React.Fragment>
            <Paper elevation={0}>
                <PodPreview podcast={podcast} withList={true} app={app} {...podPreviewProps} />
            </Paper>
            {hasChosen
                ? <Box mt={2}>
                    {friends.length
                        ? <Formik
                            enableReinitialize={true}
                            initialValues={{
                                id_user: id_user,
                                created_by: created_by,
                                podcast_info: info
                            }}
                            onSubmit={async (values,actions) => {
                                let errors = {}
                                if( !values.podcast_info ) errors['podcast_info'] = 'Missing podcast info'
                                if( !values.id_user ) errors['id_user'] = 'Devi scegliere un amico a cui consigliare lo show!'
                                if( !values.created_by ) errors['created_by'] = 'Missing created_by'

                                if( Object.keys(errors).length || !errors ) {
                                    actions.setErrors(errors)
                                }
                                else {
                                    const app = this.props.app
                                    const code = this.props.code
                                    const sponsorPod = await sponsor_show( app,code,values )
                                    if( sponsorPod.hasOwnProperty('last_id') && sponsorPod.last_id )
                                        this.setState({
                                            submitted: true
                                        })
                                }
                            }}
                            render={formikProps => {
                                return <Form id="sponsor-pod">
                                    <FieldAutocomplete
                                        title="Scegli un amico"
                                        name="id_user"
                                        fieldId="id_follower"
                                        fieldName="follower"
                                        sortDropdownKey="follower"
                                        items={friends}
                                        valueSelected={formikProps.values.id_user}
                                        //nullRow={nullRow}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        formikProps={formikProps}
                                        //helperText={helperText}
                                        inputProps={{width:'100%'}}
                                    />
                                    <ErrorMessage name="id_friend"> 
                                        {(msg) => (<TextMsg severity="error" message={msg} /*className={classes.errorMsg}*/ />)}
                                    </ErrorMessage>
                                    <FieldHidden name="created_by" value={formikProps.values.created_by} />
                                    <FieldHidden name="podcast_info" value={formikProps.values.podcast_info} />
                                    <Box mt={2} mb={2}>
                                        <Button type="submit" size="small"
                                            variant="contained" color="primary"
                                            //startIcon={startIconBtnSubmit}
                                            disabled={!(id_user ? true : formikProps.isValid)}
                                        >
                                            Consiglia
                                        </Button>
                                    </Box>
                                </Form>
                            }}
                        />
                        : <Typography component="p" variant="body2">
                            Non hai amici a cui consigliare lo show!    
                        </Typography>}
                </Box>
                : null
            }
            <SnackbarMessage
                open={submitted}
                message="Show consigliato!"
                severity="success"
                autoHideDuration={1000}
                onClose={() => {
                    this.setState({
                        submitted:false
                    })
                }}
            />
        </React.Fragment>
    }
}

export default SponsorPod