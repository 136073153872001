import React from 'react'
import { Formik,Form } from 'formik'

import { verifyEmail } from '../services/api'

import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

class VerifyEmailForm extends React.Component {
    constructor() {
        super()

        this.state = {
            request_sent: false,
        }
        
        this.verifyEmail = this.verifyEmail.bind(this)
    }

    async verifyEmail(email) {
        const link_endpoint = this.props.hasOwnProperty('link_endpoint') ? this.props.link_endpoint : ''
        const result = await verifyEmail( email,link_endpoint )
        this.setState({
            request_sent: parseInt(result) ? true : false
        })
    }

    render() {
        const request_sent = this.state.request_sent
        const msgWelcome = this.props.hasOwnProperty('msgWelcome')
            ? this.props.msgWelcome
            : <React.Fragment>
                Fill with your username or email.<br />
                You will receive an email with instructions on how to reset your password.
            </React.Fragment>
        const msgAfterverify = this.props.hasOwnProperty('msgAfterverify')
            ? this.props.msgAfterverify
            : 'Check your email and follow instructions'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps) => <Button color="primary" variant="contained"
                type="submit"
            >Verify</Button>
        const useremailProps = this.props.hasOwnProperty('useremailProps') ? this.props.useremailProps : {}

        return <React.Fragment>
            <Typography component={Box} variant="body2">
                {msgWelcome}
            </Typography>
            <Formik
                initialValues={{username: ''}}
                onSubmit={(values) => {
                    const email = values.hasOwnProperty('useremail') ? values.useremail : ""
                    this.verifyEmail(email)
                }}
                render={formikProps => (
                    <Form id="verifyEmailForm" onSubmit={formikProps.handleSubmit}>
                        <FormControl fullWidth>
                            <TextField
                                id="useremail"
                                type="text"
                                name="useremail"
                                label="Email"
                                value={formikProps.values.name}
                                onChange={formikProps.handleChange}
                                margin="normal"
                                {...useremailProps}
                            />
                        </FormControl>
                        <br />
                        <br />
                        {buttons(formikProps)}
                    </Form>
                )}
            />
            <SnackbarMessage
                open={request_sent}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterverify}
            />
        </React.Fragment>
    }
}

export default VerifyEmailForm