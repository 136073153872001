import React from 'react'
//import { withStyles } from '@material-ui/core/styles'

import {entity_q} from '../@react-fefmwk/entity/services/api'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {podswall_loop} from '../services/BE'
import Post from './Post'
import Review from './Review'
import Search from './Search'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

/*const styles = (theme) => ({
    wallContainer: {
        //height: '65vh',
        //overflow: 'auto',
        //borderRadius: theme.overrides.MuiPaper.rounded.borderRadius
    },
})*/

class PodsWall extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tot_n_reviews: 0,
            loop_items: [],
            page: 1,
            n: 5,
            loading: false
        }

        this.onScroll = this.onScroll.bind(this)
    }

    async componentDidMount() {
        const n = this.state.n
        const page = this.state.page

        this.setState({
            loading: true
        })
        const tot_n_reviews = await entity_q('reviews',{fields:['COUNT(id_review) AS reviews_n']})
        const loop_items = await this.getLoop(n,page)
        this.setState({
            tot_n_reviews: typeof tot_n_reviews[0] !== 'undefined' && tot_n_reviews[0].hasOwnProperty('reviews_n')
                ? tot_n_reviews[0].reviews_n
                : 0,
                loop_items: loop_items
        })
        
        window.addEventListener('scroll',this.onScroll)
    }
    async componentDidUpdate(prevProps,prevState) {
        const n = this.state.n
        const page = this.state.page
        const prevPage = prevState.page
        const loop_items = this.state.loop_items

        if( page > prevPage ) {
            this.setState({
                loading: true
            })
            const newLoopItems = await this.getLoop(n,page)
            if( newLoopItems.length ) {
                this.setState({
                    loop_items: [...loop_items,...newLoopItems],
                    loading: false
                })
            }
        }
    }
    async getLoop(n,page) {
        const offset = (page - 1) * n
        return await podswall_loop(
            '',//code
            0,//id_user
            offset,//offset
            n//n
        )
    }

    onScroll() {
        const n = this.state.n
        const page = this.state.page
        const tot_n_reviews = this.state.tot_n_reviews

        let documentHeight = document.body.scrollHeight
        let currentScroll = window.scrollY + window.innerHeight
        // When the user is [modifier]px from the bottom, fire the event.
        let modifier = 0;
        if((currentScroll + modifier) >= documentHeight && tot_n_reviews > n*page) {
            this.setState({
                page: page + 1,
            })
        }
    }

    render() {
        const loop_items = this.state.loop_items
        const loading = this.state.loading

        //const { classes } = this.props

        let loop = null
        if( loop_items.length )
            loop = loop_items.map((item) => {
                const type = item.type
                switch(type) {
                    case "review":
                        const id_review = item.id_review
                        return <React.Fragment key={type+"-"+id_review}>
                            <Box mb={2}>
                                <Review review={item} truncate={true} commentsLimit={3} />
                            </Box>
                        </React.Fragment>
                    break
                    case "post":
                        const id_post = item.id_post
                        return <React.Fragment key={type+"-"+id_post}>
                            <Box mb={2}>
                                <Post post={item} truncate={true} commentsLimit={3} />
                            </Box>
                        </React.Fragment>
                    break
                }
                
            })

        return <React.Fragment>
            <Box mb={2}>
                <Paper elevation={0}><Box p={4}>
                    <Search app="spotify" placeholder="Cosa stai ascoltando?" podPreviewScope="search.toPost" />
                </Box></Paper>
            </Box>
            <Box>
                {loop_items.length
                    ? loop
                    : null}
            </Box>
            <Box mt={2} mb={2}>
                {loading
                    ? <LoaderSingleComponent width="100%" />
                    : null}
            </Box>
        </React.Fragment>
    }
}

export default PodsWall