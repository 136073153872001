import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { Formik,Form } from 'formik'

import { verifyEmailVerify,signUp } from '../services/api'
import { queryStringToObj } from '../../links/service/api'

import ExternalLink from '../../links/modules/ExternalLink'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'

class SignUpForm extends React.Component {
    constructor(props) {
        super(props)

        /**MOCKUP
         * {email:<email>,username:<username>}
         */
        const user = this.props.hasOwnProperty('user')
            ? this.props.user
            : {}
        this.state = {
            serv_terms: false,
            from_emailverify: false,
            signup: false,
            user: user,
        }
        
        this.signUp = this.signUp.bind(this)
    }

    async componentDidMount() {
        const queryStrings = queryStringToObj(this.props.location.search)
        const r = queryStrings.hasOwnProperty('r')
            ? queryStrings.r
            : ''

        if( r ) {
            const user = await verifyEmailVerify( r )
            this.setState({
                from_emailverify: r ? true : false,
                user: user
            })
        }
    }

    validate( values ) {
        const useremail = values.hasOwnProperty('useremail') ? values.useremail : ''
        const display_name = values.hasOwnProperty('display_name') ? values.display_name : ''
        const username = values.hasOwnProperty('username') ? values.username : ''
        const password = values.hasOwnProperty('password') ? values.password : ''
        const password_repeat = values.hasOwnProperty('password_repeat') ? values.password_repeat : ''

        let errors = {}
        if( !useremail )
            errors['useremail'] = "User email name cannot be empty"
        if( !display_name )
            errors['display_name'] = "Display name cannot be empty"
        if( !username )
            errors['username'] = "User name cannot be empty"
        if( !password )
            errors['password'] = "Password cannot be empty"
        if( !password_repeat )
            errors['password_repeat'] = "You must repeat you new password"
        if( password !== password_repeat )
            errors['password_repeat'] = "Password repeat must be the same as password"
        if( password !== password_repeat )
            errors['password_repeat'] = "Password repeat must be the same as password"

        return errors
    }
    async signUp(data) {
        const id_user_group = this.props.hasOwnProperty('id_user_group')
            ? this.props.id_user_group
            : 0
        const result = await signUp( data,id_user_group )
        const affected_rows = result.hasOwnProperty('affected_rows')
            ? result.affected_rows
            : 0
        const last_id = result.hasOwnProperty('last_id')
            ? result.last_id
            : 0
        const signup = (parseInt(affected_rows) && parseInt(last_id))
            ? true
            : false
        this.setState({
            signup: signup
        })
    }

    handleChange(e) {
        e.persist()

        this.setState({
            serv_terms: e.target.checked,
        })
    }

    renderWelcomeMsg( user ) {
        const msgWelcome_f = this.props.hasOwnProperty('msgWelcome')
            ? this.props.msgWelcome
            : (user) => <React.Fragment>
                Email {user.email} is verified!<br />
                Fill the form below to create your new account.
            </React.Fragment>
        return <Typography component={Box} variant="body2">
            {msgWelcome_f(user)}
        </Typography>
    }

    render() {
        const user = this.state.user
        const serv_terms = this.state.serv_terms
        const from_emailverify = this.state.from_emailverify
        const email = user.hasOwnProperty('email') ? user.email : ""
        const signup = this.state.signup
        const msgAfterSignUp = this.props.hasOwnProperty('msgAfterSignUp')
            ? this.props.msgAfterSignUp
            : 'Your account is created!'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps,serv_terms) => <Button color="primary" variant="contained"
                type="submit"
                disabled={!formikProps.isValid || !serv_terms}
            >Sign up</Button>
        const useremailProps = this.props.hasOwnProperty('useremailProps') ? this.props.useremailProps : {}
        const displaynameProps = this.props.hasOwnProperty('displaynameProps') ? this.props.displaynameProps : {}
        const usernameProps = this.props.hasOwnProperty('usernameProps') ? this.props.usernameProps : {}
        const passwordProps = this.props.hasOwnProperty('passwordProps') ? this.props.passwordProps : {}

        return <React.Fragment>
            {!Object.keys(user).length
                ? <LoaderSingleComponent width="100%" />
                : <React.Fragment>
                    {this.renderWelcomeMsg( user )}
                    <Formik
                        initialValues={{useremail: email,display_name: '',username: '',password: '',password_repeat: '',serv_terms: false}}
                        validate={(values) => this.validate(values)}
                        onSubmit={(values) => {
                            const errors = this.validate( values )
                            if( !Object.keys(errors).length )
                                this.signUp(values)
                        }}
                        render={formikProps => {
                            return <React.Fragment>
                                <Form id="signUpForm" onSubmit={formikProps.handleSubmit}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="useremail"
                                            type="email"
                                            name="useremail"
                                            label="Email"
                                            value={formikProps.values.useremail}
                                            onChange={formikProps.handleChange}
                                            margin="normal"
                                            {...useremailProps}
                                            disabled={from_emailverify}
                                        />
                                        <TextField
                                            id="display_name"
                                            type="text"
                                            name="display_name"
                                            label="Come vuoi essere chiamato?"
                                            value={formikProps.values.display_name}
                                            onChange={formikProps.handleChange}
                                            margin="normal"
                                            {...displaynameProps}
                                        />
                                        <TextField
                                            id="username"
                                            type="text"
                                            name="username"
                                            label="Username"
                                            value={formikProps.values.username}
                                            onChange={formikProps.handleChange}
                                            margin="normal"
                                            {...usernameProps}
                                        />
                                        <TextField
                                            id="password"
                                            type="password"
                                            name="password"
                                            label="Password"
                                            value={formikProps.values.password}
                                            onChange={formikProps.handleChange}
                                            margin="normal"
                                            {...passwordProps}
                                        />
                                        <TextField
                                            id="password-repeat"
                                            type="password"
                                            name="password_repeat"
                                            label="Repeat password"
                                            value={formikProps.values.password_repeat}
                                            onChange={formikProps.handleChange}
                                            margin="normal"
                                            {...passwordProps}
                                        />
                                        <Box mt={2}>
                                            <Switch checked={serv_terms} name="serv_terms" value={serv_terms} onChange={(e) => this.handleChange(e)} />
                                            ho letto i <ExternalLink to="https://meetpods.com">termini di servizio</ExternalLink>
                                        </Box>
                                    </FormControl>
                                    <br />
                                    <br />
                                    {buttons(formikProps,serv_terms)}
                                </Form>
                            </React.Fragment>
                        }}
                    />
            </React.Fragment>}
            <SnackbarMessage
                open={signup}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterSignUp}
                //onClose={() => (window.location.href = redirectTo)}
            />
        </React.Fragment>
    }
}

export default withRouter(SignUpForm)