import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from "react-router-dom"

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'
import SnackbarMessage from '../../@react-fefmwk/messages/modules/SnackbarMessage'

import PodcastAppChip from '../pieces/PodcastAppChip'
import PodcastListen from '../pieces/PodcastListen'
import StatsChips from '../pieces/StatsChips'

import {
    is_in_my_pods,
    shows_get_stats,
    playlists_items_delete_byPodInfo
} from '../../services/BE'
import {entity_create} from '../../@react-fefmwk/entity/services/api'
import {idFromToken} from '../../@react-fefmwk/auth/services/token'
import {nowString} from '../../@react-fefmwk/dates/services/api'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'

import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import HeadsetIcon from '@material-ui/icons/Headset'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import TouchAppIcon from '@material-ui/icons/TouchApp'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        thumbAvatar: {marginRight: theme.spacing(2)},
        titleBoxWrap: {
            whiteSpace:'nowrap',
            overflow:'hidden',
            [theme.breakpoints.down('md')]: {
                whiteSpace:'normal',
                overflow:'none',
            },
        },
        actionsBoxWrap: {
            textAlign:'right',
            [theme.breakpoints.down('md')]: {
                textAlign:'left',
                marginTop: theme.spacing(1)
            },
        },
    }
}

class WidgetPodPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            inMyPods: false,
            stats: {},
            removedFromMyPods: false,
            addedToMyPods: false,
            removedFromPlaylist: false,
        }
    }
    async componentDidMount() {
        const app = this.props.app
        const code = this.props.code

        this.setState({
            inMyPods: await is_in_my_pods(app,code),
            stats: await shows_get_stats(app,code)
        })
    }

    async addToMyPods(app,code) {
        const id_user = idFromToken()
        const data = {id_user:id_user,podcast_app:app,podcast_code:code,active:1,created_at:nowString()}

        const addTo = await entity_create('users_mypods',data)
        if( addTo )
            this.setState({
                inMyPods: true,
                addedToMyPods: true,
            })
    }
    async remFromMyPods(app,code) {
        const id_user = idFromToken()
        const data = {id_user:id_user,podcast_app:app,podcast_code:code,active:0,created_at:nowString()}

        const remFrom = await entity_create('users_mypods',data)
        if( remFrom )
            this.setState({
                inMyPods: false,
                removedFromMyPods: true,
            })
    }
    async remFromPlaylist(id_playlist,app,code) {
        const remFromPlaylist = await playlists_items_delete_byPodInfo(id_playlist,app,code)
        if( remFromPlaylist )
            this.setState({
                removedFromPlaylist: true,
            })
    }
    render() {
        const app = this.props.app
        const thumbUrl = this.props.thumbUrl
        const title = this.props.title
        const publisher = this.props.publisher
        const code = this.props.code
        const openInAppUrl = this.props.openInAppUrl
        const description = this.props.description
        const id_user = this.props.hasOwnProperty('id_user') ? this.props.id_user : 0
        const id_playlist = this.props.hasOwnProperty('id_playlist') ? this.props.id_playlist : 0

        const stats = this.state.stats

        //add/remove to my pods button
        const addedToMyPods = this.state.addedToMyPods
        const removedFromMyPods = this.state.removedFromMyPods
        const isInMyPods = this.state.inMyPods
        const InMyPodsIcon = isInMyPods ? StarIcon : StarBorderIcon
        const inMyPodsTooltipText = isInMyPods ? "Togli dai preferiti" : "Aggiungi ai preferiti"
        const inMyPodsOnClick = isInMyPods ? () => this.remFromMyPods(app,code) : () => this.addToMyPods(app,code)
        const hookOnCloseAddToMyPods = this.props.hasOwnProperty('hookOnCloseAddToMyPods')
            ? this.props.hookOnCloseAddToMyPods
            : () => true
        const hookOnCloseRemoveFromMyPods = this.props.hasOwnProperty('hookOnCloseRemoveFromMyPods')
            ? this.props.hookOnCloseRemoveFromMyPods
            : () => true

        //remove from playlist (only for playlist page)
        const removedFromPlaylist = this.state.removedFromPlaylist
        const RemoveFromPlaylistIcon = RemoveCircleOutlineIcon
        const removeFromPlaylistTooltipText = "Rimuovi da playlist"
        const removeFromPlaylistOnClick = () => this.remFromPlaylist(id_playlist,app,code)
        const hookOnCloseRemoveFromPlaylist = this.props.hasOwnProperty('hookOnCloseRemoveFromPlaylist')
            ? this.props.hookOnCloseRemoveFromPlaylist
            : () => true

        const avatarRadius = this.props.hasOwnProperty('avatarRadius')
            ? this.props.avatarRadius
            : ""
        const titleVariant = this.props.hasOwnProperty('titleVariant')
            ? this.props.titleVariant
            : "body1"
        const truncateDesc = this.props.hasOwnProperty('truncate') ? this.props.truncate : false

        const withDescription = this.props.hasOwnProperty('withDescription') ? this.props.withDescription : false
        const withStats = this.props.hasOwnProperty('withStats') ? this.props.withStats : false
        const withPlayer = this.props.hasOwnProperty('withPlayer') ? this.props.withPlayer : false
        //const withEpisodes = this.props.hasOwnProperty('withEpisodes') ? this.props.withEpisodes : false
        const myPods = this.props.hasOwnProperty('myPods') ? this.props.myPods : false
        const sponsorPod = this.props.hasOwnProperty('sponsorPod') ? this.props.sponsorPod : false
        const writePost = this.props.hasOwnProperty('writePost') ? this.props.writePost : false
        const writeReview = this.props.hasOwnProperty('writeReview') ? this.props.writeReview : false
        const addToPlaylist = this.props.hasOwnProperty('addToPlaylist') ? this.props.addToPlaylist : false
        const addToPlaylistTxtAdd = this.props.hasOwnProperty('addToPlaylistTxtAdd') ? this.props.addToPlaylistTxtAdd : false
        const removeFromPlaylist = this.props.hasOwnProperty('removeFromPlaylist') ? this.props.removeFromPlaylist : false
        const openInApp = this.props.hasOwnProperty('openInApp') ? this.props.openInApp : true
        const openShow = this.props.hasOwnProperty('openShow') ? this.props.openShow : true

        //truncate review_content
        const description_trunc = (truncateDesc && description.length > 150) ? description.substring(0,150) : description

        const {classes,match} = this.props

        let titleLinkTo = "/app/shows/"+app+"/"+code
        let titleLinkComp = AdapterLink
        if( match.path.includes("app/shows") && match.params.code === code ) {
            titleLinkTo = openInAppUrl
            titleLinkComp = ExternalLink
        }

        const sponsorPodBtnColor = this.props.hasOwnProperty('sponsorPodBtnColor') ? this.props.sponsorPodBtnColor : 'primary'
        const sponsorPodBtnVariant = this.props.hasOwnProperty('sponsorPodBtnVariant') ? this.props.sponsorPodBtnVariant : 'contained'
        const sponsorPodBtnText = this.props.hasOwnProperty('sponsorPodBtnText') ? this.props.sponsorPodBtnText : 'consiglia'
        const writePostBtnColor = this.props.hasOwnProperty('writePostBtnColor') ? this.props.writePostBtnColor : 'primary'
        const writePostBtnVariant = this.props.hasOwnProperty('writePostBtnVariant') ? this.props.writePostBtnVariant : 'contained'
        const writePostBtnText = this.props.hasOwnProperty('writePostBtnText') ? this.props.writePostBtnText : 'in ascolto'
        const writeReviewBtnColor = this.props.hasOwnProperty('writeReviewBtnColor') ? this.props.writeReviewBtnColor : 'primary'
        const writeReviewBtnVariant = this.props.hasOwnProperty('writeReviewBtnVariant') ? this.props.writeReviewBtnVariant : 'contained'
        const writeReviewBtnText = this.props.hasOwnProperty('writeReviewBtnText') ? this.props.writeReviewBtnText : 'recensisci'
        const addToPlaylistBtnColor = this.props.hasOwnProperty('addToPlaylistBtnColor') ? this.props.addToPlaylistBtnColor : 'secondary'
        const addToPlaylistBtnVariant = this.props.hasOwnProperty('addToPlaylistBtnVariant') ? this.props.addToPlaylistBtnVariant : 'contained'
        const addToPlaylistBtnText = this.props.hasOwnProperty('addToPlaylistBtnText') ? this.props.addToPlaylistBtnText : 'playlist'
        const addToPlaylistTxtAddBtnColor = this.props.hasOwnProperty('addToPlaylistTxtAddBtnColor') ? this.props.addToPlaylistTxtAddBtnColor : 'secondary'
        const addToPlaylistTxtAddBtnVariant = this.props.hasOwnProperty('addToPlaylistTxtAddBtnVariant') ? this.props.addToPlaylistTxtAddBtnVariant : 'contained'
        const addToPlaylistTxtAddBtnText = this.props.hasOwnProperty('addToPlaylistTxtAddBtnText') ? this.props.addToPlaylistTxtAddBtnText : 'aggiungi'
        const removeFromPlaylistBtnColor = this.props.hasOwnProperty('removeFromPlaylistBtnColor') ? this.props.removeFromPlaylistBtnColor : 'secondary'
        const removeFromPlaylistBtnVariant = this.props.hasOwnProperty('removeFromPlaylistBtnVariant') ? this.props.removeFromPlaylistBtnVariant : 'contained'
        const removeFromPlaylistBtnText = this.props.hasOwnProperty('removeFromPlaylistBtnText') ? this.props.removeFromPlaylistBtnText : 'playlist'
        //const openInAppBtnColor = this.props.hasOwnProperty('openInAppBtnColor') ? this.props.openInAppBtnColor : 'secondary'
        //const openInAppBtnVariant = this.props.hasOwnProperty('openInAppBtnVariant') ? this.props.openInAppBtnVariant : 'contained'
        //const openInAppBtnText = this.props.hasOwnProperty('openInAppBtnText') ? this.props.openInAppBtnText : 'ascolta'
        const myPodsBtnColor = this.props.hasOwnProperty('myPodsBtnColor') ? this.props.myPodsBtnColor : 'secondary'
        const myPodsBtnVariant = this.props.hasOwnProperty('myPodsBtnVariant') ? this.props.myPodsBtnVariant : 'contained'
        const myPodsBtnText = this.props.hasOwnProperty('myPodsBtnText') ? this.props.myPodsBtnText : 'myPodcasts'
        const openShowBtnColor = this.props.hasOwnProperty('openShowBtnColor') ? this.props.openShowBtnColor : 'secondary'
        const openShowBtnVariant = this.props.hasOwnProperty('openShowBtnVariant') ? this.props.openShowBtnVariant : 'contained'
        const openShowBtnText = this.props.hasOwnProperty('openShowBtnText') ? this.props.openShowBtnText : 'mostra'

        const BtnComponent = this.props.hasOwnProperty('BtnComponent') ? this.props.BtnComponent : Button

        const outerP = this.props.hasOwnProperty('outerP') ? this.props.outerP : 2

        const preview = <React.Fragment>
            <Grid container spacing={0} alignItems="center">
                {thumbUrl
                    ? <Grid item xs="auto" md="auto">
                        <Avatar src={thumbUrl} className={classes.thumbAvatar} style={avatarRadius ? {width:avatarRadius,height:avatarRadius} : {}} />
                    </Grid>
                    : null}
                <Grid item xs="auto" md={4}>
                    <Box className={classes.titleBoxWrap}>
                        <Typography component={titleLinkComp} variant={titleVariant} color="primary" to={titleLinkTo} className={classes.linkNoDecoration}>
                            {title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="subtitle2">
                            {publisher}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md>
                    <Box className={classes.actionsBoxWrap}>
                        {sponsorPod
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Consiglia a un amico">
                                        <BtnComponent color={sponsorPodBtnColor} variant={sponsorPodBtnVariant} size="small" edge="end" aria-label="sponsor-pod"
                                            component={AdapterLink}
                                            to={"/app/sponsor/show?podcast_code="+code+"&podcast_app="+app+(id_user > 0 ? ("&id_user="+id_user) : "")}
                                            startIcon={BtnComponent === Button ? <TouchAppIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <TouchAppIcon /> : sponsorPodBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Consiglia a un amico">
                                        <IconButton color={sponsorPodBtnColor} size="small" aria-label="write-post"
                                            component={AdapterLink}
                                            to={"/app/sponsor/show?podcast_code="+code+"&podcast_app="+app}>
                                            <TouchAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {writePost
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Pubblica post">
                                        <BtnComponent color={writePostBtnColor} variant={writePostBtnVariant} size="small" edge="end" aria-label="write-post"
                                            component={AdapterLink}
                                            to={"/app/post/write?podcast_code="+code+"&podcast_app="+app}
                                            startIcon={BtnComponent === Button ? <HeadsetIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <HeadsetIcon /> : writePostBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Pubblica post">
                                        <IconButton color={writePostBtnColor} size="small" aria-label="write-post"
                                            component={AdapterLink}
                                            to={"/app/post/write?podcast_code="+code+"&podcast_app="+app}>
                                            <HeadsetIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {writeReview
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Scrivi recensione">
                                        <BtnComponent color={writeReviewBtnColor} variant={writeReviewBtnVariant} size="small" edge="end" aria-label="write-review"
                                            component={AdapterLink}
                                            to={"/app/review/write?podcast_code="+code+"&podcast_app="+app}
                                            startIcon={BtnComponent === Button ? <EditIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <EditIcon /> : writeReviewBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Scrivi recensione">
                                        <IconButton color={writeReviewBtnColor} size="small" aria-label="write-review"
                                            component={AdapterLink}
                                            to={"/app/review/write?podcast_code="+code+"&podcast_app="+app}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {addToPlaylist
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Aggiungi a playlist">
                                        <BtnComponent color={addToPlaylistBtnColor} variant={addToPlaylistBtnVariant} size="small" edge="end" aria-label="add-to-playlist"
                                            component={AdapterLink}
                                            to={"/app/playlists/item/add?podcast_code="+code+"&podcast_app="+app+(id_playlist > 0 ? ("&id_playlist="+id_playlist) : "")}
                                            startIcon={BtnComponent === Button ? <PlaylistAddIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <PlaylistAddIcon /> : addToPlaylistBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Aggiungi a playlist">
                                        <IconButton color={addToPlaylistBtnColor} size="small" aria-label="add-to-playlist"
                                            component={AdapterLink}
                                            to={"/app/playlists/item/add?podcast_code="+code+"&podcast_app="+app+(id_playlist > 0 ? ("&id_playlist="+id_playlist) : "")}>
                                            <PlaylistAddIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {addToPlaylistTxtAdd
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Aggiungi a playlist">
                                        <BtnComponent color={addToPlaylistTxtAddBtnColor} variant={addToPlaylistTxtAddBtnVariant} size="small" edge="end" aria-label="add-to-playlist"
                                            component={AdapterLink}
                                            to={"/app/playlists/item/add?red&podcast_code="+code+"&podcast_app="+app+(id_playlist > 0 ? ("&id_playlist="+id_playlist) : "")}
                                            startIcon={BtnComponent === Button ? <PlaylistAddIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <PlaylistAddIcon /> : addToPlaylistTxtAddBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Aggiungi a playlist">
                                        <IconButton color={addToPlaylistTxtAddBtnColor} size="small" aria-label="add-to-playlist"
                                            component={AdapterLink}
                                            to={"/app/playlists/item/add?podcast_code="+code+"&podcast_app="+app+(id_playlist > 0 ? ("&id_playlist="+id_playlist) : "")}>
                                            <PlaylistAddIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {removeFromPlaylist
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title={removeFromPlaylistTooltipText}>
                                        <BtnComponent color={removeFromPlaylistBtnColor} variant={removeFromPlaylistBtnVariant} size="small" edge="end" aria-label="remove-from-playlist"
                                            onClick={removeFromPlaylistOnClick}
                                            startIcon={BtnComponent === Button ? <RemoveFromPlaylistIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <RemoveFromPlaylistIcon /> : removeFromPlaylistBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title={removeFromPlaylistTooltipText}>
                                        <IconButton color={removeFromPlaylistBtnColor} size="small" aria-label="remove-from-playlist"
                                            onClick={removeFromPlaylistOnClick}>
                                            <RemoveFromPlaylistIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {myPods
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title={inMyPodsTooltipText}>
                                        <BtnComponent color={myPodsBtnColor} variant={myPodsBtnVariant} size="small" edge="end" aria-label="add-to-my-pods"
                                            onClick={inMyPodsOnClick}
                                            startIcon={BtnComponent === Button ? <InMyPodsIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <InMyPodsIcon /> : myPodsBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title={inMyPodsTooltipText}>
                                        <IconButton color={myPodsBtnColor} size="small" aria-label="add-to-my-pods"
                                            onClick={inMyPodsOnClick}>
                                            <InMyPodsIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {/*openInApp
                            ? <React.Fragment>
                                <Tooltip title={"Ascolta in "+app}>
                                    <BtnComponent color={openInAppBtnColor} variant={openInAppBtnVariant} size="small" edge="end" aria-label="show"
                                        component={ExternalLink}
                                        to={openInAppUrl}
                                        startIcon={BtnComponent === Button ? <HeadsetIcon /> : null}
                                    >
                                        {BtnComponent !== Button ? <HeadsetIcon /> : openInAppBtnText}
                                    </BtnComponent>
                                </Tooltip>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null*/}
                        {openShow
                            ? <React.Fragment>
                                <Hidden smDown>
                                    <Tooltip title="Mostra show">
                                        <BtnComponent color={openShowBtnColor} variant={openShowBtnVariant} size="small" edge="end" aria-label="show"
                                            component={AdapterLink}
                                            to={"/app/shows/"+app+"/"+code}
                                            startIcon={BtnComponent === Button ? <VisibilityIcon /> : null}
                                        >
                                            {BtnComponent !== Button ? <VisibilityIcon /> : openShowBtnText}
                                        </BtnComponent>
                                    </Tooltip>
                                </Hidden>
                                <Hidden smUp>
                                    <Tooltip title="Mostra show">
                                        <IconButton color={openShowBtnColor} size="small" aria-label="show"
                                            component={AdapterLink}
                                            to={"/app/shows/"+app+"/"+code}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Hidden>
                                &nbsp;&nbsp;
                            </React.Fragment>
                            : null}
                        {openInApp
                            ? <PodcastAppChip app={app} comp={Button} component={ExternalLink} to={openInAppUrl} startIcon={<GraphicEqIcon />} />
                            : null}
                        {withStats && Object.keys(stats).length
                            ? <Box mt={1}>
                                <StatsChips likes_n={stats.likes_n} comments_n={stats.comments_n} avg_rating={stats.avg_rating} color="primary" />
                            </Box>
                            : null}
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>

        return <React.Fragment><Box p={outerP}>
            <Box mb={1}>
                {preview}
            </Box>
            {description && withDescription
                ? <Box pt={1} pb={1}>
                    <Typography component="p" variant="body1">
                        {description_trunc}
                        {description_trunc.length === description.length
                            ? null
                            : <Typography component={AdapterLink} to={"/app/shows/"+app+"/"+code} variant="body2" color="primary" className={classes.linkNoDecoration}>
                                &nbsp;...leggi di più
                            </Typography>}
                    </Typography>
                </Box>
                : null}
            {withPlayer
                ? <Box>
                    <PodcastListen app={app} code={code} />
                </Box>
                : null}
            {addedToMyPods
                ? <SnackbarMessage
                    open={true}
                    autoHideDuration={2000}
                    severity={"success"}
                    message={"Show aggiunto ai preferiti"}
                    onClose={() => {
                        this.setState({addedToMyPods:false})
                        hookOnCloseAddToMyPods()
                    }}
                />
                : null}
            {removedFromMyPods
                ? <SnackbarMessage
                    open={true}
                    autoHideDuration={2000}
                    severity={"success"}
                    message={"Show rimosso dai preferiti"}
                    onClose={() => {
                        this.setState({removedFromMyPods:false})
                        hookOnCloseRemoveFromMyPods()
                    }}
                />
                : null}
            {removedFromPlaylist
                ? <SnackbarMessage
                    open={true}
                    autoHideDuration={2000}
                    severity={"success"}
                    message={"Show rimosso dalla playlist"}
                    onClose={() => {
                        this.setState({removedFromPlaylist:false})
                        hookOnCloseRemoveFromPlaylist()
                    }}
                />
                : null}
        </Box></React.Fragment>
    }
}

export default withRouter(withStyles(style)(WidgetPodPreview))