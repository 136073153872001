import React from 'react'

import {spreaker_shows_get} from '../../services/BE'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import WidgetPodPreview from '../widgets/WidgetPodPreview'

class SpreakerPodPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.hasOwnProperty('code') ? this.props.code : '',
            podcast: this.props.hasOwnProperty('podcast') ? this.props.podcast : {}
        }
    }
    async componentDidMount() {
        const podcast = this.state.podcast
        if( !Object.keys(podcast).length ) {
            const code = this.state.code
            let podcast = await spreaker_shows_get(code)
            podcast = podcast.hasOwnProperty('show') ? podcast.show : podcast
            this.setState({
                podcast: podcast
            })
        }
    }
    render() {
        const podcast = this.state.podcast
        if( podcast === undefined || !podcast || !Object.keys(podcast).length )
            return <LoaderSingleComponent width="100%" />

        const thumbUrl = podcast.hasOwnProperty('image_url') ? podcast.image_url : ''
        const title = podcast.hasOwnProperty('title') ? podcast.title : ''
        const publisher = podcast.hasOwnProperty('author') ? podcast.author.fullname : ''
        const code = podcast.hasOwnProperty('show_id') ? podcast.show_id : ''
        const openInAppUrl = podcast.hasOwnProperty('site_url') ? podcast.site_url : ''
        const description = podcast.hasOwnProperty('description') ? podcast.description : ''

        return <WidgetPodPreview
            app="spreaker"
            thumbUrl={thumbUrl}
            title={title}
            publisher={publisher}
            code={code}
            openInAppUrl={openInAppUrl}
            description={description}
            {...this.props}
        />
    }
}

export default SpreakerPodPreview