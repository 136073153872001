import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'

import Show from '../modules/Show'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class ShowPage extends React.Component {
    render() {
        const title = this.props.route.title
        const app = this.props.route.computedMatch.params.app
        const code = this.props.route.computedMatch.params.code

        const navigation = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
        />

        return <Content title={title} navigation={navigation} maxWidth="md">
            <Show app={app} code={code} />
        </Content>
    }
}

export default ShowPage