import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {msToTime} from '../../@react-fefmwk/dates/services/api'
import {spotify_get_episodes} from '../../services/BE'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'

import PodcastAppChip from '../pieces/PodcastAppChip'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import HeadsetIcon from '@material-ui/icons/Headset'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        reviewActionBtn: {textTransform:'capitalize',fontWeight:'bold'},
        reviewThumb: {height:300,overflow:'hidden'},
        hasReviewed: {color: theme.palette.grey[500]},
        boxEpisodesList: {maxHeight: 300,overflow:'auto'},
    }
}

class SpotifyPodEpisodes extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.hasOwnProperty('code') ? this.props.code : '',
            episodes: this.props.hasOwnProperty('episodes') ? this.props.episodes : {}
        }
    }
    async componentDidMount() {
        const episodes = this.state.episodes
        if( !Object.keys(episodes).length ) {
            const code = this.state.code
            this.setState({
                episodes: await spotify_get_episodes(code)
            })
        }
    }
    render() {
        const episodes = this.state.episodes
        if( episodes === undefined || !episodes || !Object.keys(episodes).length )
            return <LoaderSingleComponent width="100%" />

        const episodesList = episodes.hasOwnProperty('items') ? episodes.items : []
        const episodes_n = episodesList.length

        const {classes} = this.props

        return <React.Fragment>
            <Paper elevation={0}>
                <br />
                <Box className={classes.boxEpisodesList}>
                    <List>
                        {episodesList.map((episode,i) => {
                            //const id_episode = episode.id
                            //return <PodcastListen isEpisode={true} code={id_episode} app="spotify" />
                            const id_episode = episode.id
                            const title = episode.name
                            const duration = episode.hasOwnProperty('duration_ms') ? parseInt(episode.duration_ms) : 0
                            const thumb = episode.hasOwnProperty('images') ? episode.images[0].url : ''
                            //const audio_preview_url = episode.hasOwnProperty('audio_preview_url') ? episode.audio_preview_url : ''
                            const external_url = episode.hasOwnProperty('external_urls') ? episode.external_urls.spotify : ''

                            return <React.Fragment key={id_episode}>
                                <ListItem>
                                    {thumb
                                        ? <ListItemAvatar>
                                            <Avatar src={thumb} />
                                        </ListItemAvatar>
                                        : null}
                                    <ListItemText style={{width:"35%"}}
                                        primary={<React.Fragment>
                                            <Typography color="primary" variant="body1" component={ExternalLink} to={external_url} className={classes.linkNoDecoration}>
                                                {title}
                                            </Typography>
                                        </React.Fragment>}
                                        secondary={duration ? msToTime(duration) : null}
                                    />
                                    {/*<ListItemText
                                        primary={audio_preview_url
                                            ? <audio controls src={audio_preview_url} />
                                            : null}
                                    />*/}
                                    <ListItemSecondaryAction>
                                        <PodcastAppChip app="spotify" comp={Button} component={ExternalLink} to={external_url} startIcon={<HeadsetIcon />} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {i < (episodes_n-1) ? <Divider /> : null}
                            </React.Fragment>
                        })}
                    </List>
                </Box>
                <br />
            </Paper>
        </React.Fragment>
    }
}

export default withStyles(style)(SpotifyPodEpisodes)