import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'

import Settings from '../modules/Settings'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class SettingsPage extends React.Component {
    render() {
        const title = this.props.route.title

        const navigation = <React.Fragment>
            <GoBackLink type="button"
                variant="text"
                color="secondary"
                Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
            />
        </React.Fragment>

        return <Content title={title} navigation={navigation} maxWidth="md">
            <Settings />
        </Content>
    }
}

export default SettingsPage