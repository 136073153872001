import React from 'react'
import AppRoutes from './@react-fefmwk/app/modules/AppRoutes'

class App extends React.Component {
    constructor(props) {
        super(props)

        const assets_path = '/images'

        //set background image
        const backgroundImage = assets_path+"/login-background.png"
        let html = document.querySelector('html')
        html.style.backgroundImage = "url('"+backgroundImage+"')"
    }
    render() {
        return <React.Fragment>
            <AppRoutes />
        </React.Fragment>
    }
}

export default App