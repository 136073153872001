import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import EntityToolbar from '../@react-fefmwk/entity/modules/EntityToolbar'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {entity_q,entity_get} from '../@react-fefmwk/entity/services/api'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'

import WidgetPodsList from './widgets/WidgetPodsList'

//import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
//import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
//import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

const styles = (theme) => ({
    LinkToAllPlaylists: {
        textDecoration: 'none'
    }
})

class Playlist extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            refresh: false,
            playlist: {},
            playlists_items: [],
        }
    }
    async getPlaylistsItems() {
        const id_playlist = this.props.id_playlist
        const playlist = await entity_get('playlists',id_playlist)
        const playlists_items = await entity_q('playlists_items',{filters:{id_playlist:id_playlist}})
        this.setState({
            playlist: playlist,
            playlists_items: playlists_items
        })
    }
    async componentDidMount() {
        await this.getPlaylistsItems()
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh

        if( refresh && !prev_refresh ) {
            await this.getPlaylistsItems()
            this.setState({
                refresh: false
            })
        }
    }

    render() {
        const refresh = this.state.refresh
        const playlists_items = this.state.playlists_items
        const playlist = this.state.playlist
        if( !Object.keys(playlist).length ) return null

        const current_user_id = idFromToken()
        const playlist_user_id = playlist.hasOwnProperty('id_user') ? playlist.id_user : 0
        if( current_user_id != playlist_user_id )
            return <Chip label="Non sei autorizzato a vedere la playlist selezionata" />

        const id_playlist = this.props.id_playlist
        const withToolbar = this.props.hasOwnProperty('withToolbar') ? this.props.withToolbar : true
        let withTitle = this.props.hasOwnProperty('withTitle') ? this.props.withTitle : true
        const title = playlist.hasOwnProperty('playlist_title') ? playlist.playlist_title : ''
        if( !title ) withTitle = false
        const podPreviewScope = this.props.hasOwnProperty('podPreviewScope') ? this.props.podPreviewScope : 'playlist'

        //const classes = this.props.classes

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema(podPreviewScope)

        return <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                {withTitle
                    ? <Grid item xs="auto">
                        <Typography component="h5" variant="h5">{title}</Typography>
                    </Grid>
                    : null}
                {withToolbar
                    ? <Grid item xs>
                        <EntityToolbar entity="playlists_items" path={"/app/playlists/"+id_playlist} />
                    </Grid>
                    : null}
            </Grid>
            <Box mt={2}>
                {playlists_items.length && !refresh
                    ? <WidgetPodsList
                        pods={playlists_items}
                        boxProps={{pt:1}}
                        listProps={{p:0}}
                        listItemProps={{
                            ...{id_playlist:id_playlist},
                            ...{hookOnCloseRemoveFromPlaylist: () => this.setState({refresh:true})},
                            ...podPreviewProps
                        }}
                    />
                    : <Chip label="Nessun podcast nella playlist" />}
            </Box>
        </React.Fragment>
    }
}

export default withStyles(styles)(Playlist)