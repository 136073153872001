import React from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'

import UserSurveyBanner from '../modules/pieces/UserSurveyBanner'

import {delToken} from '../@react-fefmwk/auth/services/token'
import {spotifyLocalTokenKey} from './BE'

export async function hook_login() {
    
}
export async function hook_logout() {
    delToken( spotifyLocalTokenKey )
}
export async function hook_forgotpassword() {
    
}
export async function hook_resetpassword() {
    
}
/**
 * HookRoutePrepend è un componente che si comporta come un hook.
 * Viene eseguito in AppRoute prima del render della Public/PrivateRoute.
 * Vedi <code>@react-fefmwk\app\modules\AppRoute.js</code>.
 * 
 * @param {*} props 
 * @returns Render componente React
 */
export const HookRoutePrepend = (props) => {
    //console.log(props)
    return <React.Fragment>
        <UserSurveyBanner />
    </React.Fragment>
}
/**
 * HookRouteAppend è un componente che si comporta come un hook.
 * Viene eseguito in AppRoute dopo il render della Public/PrivateRoute.
 * Vedi <code>@react-fefmwk\app\modules\AppRoute.js</code>.
 * 
 * @param {*} props 
 * @returns Render componente React
 */
export const HookRouteAppend = (props) => {
    //console.log(props)
    return <React.Fragment>{null}</React.Fragment>
}