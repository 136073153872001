import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import WidgetAuthorPreview from './WidgetAuthorPreview'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
    }
}

class WidgetAuthor extends React.Component {
    render() {
        const {author,...rest} = this.props
        if( !Object.keys(author).length ) return null

        return <React.Fragment>
            <WidgetAuthorPreview
                author={author}
                {...rest}
            />
        </React.Fragment>
    }
}

export default withStyles(style)(WidgetAuthor)