import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import UserAvatar from '../../@react-fefmwk/auth/modules/UserAvatar'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import SnackbarMessage from '../../@react-fefmwk/messages/modules/SnackbarMessage'

import {entity_create} from '../../@react-fefmwk/entity/services/api'
import {nowString} from '../../@react-fefmwk/dates/services/api'
import {idFromToken} from '../../@react-fefmwk/auth/services/token'
import {authors_get_obj} from '../../services/BE'

import Search from '../Search'
import StatsChips from '../pieces/StatsChips'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
//import Fab from '@material-ui/core/Fab'
import Chip from '@material-ui/core/Chip'
import Hidden from '@material-ui/core/Hidden'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PersonIcon from '@material-ui/icons/Person'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import MicIcon from '@material-ui/icons/Mic'

const style = theme => {
    return {
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
        avatarBox: {paddingRight:theme.spacing(2)}
    }
}

class WidgetAuthorPreview extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_author: 0,
            author: {},
            refresh: false,
            addedFollow: false,
            removedFollow: false,
        }
    }
    async componentDidMount() {
        const prop_id_author = this.props.hasOwnProperty('id_author') ? this.props.id_author : 0
        const prop_author = this.props.hasOwnProperty('author') ? this.props.author : {}

        let id_author = 0
        let author = {}
        if( !Object.keys(prop_author).length && prop_id_author ) {
            id_author = prop_id_author
            author = await authors_get_obj(prop_id_author)
        }
        if( Object.keys(prop_author).length && !prop_id_author ) {
            id_author = prop_author.id_author
            author = prop_author
        }

        this.setState({
            id_author: id_author,
            author: author,
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh
        if( refresh && !prev_refresh ) {
            const id_author = this.state.id_author
            const author = await authors_get_obj(id_author)
            this.setState({
                author: author,
                refresh: false,
            })
        }
    }
    async follow(id_author,id_follower) {
        const data = {id_follower:id_follower,id_user:id_author,active:1,created_at:nowString()}

        const doFollow = await entity_create(
            'follows',
            data,
            false,//referenced
            {loader: false}
        )
        if( doFollow ) {
            this.setState({
                refresh: true,
                addedFollow: true,
            })
            const onFollow = this.props.hasOwnProperty('onFollow') ? this.props.onFollow : () => true
            onFollow()
        }
    }
    async unfollow(id_author,id_follower) {
        const data = {id_follower:id_follower,id_user:id_author,active:0,created_at:nowString()}

        const remFrom = await entity_create(
            'follows',
            data,
            false,//referenced
            {loader: false}
        )
        if( remFrom ) {
            this.setState({
                refresh: true,
                removedFollow: true,
            })
            
            const onUnfollow = this.props.hasOwnProperty('onUnfollow') ? this.props.onUnfollow : () => true
            onUnfollow()
        }
    }

    render() {
        const author = this.state.author
        if( !Object.keys(author).length ) return null

        const id_author = author.id_author
        const author_nice = author.author_nice
        const reviews_n = author.reviews_n
        const posts_n = author.posts_n
        const comments_n = author.comments_n
        const likes_n = author.likes_n
        //const follows_n = author.follows_n
        const followers_n = author.followers_n
        const is_followed = author.is_followed
        //const is_follower = author.is_follower
        const is_friend = author.is_friend
        const is_podcaster = author.is_podcaster

        const withAvatar = this.props.hasOwnProperty('withAvatar')
            ? this.props.withAvatar
            : true
        const withAuthorName = this.props.hasOwnProperty('withAuthorName')
            ? this.props.withAuthorName
            : true
        const withActions = this.props.hasOwnProperty('withActions')
            ? this.props.withActions
            : false
        let withStats = this.props.hasOwnProperty('withStats')
            ? this.props.withStats
            : true
        withStats = reviews_n ||posts_n || comments_n || likes_n ? withStats : false

        let style = {}
        const radius = this.props.radius
        if( radius ) style = {...style,...{width:radius,height:radius}}
        const fontSize = this.props.fontSize
        if( fontSize ) style = {...style,...{fontSize:fontSize}}

        const titleVariant = this.props.hasOwnProperty('titleVariant')
            ? this.props.titleVariant
            : "body1"
        const subtitle = this.props.subtitle
        const openAuthor = this.props.hasOwnProperty('openAuthor')
            ? this.props.openAuthor
            : false

        const {classes} = this.props

        const BtnComponent = this.props.hasOwnProperty('BtnComponent') ? this.props.BtnComponent : Button

        const current_user_id = idFromToken()
        const addedFollow = this.state.addedFollow
        const removedFollow = this.state.removedFollow
        const FollowIcon = is_followed ? PersonIcon : PersonOutlineIcon
        const followBtnText = is_followed ? "unfollow" : "follow"
        const followTooltipText = is_followed ? "Non seguire più" : "Segui"
        const followVariant = is_followed ? "outlined" : "outlined"
        const followOnClick = is_followed ? () => this.unfollow(id_author,current_user_id) : () => this.follow(id_author,current_user_id)

        const hookOnCloseFollow = this.props.hasOwnProperty('hookOnCloseFollow')
            ? this.props.hookOnCloseFollow
            : () => true
        const hookOnCloseUnfollow = this.props.hasOwnProperty('hookOnCloseUnfollow')
            ? this.props.hookOnCloseUnfollow
            : () => true

        return <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                {withAvatar || withAuthorName
                    ? <Grid item xs={12} md>
                        <Grid container alignItems="center">
                            {withAvatar
                                ? <Grid item xs="auto" className={classes.avatarBox}>
                                    <AdapterLink to={"/app/authors/"+id_author} className={classes.linkNoDecoration}>
                                        <UserAvatar username={author_nice} style={style} />
                                    </AdapterLink>
                                </Grid>
                                : null}
                            <Grid item xs="auto">
                                {withAuthorName
                                    ? <Grid container alignItems="center">
                                        <Grid item xs="auto">
                                            <Typography variant={titleVariant} color="secondary" component={AdapterLink} to={"/app/authors/"+id_author} className={classes.linkNoDecoration}>
                                                {author_nice}
                                            </Typography>
                                        </Grid>
                                        {is_podcaster
                                            ? <Grid item xs="auto">
                                                <Box ml={2}>
                                                    <Tooltip title="Podcaster!">
                                                        <IconButton size="small" component={AdapterLink} to={"/app/podcasters/"+id_author}>
                                                            <MicIcon size="small" color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                            : null}
                                    </Grid>
                                    : null}
                                {subtitle
                                    ? <React.Fragment>
                                        <Box mt={1}>
                                            <Typography component="span" variant="body2" style={{fontSize:'90%',color:'grey'}}>
                                                {subtitle}
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                    : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    : null}
                {withActions
                    ? <React.Fragment>
                        {is_friend
                            ? <Grid item xs="auto" className={classes.alignTextRight}>
                                <React.Fragment>
                                    <Box mr={1}>
                                        <Hidden smDown>
                                            <Search app="spotify"
                                                placeholder="Consiglia a un amico"
                                                podPreviewScope="search"
                                                id_user={id_author}
                                                Trigger={<BtnComponent color="primary" variant="outlined" size="small" edge="end" aria-label="consiglia"
                                                    startIcon={BtnComponent === Button ? <TouchAppIcon /> : null}
                                                >
                                                    {BtnComponent !== Button ? <TouchAppIcon /> : "Consiglia"}
                                                </BtnComponent>}
                                            />
                                        </Hidden>
                                        <Hidden smUp>
                                            <Search app="spotify"
                                                placeholder="Consiglia a un amico"
                                                podPreviewScope="search"
                                                id_user={id_author}
                                                Trigger={<IconButton color="primary" size="small" aria-label="consiglia">
                                                    <Tooltip title="Consiglia"><TouchAppIcon fontSize="small" /></Tooltip>
                                                </IconButton>}
                                            />
                                        </Hidden>
                                    </Box>
                                </React.Fragment>
                            </Grid>
                            : null}
                        {parseInt(id_author) === parseInt(current_user_id)
                            ? null
                            : <Grid item xs="auto" className={classes.alignTextRight}>
                                <React.Fragment>
                                        <Box mr={1}>
                                            <Hidden smDown>
                                                <Tooltip title={followTooltipText}>
                                                    <BtnComponent color="primary" variant={followVariant} size="small" edge="end" aria-label="follow"
                                                        onClick={followOnClick}
                                                        startIcon={BtnComponent === Button ? <FollowIcon /> : null}
                                                    >
                                                        {BtnComponent !== Button ? <FollowIcon /> : followBtnText}
                                                    </BtnComponent>
                                                </Tooltip>
                                            </Hidden>
                                            <Hidden smUp>
                                                <Tooltip title={followTooltipText}>
                                                    <Chip color="primary" size="small"
                                                        onClick={followOnClick}
                                                        icon={<FollowIcon fontSize="small" />}
                                                        variant={followVariant}
                                                        label={followTooltipText}
                                                    />
                                                </Tooltip>
                                            </Hidden>
                                        </Box>
                                    </React.Fragment>
                            </Grid>}
                        {parseInt(id_author) === parseInt(current_user_id)
                            ? null
                            : <Grid item xs="auto" className={classes.alignTextRight}>
                                <Tooltip title={is_friend ? "Amico" : "Non amico"} placement="bottom">
                                    <Chip size="small" color="secondary"
                                        label={is_friend ? "Amico" : "Non amico"}
                                        variant={is_friend ? "default" : "outlined"}
                                    />
                                </Tooltip>
                            </Grid>}
                    </React.Fragment>
                    : null}
                {withStats
                    ? <Grid item xs="auto" className={classes.alignTextRight}>
                        <StatsChips
                            //posts_n={posts_n}
                            reviews_n={reviews_n}
                            comments_n={comments_n}
                            likes_n={likes_n}
                            //follows_n={follows_n}
                            followers_n={followers_n}
                            color="primary"
                        />
                        {openAuthor
                            ? <Tooltip title="Mostra autore">
                                <IconButton edge="end" aria-label="show" component={AdapterLink} to={"/app/authors/"+id_author}>
                                    <AccountCircleIcon />
                                </IconButton>
                            </Tooltip>
                            : null}
                    </Grid>
                    : null}
            </Grid>
            {addedFollow
                ? <SnackbarMessage
                    open={true}
                    autoHideDuration={2000}
                    severity={"success"}
                    message={"Ora segui " + author_nice}
                    onClose={() => {
                        this.setState({addedFollow:false})
                        hookOnCloseFollow()
                    }}
                />
                : null}
            {removedFollow
                ? <SnackbarMessage
                    open={true}
                    autoHideDuration={2000}
                    severity={"success"}
                    message={"Non segui più " + author_nice}
                    onClose={() => {
                        this.setState({removedFollow:false})
                        hookOnCloseUnfollow()
                    }}
                />
                : null}
        </React.Fragment>
    }
}

export default withStyles(style)(WidgetAuthorPreview)