import React from 'react'
import { Redirect } from 'react-router-dom'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
import Page401 from '../@react-fefmwk/theme-structure/modules/401'
//import EntityToolbar from '../@react-fefmwk/entity/modules/EntityToolbar'
import {idFromToken} from '../@react-fefmwk/auth/services/token'
import {entity_get} from '../@react-fefmwk/entity/services/api'
import Modal from '../@react-fefmwk/modals/modules/Modal'
import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'
import DeleteEntity from '../@react-fefmwk/entity/modules/DeleteEntity'

import Playlist from '../modules/Playlist'
import Search from '../modules/Search'

import Box from '@material-ui/core/Box'
//import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

class PlaylistPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            playlist: {},
            refresh: false,
            openEdit: false,
            openDelete: false,
            isDeleted: false,
        }
    }
    async getPlaylist() {
        const id_playlist = this.props.route.computedMatch.params.id
        const playlist = await entity_get('playlists',id_playlist)
        this.setState({
            playlist: playlist,
            refresh: false
        })
    }
    async componentDidMount() {
        await this.getPlaylist()
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh

        if( refresh && !prev_refresh ) {
            await this.getPlaylist()
        }
    }
    render() {
        const isDeleted = this.state.isDeleted
        if( isDeleted )
            return <Redirect to="/app/mypods" />

        const id_playlist = this.props.route.computedMatch.params.id
        const playlist = this.state.playlist
        if( !Object.keys(playlist).length ) return null

        const current_user_id = idFromToken()
        const playlist_user_id = playlist.hasOwnProperty('id_user') ? playlist.id_user : 0
        if( current_user_id != playlist_user_id )
            return <Page401 />

        const title = playlist.hasOwnProperty('playlist_title')
            ? playlist.playlist_title
            : this.props.route.title

        const navigation = <React.Fragment>
            <GoBackLink type="button"
                variant="text"
                color="secondary"
                Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
            />
        </React.Fragment>

        const openEdit = this.state.openEdit
        const openDelete = this.state.openDelete
        const toolbar = <Box ml={2} style={{textAlign:"right"}}>
            <Modal fullWidth maxWidth="sm" printCloseBtn
                title={"Modifica playlist"}
                open={openEdit}
                onOpen={() => this.setState({openEdit:true})}
                content={<React.Fragment>
                    <EntityForm 
                        entity="playlists"
                        id_entity={id_playlist}
                        onSubmitOk={() => this.setState({refresh:true,openEdit:false})}
                        textBtnSubmit="Modifica"
                        txtOnUpdateOk="Playlist modificata"
                        gridProps={{spacing:0}}
                        customFieldsInfo={{
                            id_user: {input_type: "hidden_autouserid"},
                            created_by: {input_type: "hidden_autouserid"},
                            created_at: {input_type: "hidden_timestamp"},
                        }}
                        gridItemProps={{
                            playlist_title: {xs:12},
                        }}
                    />
                </React.Fragment>}
                Trigger={<IconButton color="primary" aria-label="Edit playlist">
                    <EditIcon />
                </IconButton>}
                tooltipText="Modifica playlist"
                tooltipPlacement="bottom"
            />
            <Modal fullWidth maxWidth="xs" printCloseBtn contentAlign="center"
                title={"Vuoi eliminare la playlist?"}
                open={openDelete}
                onOpen={() => this.setState({openDelete:true})}
                content={<React.Fragment>
                    <Box>
                    <DeleteEntity withView={false}
                        entity="playlists"
                        id_entity={id_playlist}
                        textBtnSubmit="Elimina"
                        txtOnDeletionOk="Playlist eliminata"
                        onDeletionOk={() => this.setState({isDeleted:true,openDelete:false})}
                    />
                    </Box>
                </React.Fragment>}
                Trigger={<IconButton color="primary" aria-label="Delete playlist">
                    <DeleteIcon />
                </IconButton>}
                tooltipText="Elimina playlist"
                tooltipPlacement="bottom"
            />
        </Box>

        return <Content title={title} navigation={navigation} maxWidth="md" toolbar={toolbar}>
            <Box mb={4} p={2}>
                <Search app="spotify" placeholder="Aggiungi un Podcast alla playlist..." id_playlist={id_playlist} podPreviewScope="search.toPlaylist" />
            </Box>
            <Playlist id_playlist={id_playlist} withTitle={false} withToolbar={false} />
        </Content>
    }
}

export default PlaylistPage