import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Formik,Form,ErrorMessage } from 'formik'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'
import Modal from '../@react-fefmwk/modals/modules/Modal'
import ResetPasswordForm from '../@react-fefmwk/auth/modules/ResetPasswordForm'

import {
    idFromToken,
    displaynameFromToken
} from '../@react-fefmwk/auth/services/token'
import {
    users_get_options,
    users_create_options
} from '../services/BE'

//import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'
//import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
//import Avatar from '@material-ui/core/Avatar'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = (theme) => ({})

class Settings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            get_options: false,
            form: {},
            submit: false,
            affected_rows: 0,
        }
    }
    async componentDidMount() {
        const id_user = idFromToken()
        const options = await users_get_options(id_user)

        this.setState({
            get_options: true,
            form: Object.keys(options).length <= 0
                ? this.optionsMockUp()
                : options
        })
    }
    optionsMockUp() {
        return {
            email_notifications: false,
        }
    }
    validate(values) {
        const errors = {}
        let errors_n = 0

        return errors_n > 0 ? errors : {}
    }
    async submit(values,actions) {
        const id_user = idFromToken()
        const submit = await users_create_options(id_user,values)
        const affected_rows = submit.hasOwnProperty('affected_rows') ? submit.affected_rows : 0

        this.setState({
            submit: affected_rows > 0 ? true : false,
            affected_rows: affected_rows
        })
    }

    render() {
        const get_options = this.state.get_options
        if( !get_options )
            return <LoaderSingleComponent width="100%" />

        const form = this.state.form
        const submit = this.state.submit
        const affected_rows = this.state.affected_rows
        const submitOk = submit && affected_rows ? true : false
        const submitError = submit && affected_rows <= 0 ? true : false

        const id_user = idFromToken()
        const dispaly_name = displaynameFromToken()
        const user = {
            ID: id_user,
            user: dispaly_name
        }

        const { classes } = this.props

        return <React.Fragment>
            <Box>
                <Formik
                    validateOnMount={true}
                    enableReinitialize={true}
                    initialValues={form}
                    validate={(values) => {
                        return this.validate(values)
                    }}
                    //validationSchema={entityValidationSchema}
                    onSubmit={(values,actions) => {
                        return this.submit( values,actions )
                    }}
                    render={formikProps => {
                        const isValid = formikProps.isValid
                        const values = formikProps.values
                        const email_notifications = values.hasOwnProperty('email_notifications') ? values.email_notifications : 0
                        return <Form>
                            <Box mb={2}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        Seleziona le tue preferenze e salva
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button variant="contained" color="primary"
                                            type="submit"
                                            disabled={!isValid}
                                        >
                                            salva
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            <Box mb={4}>
                                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                                    Account
                                </Typography>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs="auto">
                                        <Modal
                                            maxWidth="sm"
                                            fullWidth={true}
                                            printCloseBtn
                                            title="Reset password"
                                            contentStyle={{}}
                                            content={<Box mb={2}>
                                                <ResetPasswordForm
                                                    user={user}
                                                    msgWelcome={(user) => <React.Fragment>
                                                        Ciao {user.user},<br />
                                                        inserisci la nuova password due volte.
                                                    </React.Fragment>}
                                                    msgAfterForgot="Password aggiornata. Utilizzala al prossimo accesso!"
                                                    buttons={(formikProps) => <React.Fragment>
                                                        <Button color="primary" variant="contained" className={classes.resetBtn}
                                                            type="submit"
                                                            disabled={!formikProps.isValid}
                                                        >Reset</Button>
                                                    </React.Fragment>}
                                                />
                                            </Box>}
                                            Trigger={<Button color="secondary" endIcon={<ChevronRightIcon />}>
                                                Reset password
                                            </Button>}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={1}>
                                    <ViewEntity
                                        id_entity={id_user}
                                        entity="users"
                                        noCard noTitle
                                    />
                                </Box>
                            </Box>
                            <Box mt={2} mb={4}>
                                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                                    Form di profilazione
                                </Typography>
                                <Paper elevation={0}>
                                    <List>
                                        <ListItem>
                                            <ListItemText>
                                                <Typography component="span" variant="body2">
                                                    Modifica le tue risposte
                                                </Typography>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton component={AdapterLink} to="/app/survey?edit" color="secondary">
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Box>
                            <Box mt={2} mb={4}>
                                <Typography component={Box} p={2} variant="subtitle1" color="primary">
                                    Notifiche
                                </Typography>
                                <Paper elevation={0}>
                                    <List>
                                        <ListItem>
                                            <ListItemText>
                                                <Typography component="span" variant="body2">
                                                    Voglio ricevere le notifiche via mail
                                                </Typography>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    name="email_notifications"
                                                    onChange={formikProps.handleChange}
                                                    checked={email_notifications > 0 ? true : false}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Box>
                        </Form>
                    }}
                />
            </Box>
            <SnackbarMessage
                open={submit}
                message={submitError ? "Opzioni non salvate" : "Opzioni salvate. Grazie"}
                severity={submitError ? "error" : "success"}
                autoHideDuration={2000}
                onClose={() => {
                    this.setState({
                        submit:false,
                        affected_rows:0,
                    })
                }}
            />
            {/*Object.keys(profile_spotify).length
                ? <Box mb={2}>
                    <Paper elevation={0}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={profile_spotify.images.length ? profile_spotify.images[0].url : ''} />
                                </ListItemAvatar>
                                <ListItemText primary={<span>{profile_spotify.display_name}</span>} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="show" component={ExternalLink} to={profile_spotify.external_urls.spotify}>
                                        <OpenInNewIcon color="secondary" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Paper>
                </Box>
                : <LoaderSingleComponent width="100%" />*/}
        </React.Fragment>
    }
}

export default withStyles(styles)(Settings)