export const EntitySchema = {
    "users": {
        "entity": "users",
        "id_field": "id_user",
        "title": "Utenti",
        "title_singular": "Utente",
        "rootpath": "/users",
        "db": "meetpods",
        "table": "users",
    },
    "follows": {
        "entity": "follows",
        "id_field": "id_follow",
        "title": "Follows",
        "title_singular": "Follow",
        "rootpath": "/follows",
        "db": "meetpods",
        "table": "follows",
    },
    "users_mypods": {
        "entity": "users_mypods",
        "id_field": "id_user_mypod",
        "title": "MyPods",
        "title_singular": "MyPod",
        "rootpath": "/users_mypods",
        "db": "meetpods",
        "table": "users_mypods",
    },
    "posts": {
        "entity": "posts",
        "id_field": "id_post",
        "title": "Posts",
        "title_singular": "Post",
        "rootpath": "/posts",
        "db": "meetpods",
        "table": "posts",
    },
    "posts_comments": {
        "entity": "posts_comments",
        "id_field": "id_post_comment",
        "title": "Posts comments",
        "title_singular": "Post comment",
        "rootpath": "/reviews_comments",
        "db": "meetpods",
        "table": "posts_comments",
    },
    "posts_likes": {
        "entity": "posts_likes",
        "id_field": "id_post_like",
        "title": "Posts likes",
        "title_singular": "Post like",
        "rootpath": "/posts_likes",
        "db": "meetpods",
        "table": "posts_likes",
    },
    "reviews": {
        "entity": "reviews",
        "id_field": "id_review",
        "title": "Reviews",
        "title_singular": "Review",
        "rootpath": "/reviews",
        "db": "meetpods",
        "table": "reviews",
    },
    "reviews_comments": {
        "entity": "reviews_comments",
        "id_field": "id_review_comment",
        "title": "Reviews comments",
        "title_singular": "Review comment",
        "rootpath": "/reviews_comments",
        "db": "meetpods",
        "table": "reviews_comments",
    },
    "reviews_likes": {
        "entity": "reviews_likes",
        "id_field": "id_review_like",
        "title": "Reviews likes",
        "title_singular": "Review like",
        "rootpath": "/reviews_likes",
        "db": "meetpods",
        "table": "reviews_likes",
    },
    "podcasts_categories": {
        "entity": "podcasts_categories",
        "id_field": "id_category",
        "title": "Podcasts categories",
        "title_singular": "Podacasts category",
        "rootpath": "/podcasts_categories",
        "db": "meetpods",
        "table": "podcasts_categories",
    },
    "playlists": {
        "entity": "playlists",
        "id_field": "id_playlist",
        "title": "Playlist",
        "title_singular": "Playlist",
        "rootpath": "/playlists",
        "db": "meetpods",
        "table": "playlists",
    },
    "playlists_items": {
        "entity": "playlists_items",
        "id_field": "id_playlist_item",
        "title": "Playlist items",
        "title_singular": "Playlist item",
        "rootpath": "/playlists_items",
        "db": "meetpods",
        "table": "playlists_items",
    },
}