import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Footer from '../Footer'

import '../theme.css'

const FullWidthNoNavPageStyle = theme => ({
    app: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    appBody: {
        ...theme.globals.appBodyNoHeader,
        ...{
            flex: "1 auto",
            height: 0,
            //overflowY:"auto"
        },
    },
    monoBody: {
        //overflowY: 'auto',
        height: '100%'
    },
})

class PageFullWidthNoNav extends React.Component {
    render() {
        const { classes } = this.props

        return <div id="App" className={classes.app}>
            <div id="AppBody" className={classes.appBody}>
                <div id="Mono" className={classes.monoBody}>
                    {this.props.children}
                </div>
            </div>
            <Footer itemXs={4} />
        </div>
    }
}

export default withStyles(FullWidthNoNavPageStyle)(PageFullWidthNoNav)