import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'

import {authors_get_obj} from '../services/BE'

import Followers from './Followers'

import { Chip } from '@material-ui/core'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

const styles = (theme) => ({})

class MyCommunity extends React.Component {
    constructor(props) {
        super(props)

        const id_user = idFromToken()
        this.state = {
            id_user: id_user,
            userObj: {}
        }
    }
    async componentDidMount() {
        const id_user = this.state.id_user
        this.setState({
            userObj: await authors_get_obj( id_user )
        })
    }

    render() {
        const id_user = this.state.id_user
        const userObj = this.state.userObj
        if( !Object.keys(userObj).length )
            return <LoaderSingleComponent width="100%" />

        return <React.Fragment>
            <Tabber basicname={"mycommunity"}
                tabs={[
                    {
                        slug: "follows",
                        label: <React.Fragment><Chip label={userObj.follows_n} size="small" />seguiti</React.Fragment>,
                        content: <Followers id_user={id_user} scope="follows" />
                    },
                    {
                        slug: "followers",
                        label: <React.Fragment><Chip label={userObj.followers_n} size="small" />followers</React.Fragment>,
                        content: <Followers id_user={id_user} scope="followers" />
                    },
                    {
                        slug: "friends",
                        label: <React.Fragment><Chip label={userObj.friends_n} size="small" />amici</React.Fragment>,
                        content: <Followers id_user={id_user} scope="friends" />
                    },
                ]}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(MyCommunity)