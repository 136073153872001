import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'

import {entity_delete} from '../@react-fefmwk/entity/services/api'

import Review from '../modules/Review'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const style = theme => {
    return {
        reviewPaper: {padding: 20},
        alignTextRight: {textAlign:'right'},
        linkNoDecoration: {textDecoration:'none'},
    }
}

class ReviewDelete extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_review: this.props.id_review,
            deletion: null,
        }
    }
    async delReview(e,id_review) {
        e.persist()

        console.log(id_review)
        this.setState({
            deletion: await entity_delete('reviews',id_review)
        })
    }
    render() {
        const id_review = this.state.id_review
        const deletion = this.state.deletion

        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : '/app/pods-wall'

        const {classes} = this.props

        return <React.Fragment>
            <Paper elevation={0} className={classes.reviewPaper}>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={8}>
                    <Typography component="p" variant="body1">
                        Sei sicuro di voler eliminare la recensione?
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.alignTextRight}>
                    <Button variant="contained" color="primary"
                        onClick={(e) => this.delReview(e,id_review)}
                    >
                        Elimina
                    </Button>
                </Grid>
            </Grid>
            </Paper>
            <br />
            <Review id_review={id_review} comments={false} header={false} />
            {deletion !== null
                ? <SnackbarMessage
                    open={deletion ? true : false}
                    autoHideDuration={2000}
                    severity={deletion ? "success" : "error"}
                    message={deletion ? "Recensione eliminata" : "Recensione non eliminata"}
                    onClose={() => (window.location.href = redirectTo)}
                />
                : null}
        </React.Fragment>
    }
}

export default withStyles(style)(ReviewDelete)