import React from 'react'
//import { Redirect } from "react-router-dom"

import {entity_q} from '../@react-fefmwk/entity/services/api'
import { idFromToken } from '../@react-fefmwk/auth/services/token'
import {encodeBase64} from '../@react-fefmwk/links/service/api'
import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import RedirectBack from '../@react-fefmwk/links/modules/RedirectBack'

import PodPreview from './PodPreview'

import {spotify_shows_get,spreaker_shows_get} from '../services/BE'
import {podPreviewActionsSchema} from '../services/PodPreviewActionsSchema'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import AddIcon from '@material-ui/icons/Add'

class AddPlaylistItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            podcast: {},
            user_playlists: [],
            redirectBack: false,
        }
    }

    async componentDidMount() {
        const code = this.props.code
        const app = this.props.app

        //get show
        let shows = {}
        let podcast = {}
        switch( app ) {
            case 'spreaker':
                shows = await spreaker_shows_get( code )
                if( shows.hasOwnProperty('show') )
                    podcast = shows.show
            break
            case 'spotify':
                shows = await spotify_shows_get( code )
                if( Object.keys(shows).length )
                    podcast = shows
                /*if( shows.hasOwnProperty('shows') && shows.shows.length )
                    podcast = shows.shows[0]*/
            break
        }

        //get user's playlist
        const current_user_id = idFromToken()
        const user_playlists = await entity_q(
            'playlists',
            {filters:{
                id_user: current_user_id,
                playlist_title: {value:"Consigliati da un amico",operator:"!="}
            }}
        )

        this.setState({
            podcast: podcast,
            user_playlists: user_playlists
        })
    }

    render() {
        const podcast = this.state.podcast
        const hasChosen = Object.keys(podcast).length
        if( !hasChosen ) return <LoaderSingleComponent width="100%" />

        const app = this.props.app
        const code = this.props.code
        const id_playlist = this.props.id_playlist
        const redBack = this.props.hasOwnProperty('redBack') ? this.props.redBack : false
        const redirectBack = this.state.redirectBack
        const user_playlists = this.state.user_playlists

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('add_playlist_item')

        //simulate validation on mount
        let btnSubmitDisabledProp = {}
        if( id_playlist ) btnSubmitDisabledProp['btnSubmitDisabled'] = false

        const AddNewPlaylistBtn = <Tooltip title="Crea nuova playlist">
            <Button color="primary" size="small" variant="outlined"
                startIcon={<AddIcon />}
                component={AdapterLink} to="/app/myplaylists?add-new"
            >
                Aggiungi
            </Button>
        </Tooltip>

        return <React.Fragment>
            <Paper elevation={0}>
                <PodPreview podcast={podcast} withList={true} app={app} {...podPreviewProps} />
            </Paper>
            {hasChosen
                ? <Box mt={2}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="p" variant="body2">
                                Scegli tra le tue playlist o creane una nuova!&nbsp;&nbsp;&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item>
                            {AddNewPlaylistBtn}
                        </Grid>
                    </Grid>
                    {user_playlists.length
                        ? <EntityForm 
                            entity="playlists_items"
                            precompiledData={{
                                id_playlist: id_playlist,
                                podcast_code: code,
                                podcast_app: app,
                                podcast_info: encodeBase64(JSON.stringify(podcast))
                            }}
                            customFieldsInfo={{
                                id_playlist: {
                                    nice_name: "Scegli playlist",
                                    input_options: {
                                        ref_field: "playlist_title",
                                        ref_key: "id_playlist",
                                        items: user_playlists
                                    }
                                },
                                created_at: {input_type: "hidden"},
                                created_by: {input_type: "hidden"},
                                podcast_code: {input_type: "hidden"},
                                podcast_app: {input_type: "hidden"},
                                podcast_info: {input_type: "hidden"},
                            }}
                            entityInputProps={{
                                id_playlist: {width:'100%'},
                            }}
                            gridItemProps={{
                                id_playlist: {xs:12},
                            }}
                            //fieldsOrder={["id_playlist","podcast_code","podcast_app","podcast_info"]}
                            //onSubmitOk={() => this.setState({redirect:true})}
                            onSubmitOk={() => this.setState({redirectBack:redBack})}
                            textBtnSubmit="Aggiungi"
                            txtOnCreationOk="Show aggiunto all playlist"
                            startIconBtnSubmit={null}
                            {...btnSubmitDisabledProp}
                        />
                        : <Typography component="p" variant="body2">
                            Non hai ancora creato playlist: creane una!&nbsp;&nbsp;&nbsp;
                        </Typography>}
                </Box>
                : null
            }
            {redirectBack ? <RedirectBack /> : null}
        </React.Fragment>
    }
}

export default AddPlaylistItem