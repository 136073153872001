import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
//import EntityToolbar from '../@react-fefmwk/entity/modules/EntityToolbar'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

import MyPlaylists from '../modules/MyPlaylists'

//import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class MyPlaylistsPage extends React.Component {
    render() {
        const title = this.props.route.title
        //const a = this.props.route.computedMatch.params.id
        const queryStrings = queryStringToObj(this.props.route.location.search)
        const openAddNew = queryStrings.hasOwnProperty('add-new') ? true : false

        const navigation = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
        />

        return <Content title={title} navigation={navigation} maxWidth="md">
            <MyPlaylists withToAllPlaylists={false} withTitle={false} withToolbar={true} openAddNew={openAddNew} />
        </Content>
    }
}

export default MyPlaylistsPage