import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'
import BuildIcon from '@material-ui/icons/Build'

export function actionSchema( entity,path ) {
    switch (entity) {
        default:
            return [
                {
                    action: "view",
                    header: "View",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal"
                },
                {
                    action: "edit",
                    header: "Edit",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Delete",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
    }
}