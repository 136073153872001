import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
    users_get_follows,
    users_get_followers,
    users_get_friends
} from '../services/BE'

import Table from '../@react-fefmwk/tables/modules/Table'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import WidgetAuthorPreview from './widgets/WidgetAuthorPreview'
import { Box,Tooltip,Chip,Typography } from '@material-ui/core'

const style = theme => {
    return {
        linkNoDecoration: {textDecoration:'none'},
    }
}

class Followers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            loading: false,
            refresh: false
        }
    }
    async componentDidMount() {
        await this.getFollowsData()
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh
        if( refresh && !prev_refresh )
            await this.getFollowsData()
    }
    async getFollowsData() {
        this.setState({
            loading: true
        })

        const id_user = this.props.id_user
        const scope = this.props.scope

        let data = []
        switch( scope ) {
            case "follows":
                data = await users_get_follows(id_user)
            break
            case "friends":
                data = await users_get_friends(id_user)
            break
            default:
            case "followers":
                data = await users_get_followers(id_user)
            break
        }

        this.setState({
            data: data,
            loading: false,
            refresh: false
        })
    }

    render() {
        const loading = this.state.loading
        const refresh = this.state.refresh
        const data = this.state.data

        const pageSize = 20

        return <React.Fragment>
            {!data.length || refresh
                ? (loading ? <LoaderSingleComponent width="100%" /> : <Chip label="No data available" />)
                : <Table material
                    hideColumnsNames
                    //hideHeaders
                    //dense
                    //highlight
                    //calcCellWidth
                    data={data}
                    defaultPageSize={pageSize}
                    showPagination={true}
                    searchTxt="Cerca..."
                    //filterable={pageSize < data.length ? true : false}
                    //sortable={pageSize < data.length ? true : false}
                    fieldsProps={{
                        is_friend: {
                            Header: "Is friend",
                            nice_name: "Is friend",
                            show: false,
                            Cell: (props) => {
                                const value=props.value
                                const isFriend = parseInt(value) === 1 ? true : false
                                return <Tooltip title={isFriend ? "Amico" : "Non amico"} placement="right">
                                    <Chip size="small" color="primary"
                                        label={isFriend ? "Amico" : "Non amico"}
                                        variant={isFriend ? "default" : "outlined"}
                                    />
                                </Tooltip>
                            },
                            filterable: false,
                        },
                        id_follower: {
                            Header: "",
                            nice_name: "Follower ID",
                            Cell: (props) => {
                                const value=props.value
                                return <WidgetAuthorPreview
                                    id_author={value}
                                    withAvatar={false}
                                    withAuthorName={false}
                                    withActions={true}
                                    withStats={false}
                                    hookOnCloseFollow={() => this.setState({refresh:true})}
                                    hookOnCloseUnfollow={() => this.setState({refresh:true})}
                                />
                            },
                            //minWidth: 200,
                            filterable: false,
                        },
                        id_user: {
                            Header: "IS seguito",
                            nice_name: "Followed ID",
                            Cell: (props) => {
                                const value=props.value
                                return <WidgetAuthorPreview
                                    id_author={value}
                                    withAvatar={false}
                                    withAuthorName={false}
                                    withActions={true}
                                    withStats={false}
                                    hookOnCloseFollow={() => this.setState({refresh:true})}
                                    hookOnCloseUnfollow={() => this.setState({refresh:true})}
                                />
                            },
                            //minWidth: 200,
                            style: {textAlign:'right'},
                            filterable: false,
                        },
                        follower: {
                            Header: "Follower",
                            nice_name: "Follower",
                            Cell: (props) => {
                                const value=props.value
                                const id_follower = props.row.hasOwnProperty('id_follower') ? props.row.id_follower : 0
                                return <AdapterLink to={"/app/authors/"+id_follower} style={{textDecoration: 'none',color:'inherit'}}>
                                    <Typography variant="body1" color="initial">{value}</Typography>
                                </AdapterLink>
                            },
                            filterable: true,
                        },
                        followed: {
                            Header: "Seguiti",
                            nice_name: "Followed",
                            Cell: (props) => {
                                const value=props.value
                                const id_user = props.row.hasOwnProperty('id_user') ? props.row.id_user : 0
                                return <AdapterLink to={"/app/authors/"+id_user} style={{textDecoration: 'none',color:'inherit'}}>
                                    <Typography variant="body1" color="initial">{value}</Typography>
                                </AdapterLink>
                            },
                            filterable: true,
                        },
                    }}
                />}
        </React.Fragment>
    }
}

export default withStyles(style)(Followers)