import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

import WritePost from '../modules/WritePost'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class WritePostPage extends React.Component {
    render() {
        const title = this.props.route.title
        const queryStrings = queryStringToObj(this.props.route.location.search)
        const code = queryStrings.hasOwnProperty('podcast_code') ? queryStrings.podcast_code : ''
        const app = queryStrings.hasOwnProperty('podcast_app') ? queryStrings.podcast_app : ''

        const navigation = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
        />

        return <Content title={title} navigation={navigation} maxWidth="md">
            <WritePost app={app} code={code} />
        </Content>
    }
}

export default WritePostPage