import * as Yup from 'yup'

export function validationSchema( entity ) {
    switch (entity) {
        /*case 'company':
            return (
                Yup.object().shape({
                vat_number: Yup.string()
                  .min(11, 'Too Short! 11 digits required')
                  .max(11, 'Too Long! 11 digits required')
                  .required('Required'),
                name: Yup.string()
                  .min(4, 'Too Short!')
                  .max(200, 'Too Long!')
                  .required('Required'),
                lei: Yup.string()
                    .min(20, 'Too Short! 20 digits required')
                    .max(20, 'Too Long! 20 digits required'),
                cap: Yup.string()
                  .min(5, 'Too Short! 5 digits required')
                  .max(5, 'Too Long! 5 digits required')
              }));
        case 'security':
            return Yup.object().shape({}) ;
        case 'transaction':
            return Yup.object().shape({}) ;*/
        default:
            return Yup.object().shape({})
    }
}