export function fieldsViewSchema( entity ) {
    switch (entity) {
        /*case 'reviews':
            return [
                //"id_costrev_causal",
                //"id_stakeholder",
                //"id_currency",
            ]*/
        default:
            return []
    }
}
export function fieldsListSchema( entity ) {
    switch (entity) {
        default:
            return []
    }
}