Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var yellowSpreaker = {
  50: '#fff8e1',
    100: '#ffecb5',
    200: '#ffe083',
    300: '#ffd451',
    400: '#ffca2c',
    500: '#ffc107',
    600: '#ffbb06',
    700: '#ffb305',
    800: '#ffab04',
    900: '#ff9e02',
    A100: '#ffffff',
    A200: '#fffaf2',
    A400: '#ffe4bf',
    A700: '#ffd9a6',
    'contrastDefaultColor': 'dark',
};

var _default = yellowSpreaker;
exports.default = _default;