import React from 'react'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import {is_player_reachable} from '../../services/BE'

class PodcastListen extends React.Component {
    constructor(props) {
        super(props)

        const {app,code} = this.props
        this.state = {
            app: app,
            code: code,
            isEpisode: this.props.hasOwnProperty('isEpisode') ? this.props.isEpisode : false,
            url: "",
            height: "",
            isUrlOk: true,
            counter: 1,
        }
    }
    async componentDidMount() {
        const {app,code,isEpisode} = this.state

        let url = this.state.url
        let height = this.state.height
        switch(app) {
            case 'spotify':
                url += "https://open.spotify.com/embed"
                url += isEpisode ? "/episode/" : "/show/"
                url += code+"?utm_source=generator"
                //height = isEpisode ? 152 : 352
                height = 152
            break
            case 'spreaker':
                url += "https://widget.spreaker.com/player?"
                url += isEpisode ? "/episode_id" : "show_id="
                url += code+"&theme=dark&playlist=show"
                height = 200
            break
        }

        this.setState({
            url: url,
            height: height,
        })

        if( url ) {
            const isUrlOk = await is_player_reachable(url)
            if( !isUrlOk )
                this.setState({
                    isUrlOk: isUrlOk,
                })
        }
    }
    async componentDidUpdate(prevProps,prevState) {
        const {url,isUrlOk,counter} = this.state
        if( !isUrlOk ) {
            setTimeout(async () => {
                this.setState({
                    counter: counter + 1,
                    isUrlOk: await is_player_reachable(url),
                })
            },2000)
        }
    }
    render() {
        const {app,url,isUrlOk,counter,code} = this.state
        if( !isUrlOk )
            return <LoaderSingleComponent width="100%" />

        const borderRadius = 12
        const height = this.state.height

        let Player = null
        switch(app) {
            case 'spotify':
                Player = <iframe style={{borderRadius:borderRadius}}
                    src={url}
                    width="100%"
                    height={height}
                    frameBorder="0"
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"></iframe>
            break
            case 'spreaker':
                Player = <iframe style={{borderRadius:borderRadius}}
                    src={url}
                    width="100%"
                    height={height}
                    frameBorder="0"></iframe>
            break
        }

        return <React.Fragment>
            {Player}
        </React.Fragment>
    }
}

export default PodcastListen