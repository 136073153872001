import React from 'react'

import { isPodcaster } from './BE'
import { idFromToken } from '../@react-fefmwk/auth/services/token'

import NotificationsBell from '../@react-fefmwk/messages/modules/NotificationsBell'

import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MicIcon from '@material-ui/icons/Mic'

export const NavTopTitle = ""
export const NavTopImgPath = "/images/logo.png"
export const ShowUsername = false

export const NavTopSchema = [
    {
        "slug": "pods-wall",
        "icon": null,
        "title": "Podswall",
        "path": "/app/pods-wall",
        "path_type": "internal",
    },
    {
        "slug": "discover",
        "icon": null,
        "title": "Scopri",
        "path": "/app/search",
        "path_type": "internal",
    },
    {
        "slug": "notifications",
        "component": <NotificationsBell notificationsPagePath="/app/notifications" />,
    },
]

let userDropDown = []
userDropDown.push({
    "slug": "profile",
    "icon": PersonOutlineIcon,
    "title": "Profilo",
    "path": "/app/profile",
    "path_type": "internal",
})
if(isPodcaster())
    userDropDown.push({
        "slug": "profile-podcaster",
        "icon": MicIcon,
        "title": "Podcaster",
        "path": "/app/podcasters/"+idFromToken(),
        "path_type": "internal",
    })
userDropDown.push({
    "slug": "settings",
    "icon": SettingsIcon,
    "title": "Impostazioni",
    "path": "/app/settings",
    "path_type": "internal",
})
userDropDown.push({
    "slug": "notifications",
    "icon": NotificationsIcon,
    "title": "Notifiche",
    "path": "/app/notifications",
    "path_type": "internal",
})
export const UserDropdownSchema = userDropDown