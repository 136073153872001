import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import WidgetPodsCarousel from './widgets/WidgetPodsCarousel'

import {reviews_list_mostreviewed} from '../services/BE'
import { Box,Typography } from '@material-ui/core'

const styles = (theme) => ({})

class MostReviewdPodsCarousel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pods: []
        }
    }
    async componentDidMount() {
        const pods = await reviews_list_mostreviewed()
        this.setState({
            pods: pods
        })
    }
    render() {
        const pods = this.state.pods

        //const { classes } = this.props
        const height = this.props.hasOwnProperty('height') ? this.props.height : 300
        const outfit = this.props.hasOwnProperty('outfit') ? this.props.outfit : "standard"

        return <Box style={{height:height}}>
            <Box mb={1}>
                <Typography component="h5" variant="h5"
                    style={{color: outfit === "white" ? "white" : "initial"}}
                >
                    I più recensiti
                </Typography>
            </Box>
            {!pods.length
                ? <LoaderSingleComponent width="100%" />
                : <WidgetPodsCarousel pods={pods} />}
        </Box>
    }
}

export default withStyles(styles)(MostReviewdPodsCarousel)