import { createMuiTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

import {it} from 'date-fns/locale'

import greenMeetpods from './colors/green-meetpods'
import purpleMeetpods from './colors/purple-meetpods'
import greenSpotify from './colors/green-spotify'
import yellowSpreaker from './colors/yellow-spreaker'
import grey from '@material-ui/core/colors/grey'

greenMeetpods.main = greenMeetpods[700]
purpleMeetpods.main = purpleMeetpods[900]
greenSpotify.main = greenSpotify[500]
yellowSpreaker.main = yellowSpreaker[500]
const palette = {
    primary: greenMeetpods,
    secondary: purpleMeetpods,
    grey: grey,
    spotify: greenSpotify,
    spreaker: yellowSpreaker,
}
export const maxFileSize = 2 * 1024 * 1024;//2MB
const appHeaderBarHeight = 64
const appNavSideWidth = 250
const defaultTheme = createMuiTheme()
export default createMuiTheme({
    typography: {//included in theme.css
        fontFamily: ['Circular ProTT Book'].join(",")
    },
    palette: palette,
    locale: it,
    homeUrl: "/app/pods-wall",
    navtop_logo: '/images/logo.png',
    //navtop_title: 'Meetpods',
    globals: {
        appHeaderBar: {
            boxShadow: 'none',
            color: 'white',
            //color: palette.secondary.main,
            height: appHeaderBarHeight,
            minHeight: appHeaderBarHeight,
            borderRadius: 0,
        },
        appHeaderTitleBox: {
            flexGrow: 1,
        },
        appHeaderTitleText: {
            //fontWeight: 400,
            color: 'inherit',
        },
        appHeaderLogoBox: {
            marginRight: 20,
            height: '70%',
            maxHeight: 60
        },
        appHeaderLogoImg: {
            height: '100%',
        },
        appHeaderNavTopBox: {
            color: 'inherit',
        },
        appNavTopItem: {
            textDecoration: 'none',
            color: 'inherit',
            marginLeft: '2%',
            marginRight: '2%'
        },
        appNavTopItemText: {
            //textTransform: 'uppercase',
            //fontWeight: 500
        },
        appNavTopItemDivider: {
            marginLeft: '2%',
            marginRight: '1%'
        },
        appUserAvatar: {
            width: 35,
            height: 35,
            lineHeight: 35,
            fontSize: '108%',
            backgroundColor: palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                transform: 'scale(1.1)'
            },
            cursor:'pointer'
        },
        appNavSideDrawer: {
            width: appNavSideWidth,
            borderRadius: 0,
            [defaultTheme.breakpoints.up('sm')]: {
                backgroundColor: 'transparent'
            },
            borderRight: 0,
            paddingTop: 5,
            position: 'relative',
        },
        appNavSideCloseBtn: {
            borderTop: '1px solid lightgrey',
        },
        appNavSideItem: {
            borderTopRightRadius: 25,
            borderBottomRightRadius: 25,
        },
        appNavSideItemTitle: {
            paddingBottom: 0,
            fontWeight: 900,
            color: palette.grey[700],
            borderTop: '1px solid lightgrey',
        },
        appNavSideItemIcon: {
            minWidth: 30,
            color: palette.primary.main,
        },
        appNavSideItemText: {
            color: palette.grey[600],
        },
        appNavSideItemSelected: {
            backgroundColor: fade(palette.primary.main,0.1),
            //borderRight: "3px solid",
            //borderRightColor: palette.primary.main,
            borderTopRightRadius: 25,
            borderBottomRightRadius: 25,
        },
        appNavSideItemIconSelected: {
            fontWeight: 900,
            color: palette.primary.main,
        },
        appNavSideItemTextSelected: {
            fontWeight: 900,
            color: palette.primary.main,
        },
        appNavSideItemTextInCollapsedSelected: {
            fontWeight: 900,
            color: palette.grey[700]
        },
        appBody: {
            paddingTop: appHeaderBarHeight,
        },
        rightBody: {
            //overflowY: 'unset',
        },
        leftBody: {
            //overflowY: 'unset',
        },
        appPageContent: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        appPageBoxContent: {
            overflowY: 'unset',
            overflowX: 'unset',
        },
        appPageTitle: {
            marginTop: 15,
            marginBottom: 15,
            color: palette.primary.main,
            fontWeight: 'bold'
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottomColor: palette.primary.main
                }
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 20,
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: palette.primary.main,
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: 60,
                textTransform: 'capitalize',
            },
            contained: {
                boxShadow: 0,
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 25,
            },
        },
        MuiCard: {
            root: {
                borderRadius: 25,
            },
        },
    },
})