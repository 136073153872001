import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { getObjPath } from '../../arrays/api'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const style = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
})

class FieldCheckboxes extends React.Component {
    render() {
        const name = this.props.name
        if( !this.props.hasOwnProperty("items") || !this.props.items.length )
            return <Chip label={"No "+name+" found"} variant="outlined" style={{marginTop:20}}/>
        
        const title = this.props.title ? this.props.title : ""
        const id = this.props.id ? this.props.id : ""
        const row = this.props.row ? this.props.row : false
        const fieldId = this.props.fieldId
        const fieldName = this.props.fieldName
        const checks = this.props.hasOwnProperty('checks') ? this.props.checks : []
        const formikProps = this.props.hasOwnProperty('formikProps') ? this.props.formikProps : {}

        const items = this.props.items
        const disabled = this.props.disabled
        const checkboxes = items.map((item, i) => {
            return <FormControlLabel key={i} id={id}
                control={<Checkbox
                    name={name}//must be set as array in InitialValues to store multiple choices
                    value={item[fieldId]}
                    checked={checks.includes(item[fieldId]) ? true : false}
                    onChange={(e,v) => {
                        this.props.onChange(e,v)
                        if( Object.keys(formikProps).length ) {
                            //let val = formikProps.values[name]
                            let val = getObjPath(formikProps.values,name)
                            if( e.target.checked ) {
                                if( Array.isArray(val) )
                                    val.push(item[fieldId])
                                else
                                    val = item[fieldId]
                            } else {
                                val = Array.isArray(val)
                                    ? val.filter(opt => String(opt) === String(item[fieldId]) ? false : true)
                                    : ""
                            }
                            formikProps.setFieldValue(name,val)
                            formikProps.setFieldTouched(name)
                        }
                    }}
                    onBlur={this.props.onBlur}
                    disabled={disabled}
                />}
                label={item[fieldName]}
            />})

        const { classes } = this.props

        return <FormControl className={classes.formControl}>
            <FormLabel className="MuiInputLabel-shrink">{title}</FormLabel>
            <FormGroup row={row}>
                {checkboxes}
            </FormGroup>
        </FormControl>
    }
}

export default withStyles(style)(FieldCheckboxes)