import React from 'react'

import Tabber from '../@react-fefmwk/tabs/modules/Tabber'

import SpotifySearch from './spotify/SpotifySearch'
import SpreakerSearch from './spreaker/SpreakerSearch'

class Search extends React.Component {
    render() {
        const app = this.props.hasOwnProperty('app')
            ? this.props.app
            : 'all'

        const variant = this.props.hasOwnProperty('variant') ? this.props.variant : "standard"
        const outfit = this.props.hasOwnProperty('outfit') ? this.props.outfit : "standard"//standard|white
        const placeholder = this.props.hasOwnProperty('placeholder') ? this.props.placeholder : "Cerca podcast..."//standard|white

        const id_user = this.props.hasOwnProperty('id_user') ? this.props.id_user : 0
        const id_playlist = this.props.hasOwnProperty('id_playlist') ? this.props.id_playlist : 0
        const podPreviewScope = this.props.hasOwnProperty('podPreviewScope') ? this.props.podPreviewScope : 'search'
        const Trigger = this.props.Trigger

        return <React.Fragment>
            {app === 'all'
                ? <Tabber basicname="searchpods"
                    tabs={[
                        {
                            slug: "spotify",
                            label: "spotify",
                            content: <React.Fragment>
                                <SpotifySearch
                                    variant={variant}
                                    outfit={outfit}
                                    placeholder={placeholder}
                                    id_user={id_user}
                                    id_playlist={id_playlist}
                                    podPreviewScope={podPreviewScope}
                                    Trigger={Trigger}
                                />
                            </React.Fragment>
                        },
                        {
                            slug: "spreaker",
                            label: "spreaker",
                            content: <React.Fragment>
                                <SpreakerSearch
                                    variant={variant}
                                    outfit={outfit}
                                    placeholder={placeholder}
                                    id_user={id_user}
                                    id_playlist={id_playlist}
                                    podPreviewScope={podPreviewScope}
                                    Trigger={Trigger}
                                />
                            </React.Fragment>
                        },
                    ]}
                />
                : null}
            {app === 'spotify'
                ? <React.Fragment>
                    <SpotifySearch
                        variant={variant}
                        outfit={outfit}
                        placeholder={placeholder}
                        id_user={id_user}
                        id_playlist={id_playlist}
                        podPreviewScope={podPreviewScope}
                        Trigger={Trigger}
                    />
                </React.Fragment>
                : null}
            {app === 'spreaker'
                ? <React.Fragment>
                    <SpreakerSearch
                        variant={variant}
                        outfit={outfit}
                        placeholder={placeholder}
                        id_user={id_user}
                        id_playlist={id_playlist}
                        podPreviewScope={podPreviewScope}
                        Trigger={Trigger}
                    />
                </React.Fragment>
                : null}
        </React.Fragment>
    }
}

export default Search