//import InfoIcon from '@material-ui/icons/InfoOutlined'
//import React from 'react'

export function helpersFormSchema( entity ) {
    switch (entity) {
        default:
            return {
                id_rating: "Dai un voto al podcast: 6 bellissimo, 1 bruttissimo",
            }
    }
}