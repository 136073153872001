export function podPreviewActionsSchema( context = '' ) {
    switch (context) {
        case 'post':
            return {
                sponsorPod: true,
                writeReview: false,
                writePost: false,
                myPods: true,
                withPlayer: true,
                withDescription: true,
                addToPlaylist: true,
                openInApp: true,
                openShow: false,
            }
        case 'review':
            return {
                sponsorPod: true,
                writeReview: false,
                writePost: false,
                myPods: true,
                withPlayer: true,
                withDescription: true,
                addToPlaylist: true,
                openInApp: true,
                openShow: false,
            }
        case 'write_post':
        case 'write_review':
            return {
                sponsorPod: false,
                withStats: true,
                withDescription: true,
                withPlayer: false,
                writeReview: false,
                writePost: false,
                myPods: true,
                addToPlaylist: true,
                openInApp: true,
                openShow: true,
            }
        case 'show':
            return {
                sponsorPod: true,
                withStats: true,
                withPlayer: true,
                writePost: false,
                writeReview: true,
                myPods: true,
                addToPlaylist: true,
                openInApp: true,
                openShow: false,
                withDescription: true,
            }
        case 'mypods':
            return {
                sponsorPod: true,
                withStats: false,
                writePost: false,
                writeReview: true,
                addToPlaylist: true,
                myPods: false,
                openInApp: false,
                openShow: false,
            }
        case 'playlist':
            return {
                sponsorPod: false,
                writeReview: false,
                addToPlaylist: false,
                removeFromPlaylist: true,
                openInApp: false,
                openShow: false,
            }
        case 'playlist.sponsoredBy':
            return {
                sponsorPod: true,
                writeReview: true,
                addToPlaylist: true,
                removeFromPlaylist: false,
                openInApp: true,
                openShow: false,
            }
        case 'add_playlist_item':
            return {
                withStats: true,
                withDescription: false,
                withPlayer: false,
                writePost: false,
                writeReview: true,
                myPods: true,
                addToPlaylist: false,
                openInApp: true,
                openShow: true,
            }
        case 'sponsor_show':
            return {
                withStats: true,
                withDescription: true,
                withPlayer: false,
                writePost: false,
                writeReview: false,
                myPods: true,
                addToPlaylist: false,
                openInApp: true,
                openShow: true,
            }
        case 'search':
            return {
                sponsorPod: true,
                writePost: false,
                writeReview: false,
                addToPlaylist: true,
                myPods: true,
                openInApp: true,
                openShow: false,
            }
        case 'search.toReview':
            return {
                sponsorPod: false,
                writePost: false,
                writeReview: true,
                addToPlaylist: false,
                myPods: false,
            }
        case 'search.toPost':
            return {
                sponsorPod: false,
                writePost: true,
                writeReview: false,
                addToPlaylist: true,
                myPods: false,
            }
        case 'search.toPlaylist':
            return {
                sponsorPod: false,
                writePost: false,
                writeReview: false,
                addToPlaylist: false,
                addToPlaylistTxtAdd: true,
                myPods: false,
                openInApp: false,
                openShow: false,
            }
        default:
            return {}
    }
}