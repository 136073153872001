import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'

/**Route /notifications/push/sync is required
 * export async function notifications_push_sync( options = {} ) {
 *     let callUrl = getEP( "notifications_push_sync" )
 *     if( !callUrl ) return []
 * 
 *     return await doCall( callUrl,{
 *         method: 'GET',
 *         headers: {
 *             [headerTokenKey]: getToken()
 *         }
 *     },'json.array',options)
 * }
 */
import {notifications_push_sync} from '../../../services/BE'
//import {idFromToken,displaynameFromToken} from '../../auth/services/token'

import AdapterLink from '../../links/modules/AdapterLink'
import { Badge } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'

class NotificationsBell extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notifications_n: 0
        }
    }
    async componentDidMount() {
        const unread = this.props.hasOwnProperty('unread') ? this.props.unread : true
        const id_user = this.props.hasOwnProperty('id_user') ? this.props.id_user : 0
        const onSync = this.props.hasOwnProperty('onSync') ? this.props.onSync : () => true
        const notificationsPagePath = this.props.hasOwnProperty('notificationsPagePath') ? this.props.notificationsPagePath : ""
        const currentPage = this.props.location.pathname

        if( currentPage !== notificationsPagePath ) {
            //const id_user = idFromToken()
            //const dispaly_name = displaynameFromToken()

            //get user notifications
            const notifications = await notifications_push_sync(unread,id_user)
            //exec hook onSync
            if( onSync ) onSync()
            //msg in console.log
            console.log("!sync push notifications")

            this.setState({
                notifications_n: notifications.length
            })
        }
    }
    render() {
        const notifications_n = this.state.notifications_n
        const variant = this.props.hasOwnProperty('variant') ? this.props.variant : "dot"
        const color = this.props.hasOwnProperty('color') ? this.props.color : "error"
        const notificationsPagePath = this.props.hasOwnProperty('notificationsPagePath') ? this.props.notificationsPagePath : ""
        let invisible = this.props.hasOwnProperty('invisible') ? this.props.invisible : false
        invisible = notifications_n <= 0 ? true : false

        return <React.Fragment>
            <AdapterLink to={notificationsPagePath} style={{color:'inherit'}}>
                <Badge badgeContent={notifications_n} variant={variant} color={color} invisible={invisible}>
                    <NotificationsIcon fontSize="small" />
                </Badge>
            </AdapterLink>
        </React.Fragment>
    }
}

export default withRouter(NotificationsBell)