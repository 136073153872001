import React from 'react'

import { maxFileSize } from '../../../theme/theme'

import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'

import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import { Box } from '@material-ui/core'

class FieldFile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            files: [],
            uploaded: false,
            uploadMsgOpen: false,
            maxFileSize: maxFileSize ? maxFileSize : 8 * 1024 * 1024, // 8 MB
            fileSizeError: false,
        }

        this.onChange = this.onChange.bind(this)
        this.doUpload = this.doUpload.bind(this)
        this.emptyFiles = this.emptyFiles.bind(this)
        this.onCloseUploadMsg = this.onCloseUploadMsg.bind(this)
    }

    readableBytes(bytes,decimals = 2) {
        if(bytes == 0) return '0 Bytes';
        var k = 1024,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    emptyFiles() {
        this.setState({files: [],uploaded: false})
    }

    onChange(e) {
        e.persist()

        const formFiles = e.target.files
        const files = Array.from(formFiles)

        const maxFileSize = this.props.hasOwnProperty("maxFileSize") 
            ? this.props.maxFileSize 
            : this.state.maxFileSize
        const invalidFiles = files.filter(file => file.size > maxFileSize)
        const fileSizeError = invalidFiles.length ? true : false

        this.setState({
            files: formFiles,
            fileSizeError: fileSizeError,
        })
    }

    onCloseUploadMsg() {
        this.setState({uploadMsgOpen:false})
    }

    async doUpload() {
        const files = this.state.files
        const files_n = files.length
        if( !files_n ) return false

        const name = this.props.name
        const upload_f = this.props.upload_f

        let formFiles = new FormData()
        for( let f = 0; f < files_n; f++ )
            formFiles.append(name,files[f])

        const uploaded = await upload_f(
            formFiles,//file uploaded object
            files//clean uploaded files list
        )
        this.setState({
            uploaded: uploaded,
            files: [],
            uploadMsgOpen: true
        })
    }

    render() {
        const label = this.props.label ? this.props.label : ""
        const name = this.props.name
        const helperText = this.props.helperText ? this.props.helperText : ""
        const chooseButtonText = this.props.chooseButtonText ? this.props.chooseButtonText : "Choose"
        const uploadButtonText = this.props.uploadButtonText ? this.props.uploadButtonText : "Upload"
        const fileList = this.props.hasOwnProperty('fileList')
        const fileListStyle = this.props.fileListStyle ? this.props.fileListStyle : {}
        const webkitdirectory = this.props.webkitdirectory ? {webkitdirectory:"webkitdirectory"} : {}
        const multiple = webkitdirectory ? true : this.props.hasOwnProperty('multiple')
        const files = Array.from(this.state.files)
        const files_n = files.length
        const uploaded = this.state.uploaded
        const uploadMsgOpen = this.state.uploadMsgOpen
        const disabled = this.props.disabled
        const size = this.props.size
        const startIcon = this.props.startIcon

        const maxFileSize = this.state.maxFileSize
        const fileSizeError = this.state.fileSizeError

        const FilesList = (files_n && fileList)
            ? <List style={fileListStyle}>
                {files.map((file,f) => {
                    const file_size = file.size
                    const file_size_nice = this.readableBytes(file_size, 1)
                    const file_too_large = (maxFileSize && file_size > maxFileSize) ? true : false
                    const fileSizeErrorMsg = <b>&gt;&gt; file size exceeds {this.readableBytes(maxFileSize, 1)}</b>

                    const errorStyle = file_too_large
                        ? {color: "red"}
                        : {}

                    return <React.Fragment key={"file-"+f}>
                        <Box>
                            <Typography variant="caption">{file.name}</Typography>
                            <Typography variant="caption">&nbsp;&nbsp;{"[" + file_size_nice + "]"}</Typography>
                            {file_too_large
                                ? <Typography variant="caption" style={{...errorStyle,...{fontWeight:"bold !important"}}}>
                                    &nbsp;&nbsp;{fileSizeErrorMsg}
                                </Typography>
                                : null}
                            </Box>
                    </React.Fragment>
                })}
            </List>
            : null

        const UploadMsg = uploaded
            ? <Snackbar
                open={uploadMsgOpen}
                anchorOrigin={{vertical:"bottom",horizontal:"left"}}
                message="Upload correct"
                autoHideDuration={3000}
                onClose={() => this.onCloseUploadMsg()}
                action={<IconButton size="small" aria-label="close" color="inherit" onClick={() => this.onCloseUploadMsg()}>
                        <CloseIcon />
                    </IconButton>}
            />
            : null

        return <React.Fragment>
            <FormControl>
                <label>
                    {label ? <p>{label}</p> : null}
                    {helperText ? <p>{helperText}</p> : null}
                    {!files_n
                        ? <Button variant="contained" component="label" disabled={disabled} size={size} startIcon={startIcon}>
                            {chooseButtonText}
                            <input name={name} type="file" hidden disabled={disabled}
                                multiple={multiple}
                                {...webkitdirectory}
                                onChange={this.onChange}
                                onClick={() => this.emptyFiles()}
                            />
                        </Button>
                        : <React.Fragment>
                            <Button disabled={fileSizeError} size={size} onClick={() => this.doUpload()} variant="contained" color="primary">{uploadButtonText}</Button>
                            &nbsp;&nbsp;&nbsp;
                            <IconButton size="small" onClick={() => this.emptyFiles()}><DeleteIcon size="small" /></IconButton>
                            &nbsp;&nbsp;&nbsp;
                            <Chip label={files_n} size="small" />
                        </React.Fragment>}
                </label>
            </FormControl>
            {UploadMsg}
            {FilesList}
        </React.Fragment>
    }
}

export default FieldFile