import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Search from './Search'

import { Box } from '@material-ui/core'

const styles = (theme) => ({})

class PodswallSearch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pods: []
        }

    }
    render() {
        //const { classes } = this.props

        return <React.Fragment>
            <Box>
                <Search app="spotify" variant="standard" outfit="white" placeholder="Cosa vuoi recensire?" podPreviewScope="search.toReview" />
            </Box>
        </React.Fragment>
    }
}

export default withStyles(styles)(PodswallSearch)