import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'
//import MenuBookIcon from '@material-ui/icons/MenuBook'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
//import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'

export function getNavSideSchema() {
    const pathArray = window.location.pathname.split('/');
    const app = pathArray[1]

    switch (app) {
        default:
            return NavSideSchema
    }
}

export const ShowUserAvatar = false
export const CloseBtn = false
export const NavSideStartState = 'open'

export const NavSideSchema = [
    {
        "block": "main",
        "list": [
            {
                "slug": "pods-wall",
                "icon": HomeIcon,
                "title": "Podswall",
                "collapse": false,
                "path": "/app/pods-wall",
                "path_type": "internal",
            },
            {
                "slug": "discover",
                "icon": SearchIcon,
                "title": "Scopri",
                "collapse": false,
                "path": "/app/search",
                "path_type": "internal",
            },
            /*{
                "slug": "genres",
                "icon": MenuBookIcon,
                "title": "Generi",
                "collapse": false,
                "path": "/app/genres",
                "path_type": "internal",
            },*/
            {
                "slug": "my-pods",
                "icon": StarBorderIcon,
                "title": "MyPodcasts",
                "collapse": false,
                "path": "/app/mypods",
                "path_type": "internal",
            },
            {
                "slug": "my-community",
                "icon": PeopleOutlineIcon,
                "title": "MyCommunity",
                "collapse": false,
                "path": "/app/mycommunity",
                "path_type": "internal",
            },
            /*{
                "slug": "bookmarks",
                "icon": BookmarkBorderIcon,
                "title": "Salvati per dopo",
                "collapse": false,
                "path": "/app/bookmarks",
                "path_type": "internal",
            },*/
        ],
    },
]