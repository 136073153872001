Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var greenSpotify = {
  50: '#e4faec',
  100: '#bcf3cf',
  200: '#8febb0',
  300: '#62e390',
  400: '#40dd78',
  500: '#1ed760',
  600: '#1ad358',
  700: '#16cd4e',
  800: '#12c744',
  900: '#0abe33',
  A100: '#e9ffed',
  A200: '#b6ffc3',
  A400: '#83ff9a',
  A700: '#69ff85',
};

var _default = greenSpotify;
exports.default = _default;