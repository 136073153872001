import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {spreaker_shows_get} from '../../services/BE'
import {podPreviewActionsSchema} from '../../services/PodPreviewActionsSchema'

import SpreakerPodPreview from './SpreakerPodPreview'
import SpreakerPodEpisodes from './SpreakerPodEpisodes'

import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

const style = theme => {}

class SpreakerShow extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: this.props.code,
            show: {}
        }
    }
    async componentDidMount() {
        const code = this.state.code
        let show = await spreaker_shows_get(code)
        show = show.hasOwnProperty('show') ? show.show : show

        this.setState({
            show: show
        })
    }

    render() {
        const code = this.props.code
        if( !code )
            return <React.Fragment>
                <p>Unkown show id</p>
            </React.Fragment>

        const show = this.state.show
        if( !Object.keys(show).length )
            return <LoaderSingleComponent width="100%" />

        //podPreview props
        const podPreviewProps = podPreviewActionsSchema('show')

        return <React.Fragment>
            <Box mt={1}>
                <List>
                    <SpreakerPodPreview
                        podcast={show}
                        titleVariant="h6"
                        //avatarRadius={50}
                        {...podPreviewProps}
                    />
                </List>
            </Box>
            <Typography component={Box} p={2} variant="subtitle1" color="primary">
                Episodi
            </Typography>
            <SpreakerPodEpisodes code={code} />
        </React.Fragment>
    }
}

export default withStyles(style)(SpreakerShow)