import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'

import HeadsetIcon from '@material-ui/icons/Headset'
import EditIcon from '@material-ui/icons/Edit'
import CommentIcon from '@material-ui/icons/Comment'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LensIcon from '@material-ui/icons/Lens'
import PeopleIcon from '@material-ui/icons/People'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

const style = theme => {
    return {
        statChip: {
            //marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    }
}

class StatsChips extends React.Component {
    render() {
        const posts_n = this.props.posts_n
        const reviews_n = this.props.reviews_n
        const comments_n = this.props.comments_n
        const likes_n = this.props.likes_n
        const avg_rating = this.props.avg_rating
        const follows_n = this.props.follows_n
        const followers_n = this.props.followers_n

        const size = this.props.hasOwnProperty('size') ? this.props.size : "small"
        const color = this.props.hasOwnProperty('color') ? this.props.color : "default"

        const classes = this.props.classes

        return <React.Fragment>
            {follows_n
                ? <React.Fragment>
                    <Tooltip title="Autori seguiti">
                        <Chip label={follows_n} size={size} color={color} icon={<PeopleIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {followers_n
                ? <React.Fragment>
                    <Tooltip title="Followers">
                        <Chip label={followers_n} size={size} color={color} icon={<PeopleOutlineIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {posts_n
                ? <React.Fragment>
                    <Tooltip title="Post in ascolto">
                        <Chip label={posts_n} size={size} color={color} icon={<HeadsetIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {reviews_n
                ? <React.Fragment>
                    <Tooltip title="Recensioni scritte">
                        <Chip label={reviews_n} size={size} color={color} icon={<EditIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {comments_n
                ? <React.Fragment>
                    <Tooltip title="Commenti scritti">
                        <Chip label={comments_n} size={size} color={color} icon={<CommentIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {likes_n
                ? <React.Fragment>
                    <Tooltip title="Numero di mi piace">
                        <Chip label={likes_n} size={size} color={color} icon={<FavoriteIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
            {avg_rating
                ? <React.Fragment>
                    <Tooltip title="Rating medio">
                        <Chip label={avg_rating} size={size} color={color} icon={<LensIcon />} className={classes.statChip} />
                    </Tooltip>
                </React.Fragment>
                : null}
        </React.Fragment>
    }
}

export default withStyles(style)(StatsChips)