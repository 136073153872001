import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import GoBackLink from '../@react-fefmwk/links/modules/GoBackLink'
import Post from '../modules/Post'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class PostPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_post = this.props.route.computedMatch.params.id

        const navigation = <GoBackLink type="button"
            variant="text"
            color="secondary"
            Component={(props) => <IconButton {...props}><ArrowBackIcon /></IconButton>}
        />

        return <Content title={title} maxWidth="md" navigation={navigation}>
            <Post id_post={id_post} commentsLimit={1000} />
        </Content>
    }
}

export default PostPage