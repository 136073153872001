import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import {
    Typography,
    Paper
} from '@material-ui/core'

const styles = (theme) => ({
    categoryBox: {
        color: "white",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
        height: "20vh",
        padding: theme.spacing(2),
        //backgroundColor:"transparent",
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        '&:hover': {
            boxShadow: '4px 4px 2px lightgrey'
        }
    },
    categoryLink: {
        textDecoration: 'none'
    },
})
const categoriesColors = [
    //"#00ffff",//aqua
    //"#f0ffff",//azure
    //"#f5f5dc",//beige
    "#000000",//black
    //"#0000ff",//blue
    "#3b498f",//blue corretto
    "#a52a2a",//brown
    "#80c4c4",//cyan spento
    //"#00ffff",//cyan
    "#00008b",//darkblue
    "#008b8b",//darkcyan
    "#a9a9a9",//darkgrey
    "#006400",//darkgreen
    "#bdb76b",//darkkhaki
    "#8b008b",//darkmagenta
    "#556b2f",//darkolivegreen
    "#ff8c00",//darkorange
    "#9932cc",//darkorchid
    "#8b0000",//darkred
    "#e9967a",//darksalmon
    "#9400d3",//darkviolet
    "#b35fb3",//fuchsia spento
    //"#ff00ff",//fuchsia
    //"#ffd700",//gold
    "#ccb743",//gold corretto
    "#008000",//green
    "#4b0082",//indigo
    //"#f0e68c",//khaki
    "#c2b865",//khaki scuro
    "#add8e6",//lightblue
    //"#e0ffff",//lightcyan
    "#90ee90",//lightgreen
    //"#d3d3d3",//lightgrey
    "#999797",//lightgrey corretto
    "#ffb6c1",//lightpink
    //"#ffffe0",//lightyellow
    //"#00ff00",//lime
    "#8a1e3f",//magenta corretto
    //"#ff00ff",//magenta
    "#800000",//maroon
    "#000080",//navy
    "#808000",//olive
    "#ffa500",//orange
    "#ffc0cb",//pink
    "#800080",//purple
    "#800080",//violet
    //"#ff0000",//red
    "#6b0e0e",//red spento
    "#c0c0c0",//silver
    //"#ffffff",//white
    //"#ffff00"//yellow    
]

class PodCategoryBox extends React.Component {
    render() {
        const url = this.props.url
        const title = this.props.title
        const externalUrl = this.props.hasOwnProperty('externalUrl') ? this.props.externalUrl : false
        const LinkComp = externalUrl ? ExternalLink : AdapterLink

        //const randomColor = "#" + Math.floor(Math.random()*16777215).toString(16)
        const randomColor = categoriesColors[Math.floor(Math.random() * categoriesColors.length)]

        const { classes } = this.props

        return <React.Fragment>
            <LinkComp to={url} className={classes.categoryLink}>
                <Paper elevation={0} className={classes.categoryBox} style={{backgroundColor:randomColor}}>
                    <Typography variant="h6" component="span">{title}</Typography>
                </Paper>
            </LinkComp>
        </React.Fragment>
    }
}

export default withStyles(styles)(PodCategoryBox)