import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Footer from '../../@react-fefmwk/theme-structure/modules/Footer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const LoginLayoutStyle = theme => ({
    container: {
        height: "100vh"
    },
    grid: {
        height: "100%"
    },
})

class LoginLayout extends React.Component {
    render() {
        const { classes } = this.props

        return <React.Fragment>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={4} direction="row" alignItems="center" className={classes.grid}>
                    <Grid item md={4} xs={12}>
                        {/*Col left*/}
                    </Grid>
                    <Grid id="Login" item md={4} xs={12}>
                        {this.props.children}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {/*Col right*/}
                    </Grid>
                </Grid>
            </Container>
            <Footer containerSize="md" textAlign="center" itemXs={4} />
        </React.Fragment>
    }
}

export default withRouter(withStyles(LoginLayoutStyle)(LoginLayout))