import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import PodsWall from '../modules/PodsWall'

import { Box } from '@material-ui/core'

class PodswallPage extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title} maxWidth="md">
            <Box mt={title ? 0 : 1}>
                <PodsWall />
            </Box>
        </Content>
    }
}

export default PodswallPage