import React from 'react'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import ExportCsvButton from '../@react-fefmwk/tables/modules/ExportCsvButton'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'

export function addBtn( entity,path,s='' ) {
    return <React.Fragment>
        <Fab title={"Add new "+entity} component={AdapterLink} color="primary" size="small" to={path + "/add" + s}>
            <AddIcon />
        </Fab>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function exportCSVBtn( entity,path,data ) {
    return data === undefined || !data.length
        ? null
        : <React.Fragment>
            <ExportCsvButton data={data} title={"Download CSV "+entity} component={Fab} color="primary" size="small" />
            &nbsp;&nbsp;
        </React.Fragment>
}
export function importCSVBtn( entity,path ) {
    return <React.Fragment>
        <Fab title={"Upload CSV "+entity} component={AdapterLink} color="primary" size="small" to={path + "/upload-csv"}>
            <PublishIcon />
        </Fab>
        &nbsp;&nbsp;
    </React.Fragment>
}
/*export function addPlaylistItem( entity,path,s='' ) {
    return <React.Fragment>
        <Fab title={"Aggiungi alla playlist"} component={AdapterLink} color="primary" size="small" to={path + s}>
            <AddIcon />
        </Fab>
        &nbsp;&nbsp;
    </React.Fragment>
}*/
export function toolbarSchema( entity,path,data ) {
    switch (entity) {
        case 'playlists':
            return [
                addBtn(entity,path),
            ]
        /*case 'playlists_items':
            return [
                addPlaylistItem(entity,path),
            ]*/
        default:
            return [
                exportCSVBtn( entity,path,data ),
                importCSVBtn( entity,path ),
                addBtn(entity,path),
            ]
    }
}