import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles,withTheme } from '@material-ui/core/styles'

import Header from '../../@react-fefmwk/theme-structure/modules/Header'
import NavSide from '../../@react-fefmwk/theme-structure/modules/NavSide'
import Footer from '../../@react-fefmwk/theme-structure/modules/Footer'

import { currentUrl } from '../../@react-fefmwk/requests/api'

import '../../@react-fefmwk/theme-structure/modules/theme.css'

import PodsSearch from '../../modules/PodsSearch'
import MostReviewdPodsCarousel from '../../modules/MostReviewdPodsCarousel'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const PodsWallPageStyle = theme => ({
    app: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    appHeaderBar: theme.globals.appHeaderBar,
    appBody: {
        ...theme.globals.appBody,
        ...{
            flex: "1 auto",
            height: 0,
            display: 'flex',
        },
    },
    rightBody: {
        flex: '1 1 auto',
        height: '100%'
    },
    leftBody: {
        //overflowY: 'auto',
        height: '100%'
    },
    standardFooter: {
        paddingLeft: theme.globals.appNavSideDrawer.width,
        width:'calc(100% - '+theme.globals.appNavSideDrawer.width+'px)',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            width:'100%',
        },
    },
    boxWhatListen: {
        height: '20vh',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        //paddingTop: theme.globals.appBody.paddingTop
        paddingTop: '10vh'
    },
    boxCarouselContainer: {
        height: '100%'
    },
    boxCarousel: {
        background: 'linear-gradient(to bottom,'+theme.palette.primary.main+' 0%,'+theme.palette.primary.main+' 50%,transparent 50%,transparent 100%)'
    }
})

class PodsWallLayout extends React.Component {
    constructor() {
        super()

        this.state = {
            openNavSide: false,
        }
    }
    toggleNavSide(e) {
        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return
        }
        this.setState({
            openNavSide: !this.state.openNavSide
        })
    }
    handleClickAway() {
        if( this.state.openNavSide )
            this.setState({
                openNavSide: false
            })
    }
    render() {
        const { classes,theme } = this.props
        const currentPath = currentUrl()
        const openNavSide = this.state.openNavSide

        const NavTopTitle = theme.hasOwnProperty('navtop_title') ? theme.navtop_title : ""
        const NavTopImgPath = theme.hasOwnProperty('navtop_logo') ? theme.navtop_logo : ""
        const ShowUsername = theme.hasOwnProperty('navtop_showusername') ? theme.navtop_showusername : ""
        const showUserDivider = theme.hasOwnProperty('navtop_showuserdivider') ? theme.navtop_showuserdivider : false
        const homeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : false

        return <div id="App" className={classes.app}>
            <Header className={classes.appHeaderBar}
                showAppLogo={true}
                navTopTitle={NavTopTitle}
                NavTopImgPath={NavTopImgPath}
                showUsername={ShowUsername}
                showUserDivider={showUserDivider}
                homeUrl={homeUrl}
                MenuBtn={<IconButton size="small" color="secondary" onClick={(e) => this.toggleNavSide(e)}>
                    <MenuIcon />
                </IconButton>}
            />
            <Box className={classes.boxWhatListen}>
                <Container maxWidth="sm">
                    <PodsSearch />
                </Container>
            </Box>
            <Box className={classes.boxCarousel}>
                <Container maxWidth="xl" className={classes.boxCarouselContainer}>
                    <MostReviewdPodsCarousel height="30vh" outfit="white" />
                </Container>
            </Box>
            <div id="AppBody" className={classes.appBody}>
                <div id="Left">
                    <Hidden smDown>
                        <NavSide currentPath={currentPath} variant="permanent" open={true} />
                    </Hidden>
                    <Hidden xsDown mdUp>
                        <NavSide currentPath={currentPath} variant="permanent" open={false} />
                    </Hidden>
                    <Hidden smUp>
                        <div onClick={(e) => this.toggleNavSide(e)}>
                            <NavSide currentPath={currentPath} variant="temporary" open={openNavSide} />
                        </div>
                    </Hidden>
                </div>
                <div id="Right" className={classes.rightBody}>
                    {this.props.children}
                </div>
            </div>
            <Footer textAlign="left" itemXs={4} className={classes.standardFooter} />
        </div>
    }
}

export default withRouter(withStyles(PodsWallPageStyle)(withTheme(PodsWallLayout)))